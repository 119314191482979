import React, { Component } from 'react';
import ReactExport from "react-export-excel";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import { map } from 'lodash';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require('moment');


class Report_pendaftaran_detail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data_student: '',
      is_show: false,
      data: '',
      listMaster: {},
      reportExcel: [],
      report: []
    }
  }

  getMaster(id = '', table) {
    var array = this.state.listMaster[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getMasterBeforeState(id = 0, table) {
    var array = JSON.parse(localStorage.getItem('DATA_PARAMETER'))[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getGuardianData(dataArray = []) {
    let result;
    let guardian = JSON.parse(localStorage.getItem('DATA_PARAMETER')).pdf_guardian;
    if (dataArray.length > 0) {
      var array = dataArray;
      const items = []
      map(guardian, (dt, idx) => {
        var arrayfilter = array.filter((data) => { if (parseInt(data.guardian_id) === parseInt(dt.id)) return data });
        var obj = { name: dt.name }
        var arrayResult = arrayfilter.length > 0 ? { ...arrayfilter[0], ...obj } : dt;
        items.push(arrayResult)
      });
      result = { data: items };
    } else {
      result = { data: [] };
    }
    return result;
  }


  handleClick = param => e => {
    e.preventDefault();
    // localStorage.setItem('student_id', param)
    const findIdx = this.state.report.findIndex((i) => i.registration_id === param.trim());
    var dataStudent = this.state.report[findIdx]
    var dataJalur = dataStudent.registration_id + ' - ' + dataStudent.fullname;
    localStorage.setItem("DATA_JALUR", dataJalur);
    localStorage.setItem("STUDENT_DETAIL", JSON.stringify(dataStudent));
    window.open("/detailstudent", '_blank');
  }

  componentDidMount() {
    let data_report = JSON.parse(localStorage.getItem('report_pendaftaran'));
    let master = JSON.parse(localStorage.getItem('DATA_PARAMETER'));
    const data = [];
    data_report.map((item) => {
      const dataStudent = [];
      let items = Object.create({});
      items['Nomor Pendaftaran'] = item.year + '-' + item.registration_type_id + '-' + item.registration_number
      master.prm_ms_student.map((data2) => {
        if (data2.pdf_table) {
          items[data2.name.trim()] = this.getMasterBeforeState(item[data2.field], data2.pdf_table)
        } else {
          if (data2.field === 'hp') {
            items[data2.name.trim()] = item[data2.field] ? item[data2.field].replace("+62", "0") : '';
          } else {
            items[data2.name.trim()] = item[data2.field] ? item[data2.field] : '';
          }
        }
      });
      items['Keadaan Orang Tua'] = this.getMasterBeforeState(item.parent_status_id, 'pdf_parent_status');
      items['Keadaan Pekerjaan Orang Tua'] = this.getMasterBeforeState(item.working_parent_id, 'pdf_working_parents');
      let guardian = JSON.parse(localStorage.getItem('DATA_PARAMETER')).pdf_guardian;
      if (item.guardian.length > 0) {
        var gd = this.getGuardianData(item.guardian);
        const data2 = [];
        map(gd.data, (dt) => {
          let items2 = Object.create({});
          if ('student_id' in dt) {
            items2[`Orang Tua ${dt.name}`] = dt.name;
            items2[`Nama Lengkap ${dt.name}`] = dt.fullname;
            items2[`NIK ${dt.name}`] = dt.nik;
            items2[`Tanggal Lahir ${dt.name}`] = dt.dob;
            items2[`Agama ${dt.name}`] = this.getMasterBeforeState(dt.religion_guardian_id, 'pdf_religion');
            items2[`Status ${dt.name}`] = this.getMasterBeforeState(dt.status_guardian_id, 'pdf_parent_status');
            items2[`Pendidikan ${dt.name}`] = this.getMasterBeforeState(dt.status_guardian_id, 'pdf_education');
            items2[`Pekerjaan ${dt.name}`] = this.getMasterBeforeState(dt.occupation_id, 'pdf_occupation');;
            items2[`Penghasilan ${dt.name}`] = dt.salary;
            items2[`No HP. ${dt.name}`] = dt.hp.replace("+62", "0");
            items2[`Alamat ${dt.name}`] = dt.address;
          } else {
            items2[`Orang Tua ${dt.name}`] = dt.name;
            items2[`Nama Lengkap ${dt.name}`] = '';
            items2[`NIK ${dt.name}`] = '';
            items2[`Tanggal Lahir ${dt.name}`] = '';
            items2[`Agama ${dt.name}`] = '';
            items2[`Status ${dt.name}`] = '';
            items2[`Pendidikan ${dt.name}`] = '';
            items2[`Pekerjaan ${dt.name}`] = '';
            items2[`Penghasilan ${dt.name}`] = '';
            items2[`No HP. ${dt.name}`] = '';
            items2[`Alamat ${dt.name}`] = '';
          }
          data2.push(items2)
        })
        var newObj = Object.assign({}, ...data2);
        items = { ...items, ...newObj }
      } else {
        const data2 = [];
        map(guardian, (dt) => {
          let items2 = Object.create({});
          items2[`Orang Tua ${dt.name}`] = dt.name;
          items2[`Nama Lengkap ${dt.name}`] = '';
          items2[`NIK ${dt.name}`] = '';
          items2[`Tanggal Lahir ${dt.name}`] = '';
          items2[`Agama ${dt.name}`] = '';
          items2[`Status ${dt.name}`] = '';
          items2[`Pendidikan ${dt.name}`] = '';
          items2[`Pekerjaan ${dt.name}`] = '';
          items2[`Penghasilan ${dt.name}`] = '';
          items2[`No HP. ${dt.name}`] = '';
          items2[`Alamat ${dt.name}`] = '';
          data2.push(items2)
        })
        var newObj = Object.assign({}, ...data2);
        items = { ...items, ...newObj }
      }
      items[`Catatan`] = item.note_verification ? item.note_verification : '';
      data.push(items)
    })
    this.setState({ report: data_report, listMaster: master, reportExcel: data })
  }

  createExcelSheet(data) {
    let final = [];
    for (var key in data[0]) {
      final.push(<ExcelColumn label={key} value={key} key={key} />);
    }
    return final;
  }


  render() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Laporan Pendaftaran
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <ExcelFile element={<MDBBtn color='primary' block style={{ margin: 5, height: 50 }}>Download Data</MDBBtn>}>
                        <ExcelSheet data={this.state.reportExcel} name="Report Pendaftaran">
                          {this.state.reportExcel.length > 0 ? this.createExcelSheet(this.state.reportExcel) : null}
                          {/* <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran" />
                          <ExcelColumn label="Jalur" value="jalur" />
                          <ExcelColumn label="Nama Lengkap" value="fullname" />
                          <ExcelColumn label="Nomor Panggilan" value="nickname" />
                          <ExcelColumn label="Jenis Kelamin" value="gender" />
                          <ExcelColumn label="NIK" value="nik" />
                          <ExcelColumn label="No KK" value="no_kk" />
                          <ExcelColumn label="NIS" value="nis" />
                          <ExcelColumn label="NISN" value="nisn" />
                          <ExcelColumn label="Tempat Lahir" value="tempatlahir" />
                          <ExcelColumn label="Tanggal Lahir" value="tanggallahir" />
                          <ExcelColumn label="No Akta Kelahiran" value="akta" />
                          <ExcelColumn label="Agama" value="agama" />
                          <ExcelColumn label="Kewarganegaraan" value="kewarganegaraan" />
                          <ExcelColumn label="Alamat" value="alamat" />
                          <ExcelColumn label="RT" value="rt" />
                          <ExcelColumn label="RW" value="rw" />
                          <ExcelColumn label="Kelurahan" value="kelurahan" />
                          <ExcelColumn label="Kecamatan" value="kecamatan" />
                          <ExcelColumn label="Kabupaten/Kota" value="kabupaten" />
                          <ExcelColumn label="Provinsi" value="provinsi" />
                          <ExcelColumn label="Kode Pos" value="kodepos" />
                          <ExcelColumn label="Bujur" value="bujur"/>
                                <ExcelColumn label="Lintang" value="lintang"/> 
                          <ExcelColumn label="Tinggal Dengan" value="tinggal" />
                          <ExcelColumn label="Moda Transportasi" value="transportasi" />
                          <ExcelColumn label="Jarak Tempuh(km)" value="jarak" />
                          <ExcelColumn label="Waktu Tempuh" value="waktu" />
                          <ExcelColumn label="No. Handphone" value="hp" />
                          <ExcelColumn label="Email" value="email" />
                          <ExcelColumn label="Anak ke" value="anak" />
                          <ExcelColumn label="Dari Bersaudara" value="saudara" />
                          <ExcelColumn label="Saudara Yang Masih Menjadi Tanggungan Orang Tua" value="sibling_dependent" />
                          <ExcelColumn label="Golongan Darah" value="darah"/> 
                                <ExcelColumn label="Tinggi (cm)" value="tinggi"/>
                                <ExcelColumn label="Berat (kg)" value="berat"/>
                                <ExcelColumn label="Lingkar Kepala (cm)" value="kepala"/> */}
                          {/* <ExcelColumn label="Sekolah Asal" value="sekolahasal" />
                          <ExcelColumn label="Nama Sekolah Asal" value="namasekolah" />
                          <ExcelColumn label="Alamat Sekolah Asal" value="alamatsekolah" />
                          <ExcelColumn label="Lama Belajar" value="study_year" />
                          <ExcelColumn label="Uang SPP Sekolah Asal" value="spp" />
                          <ExcelColumn label="Daya Listrik" value="electrical_power_id" />
                          <ExcelColumn label="Status" value="status" />
                          <ExcelColumn label="Catatan" value="catatan" />

                          <ExcelColumn label="Keadaan Orang Tua" value="parent_status_id" />
                          <ExcelColumn label="Keadaan Pekerjaan Orang Tua" value="both_parent_work" />
                          <ExcelColumn label="Orang Tua" value="guardian_name0" />
                          <ExcelColumn label="Nama Lengkap" value="fullname0" />
                          <ExcelColumn label="NIK" value="nik0" />
                          <ExcelColumn label="Tanggal Lahir" value="lahirayah" />
                          <ExcelColumn label="Agama" value="agama0" />
                          <ExcelColumn label="Status" value="status0" />
                          <ExcelColumn label="Pendidikan" value="pendidikan0" />
                          <ExcelColumn label="Pekerjaan" value="pekerjaan0" />
                          <ExcelColumn label="Penghasilan" value="penghasilan0" />
                          <ExcelColumn label="no. HP" value="hp0" />
                          <ExcelColumn label="Alamat" value="alamat0" />


                          <ExcelColumn label="Orang Tua" value="guardian_name1" />
                          <ExcelColumn label="Nama Lengkap" value="fullname1" />
                          <ExcelColumn label="NIK" value="nik1" />
                          <ExcelColumn label="Tanggal Lahir" value="lahiribu" />
                          <ExcelColumn label="Agama" value="agama1" />
                          <ExcelColumn label="Status" value="status1" />
                          <ExcelColumn label="Pendidikan" value="pendidikan1" />
                          <ExcelColumn label="Pekerjaan" value="pekerjaan1" />
                          <ExcelColumn label="Penghasilan" value="penghasilan1" />
                          <ExcelColumn label="no. HP" value="hp1" />
                          <ExcelColumn label="Alamat" value="alamat1" />

                          <ExcelColumn label="Orang Tua" value="guardian_name2" />
                          <ExcelColumn label="Nama Lengkap" value="fullname2" />
                          <ExcelColumn label="NIK" value="nik2" />
                          <ExcelColumn label="Tanggal Lahir" value="lahirwali" />
                          <ExcelColumn label="Agama" value="agama2" />
                          <ExcelColumn label="Status" value="status2" />
                          <ExcelColumn label="Pendidikan" value="pendidikan2" />
                          <ExcelColumn label="Pekerjaan" value="pekerjaan2" />
                          <ExcelColumn label="Penghasilan" value="penghasilan2" />
                          <ExcelColumn label="no. HP" value="hp2" />
                          <ExcelColumn label="Alamat" value="alamat2" />

                          <ExcelColumn label="Catatan Tentang Pendaftar" value="note" />
                          <ExcelColumn label="Jadwal Sosialisasi" value="schedulesocialization" />
                          <ExcelColumn label="Jadwal Wawancara" value="scheduleinterview" /> */}
                        </ExcelSheet>
                      </ExcelFile>

                      {/* <div className="box-body">
                        <div className="row"> */}
                      <MDBTable >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nama Lengkap (Nama dapat diklik)</th>
                            <th>Nomor Pendaftaran</th>
                            <th>Nomor Handphone</th>
                            <th>Status</th>
                            <th>Catatan</th>
                            {/* <th>Pendaftaran</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.report.map((item, index) => {
                            counter++;
                            return (
                              <tr key={index}>
                                <td>{counter}</td>
                                <td style={{ color: 'blue' }} onClick={this.handleClick(item.registration_id)}>{item.fullname}</td>
                                <td>{item.year + '-' + item.registration_type_id + '-' + item.registration_number}</td>
                                <td>{item.hp ? item.hp.replace("+62", "0") : ''}</td>
                                <td>{this.getMaster(item.flow_id, 'pdf_flow')}</td>
                                <td>{item.note_reject}</td>
                                {/* <td>{item.is_offline === 1 ?
                                      <div style={{ color: 'red' }}>Offline</div>
                                      :
                                      <div style={{ color: 'green' }}>Online</div>
                                    }
                                    </td> */}
                              </tr>
                            )
                          })}
                        </tbody>
                      </MDBTable>
                      {/* </div>
                      </div> */}
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Report_pendaftaran_detail;