import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import App from './App';
import Global from './Global';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

const customHistory = createBrowserHistory();
const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && 
      <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: '#fff',
          }}>
          <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
      </div>
  );  
}
global.variable = Global;
global.swal = swal;
global.cookies = Cookies;
// global.loading = LoadingIndicator;

ReactDOM.render(
  <Router history={customHistory}>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
