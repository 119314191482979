import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
import remSep from "string-remove-thousand-separators";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";

class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guardian_id1: "",
      fullname1: "",
      nik1: "",
      dob1: "1980-01-01",
      status_guardian_id1: "",
      parent_status_id: "",
      both_parent_work: "",
      education_id1: "",
      occupation_id1: "",
      salary1: "",
      hp1: "",
      address1: "",
      guardian_id1_name: "",
      status_guardian_id1_name: "",
      education_id1_name: "",
      occupation_id1_name: "",
      religion_guardian_id1: "",
      religion_guardian_id1_name: "",
      guardian_id2: "",
      fullname2: "",
      nik2: "",
      dob2: "1980-01-01",
      status_guardian_id2: "",
      education_id2: "",
      occupation_id2: "",
      salary2: "",
      hp2: "",
      address2: "",
      guardian_id2_name: "",
      status_guardian_id2_name: "",
      education_id2_name: "",
      occupation_id2_name: "",
      religion_guardian_id2: "",
      religion_guardian_id2_name: "",
      guardian_id3: "",
      fullname3: "",
      nik3: "",
      dob3: "1980-01-01",
      status_guardian_id3: "1",
      education_id3: "1",
      occupation_id3: "1",
      salary3: "",
      hp3: "",
      address3: "",
      guardian_id3_name: "",
      status_guardian_id3_name: "",
      education_id3_name: "",
      occupation_id3_name: "",
      religion_guardian_id3: "",
      religion_guardian_id3_name: "",
    };
  }

  validateForm() {
    return this.state.fullname2.length > 0
      ? this.state.fullname2.length > 0 &&
          this.state.parent_status_id.length > 0 &&
          this.state.both_parent_work.length > 0 &&
          this.state.nik2.length > 0 &&
          this.state.dob2.length > 0 &&
          this.state.education_id2.length > 0 &&
          this.state.occupation_id2.length > 0 &&
          this.state.address2.length > 0 &&
          this.state.religion_guardian_id2.length > 0
      : 1 && this.state.fullname1.length > 0
      ? this.state.fullname1.length > 0 &&
        this.state.nik1.length > 0 &&
        this.state.dob1.length > 0 &&
        this.state.education_id1.length > 0 &&
        this.state.occupation_id1.length > 0 &&
        this.state.address1.length > 0 &&
        this.state.religion_guardian_id1.length > 0
      : 1;
  }

  onChangeNom = (e) =>
    this.setState({ [e.target.name]: remSep(e.target.value) });

  handleAdd = () => {
    console.log("state 4 " + JSON.stringify(this.state));

    let item = [];
    let items = Object.create({});
    items.guardian_id = this.state.guardian_id1;
    items.fullname = this.state.fullname1;
    items.nik = this.state.nik1;
    items.dob = this.state.dob1;
    items.status_guardian_id = this.state.status_guardian_id1;
    items.education_id = this.state.education_id1;
    items.occupation_id = this.state.occupation_id1;
    items.salary = this.state.salary1 ? this.state.salary1 : 0;
    items.hp = this.state.hp1;
    items.address = this.state.address1;
    items.religion_guardian_id = this.state.religion_guardian_id1;
    item.push(items);

    let items2 = Object.create({});
    items2.guardian_id = this.state.guardian_id2;
    items2.fullname = this.state.fullname2;
    items2.nik = this.state.nik2;
    items2.dob = this.state.dob2;
    items2.status_guardian_id = this.state.status_guardian_id2;
    items2.education_id = this.state.education_id2;
    items2.occupation_id = this.state.occupation_id2;
    items2.salary = this.state.salary2 ? this.state.salary2 : 0;
    items2.hp = this.state.hp2;
    items2.address = this.state.address2;
    items2.religion_guardian_id = this.state.religion_guardian_id2;
    item.push(items2);

    let items3 = Object.create({});
    items3.guardian_id = this.state.guardian_id3;
    items3.fullname = this.state.fullname3;
    items3.nik = this.state.nik3;
    items3.dob = this.state.dob3;
    items3.status_guardian_id = this.state.status_guardian_id3;
    items3.education_id = this.state.education_id3;
    items3.occupation_id = this.state.occupation_id3;
    items3.salary = this.state.salary3;
    items3.hp = this.state.hp3;
    items3.address = this.state.address3;
    items3.religion_guardian_id = this.state.religion_guardian_id3;
    item.push(items3);

    localStorage.setItem("reg4", JSON.stringify(item));
    localStorage.setItem("statereg4", JSON.stringify(this.state));

    console.log("score " + JSON.stringify(item));
    console.log(JSON.stringify(this.state));
    window.location.href = "/pendaftaran4";
  };

  componentDidMount() {
    if (localStorage.getItem("statereg4")) {
      this.setState(JSON.parse(localStorage.getItem("statereg4")));
    }

    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_guardian;
    this.setState({
      guardian_id1: params[0].id,
      guardian_id2: params[1].id,
      guardian_id3: params[2].id,
      guardian_id_name1: params[0].name,
      guardian_id_name2: params[1].name,
      guardian_id_name3: params[2].name,
    });

    console.log(localStorage.getItem("reg4"));
  }

  createSelectGuardian() {
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_guardian;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectStatus() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_parent_status;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectParentStatus() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_parent_status;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectWorkingParents() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_working_parents;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectPendidikan() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_education;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectPekerjaan() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_occupation;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectReligion() {
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_religion;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  showRemove() {
    if (localStorage.getItem("reg4")) {
      return (
        <MDBBtn
          color="primary"
          onClick={this.handleClear}
          block
          style={{ margin: 5, height: 30, width: 100 }}
        >
          Clear Data
        </MDBBtn>
      );
    }
  }

  showGuardian() {
    if (localStorage.getItem("reg4")) {
      let guardian = JSON.parse(localStorage.getItem("reg4"));
      console.log(localStorage.getItem("reg4"));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) {
        console.log(guardian[i].nik);
        showitem.push(
          <div key={guardian[i].nik}>
            <hr />
            {/* <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Orang Tua{" "}
              </Form.Label>
              <Form.Label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].guardian_id_name}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Fullname{" "}
              </Form.Label>
              <Form.Label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].fullname}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                NIK{" "}
              </Form.Label>
              <Form.Label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].nik}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Tanggal Lahir{" "}
              </Form.Label>
              <Form.Label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].dob}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Agama{" "}
              </Form.Label>
              <Form.Label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].religion_guardian_id_name}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Status{" "}
              </Form.Label>
              <Form.Label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].status_guardian_id_name}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Pendidikan{" "}
              </Form.Label>
              <Form.Label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].education_id_name}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Pekerjaan{" "}
              </Form.Label>
              <Form.Label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].occupation_id_name}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Penghasilan{" "}
              </Form.Label>
              <Form.Label className="col-md-3" style={{ margin: 5 }}>
                {guardian[i].salary}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                No HP{" "}
              </Form.Label>
              <Form.Label className="col-md-3" style={{ margin: 5 }}>
                {guardian[i].hp}{" "}
              </Form.Label>
            </div>
            <div className="rowHorizontal">
              <Form.Label
                className="col-md-3"
                style={{ width: 200, margin: 5 }}
              >
                Alamat Tempat Tinggal{" "}
              </Form.Label>
              <Form.Label className="col-md-3" style={{ margin: 5 }}>
                {guardian[i].address}{" "}
              </Form.Label>
            </div> */}
          </div>
        );
      }

      return showitem;
    }
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  /*createSelectWorkingParents() {
    // ini valuenya
    let items = [];          
         items.push(
         <option value="1">YA</option>,
         <option value="0">TIDAK</option>); 
         
    return items;
    
  }*/

  /*onDropdownSelected2(e) {
    console.log("THE VAL", e.target.value);    
    this.setState({both_parent_work: e.target.value});
  }*/
  onDropdownSelected2(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onDropdownSelected(e) {
    this.setState({
      [e.target.name]: e.target.value.split("_")[0],
      [e.target.name + "_name"]: e.target.value.split("_")[1],
    });
  }

  onDropdownSelected3(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeDate1 = (jsDate, dateString) => {
    this.setState({ dob1: dateString });
  };

  onChangeDate2 = (jsDate, dateString) => {
    this.setState({ dob2: dateString });
  };

  onChangeDate3 = (jsDate, dateString) => {
    this.setState({ dob3: dateString });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let item = [];
    let data = Object.create({});
    // data.parent_status_id = this.state.parent_status_id;
    // data.both_parent_work = this.state.both_parent_work;
    // if(this.state.parent_status_id || this.state.both_parent_work)
    // {
    //   item.push(data)
    // }

    let items = Object.create({});
    items.guardian_id = this.state.guardian_id1;
    items.fullname = this.state.fullname1;
    items.nik = this.state.nik1;
    items.dob = this.state.dob1;
    items.status_guardian_id = this.state.status_guardian_id1;
    items.education_id = this.state.education_id1;
    items.occupation_id = this.state.occupation_id1;
    items.salary = this.state.salary1 ? this.state.salary1 : 0;
    items.hp = this.state.hp1;
    items.address = this.state.address1;
    items.religion_guardian_id = this.state.religion_guardian_id1;
    if (this.state.fullname1) {
      item.push(items);
    }

    let items2 = Object.create({});
    items2.guardian_id = this.state.guardian_id2;
    items2.fullname = this.state.fullname2;
    items2.nik = this.state.nik2;
    items2.dob = this.state.dob2;
    items2.status_guardian_id = this.state.status_guardian_id2;
    items2.education_id = this.state.education_id2;
    items2.occupation_id = this.state.occupation_id2;
    items2.salary = this.state.salary2 ? this.state.salary2 : 0;
    items2.hp = this.state.hp2;
    items2.address = this.state.address2;
    items2.religion_guardian_id = this.state.religion_guardian_id2;
    if (this.state.fullname2) {
      item.push(items2);
    }

    let items3 = Object.create({});
    items3.guardian_id = this.state.guardian_id3;
    items3.fullname = this.state.fullname3;
    items3.nik = this.state.nik3;
    items3.dob = this.state.dob3;
    //items3.status_guardian_id = this.state.status_guardian_id;
    items3.education_id = this.state.education_id3;
    items3.occupation_id = this.state.occupation_id3;
    items3.salary = this.state.salary3 ? this.state.salary3 : 0;
    items3.hp = this.state.hp3;
    items3.address = this.state.address3;
    items3.religion_guardian_id = this.state.religion_guardian_id3;
    if (this.state.fullname3) {
      item.push(items3);
    }

    // if(this.state.both_parent_work===""){
    //   global.swal("Info", "Harap pilih Keadaan Orang Tua Bekerja", "info");
    // }else if(this.state.parent_status_id===""){
    //   global.swal("Info", "Harap pilih Keadaan Orang Tua", "info");
    // }
    // else{

    localStorage.setItem("reg4", JSON.stringify(item));
    localStorage.setItem("statereg4", JSON.stringify(this.state));
    console.log("score " + JSON.stringify(item));
    console.log(JSON.stringify(this.state));
    window.location.href = "/pendaftaran5";
    // }
  };

  handleClear = () => {
    localStorage.removeItem("reg4");
    window.location.href = "/pendaftaran4";
  };

  handleBack = () => {
    window.location.href = "/pendaftaran3";
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pendaftaran Step 4
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleForm2">
                              Keadaan Orang Tua *
                            </label>
                            <select
                              className="form-control"
                              name="parent_status_id"
                              onChange={this.onDropdownSelected3.bind(this)}
                              value={this.state.parent_status_id}
                              required
                            >
                              <option value="">-Pilih Status-</option>
                              {this.createSelectParentStatus()}
                            </select>
                          </div>
                          {this.state.parent_status_id !== "4" ? (
                            <>
                              <div className="form-group col-md-6">
                                <label htmlFor="exampleForm2">
                                  Keadaan Pekerjaan Orang Tua *
                                </label>
                                <select
                                  className="form-control"
                                  name="both_parent_work"
                                  onChange={this.onDropdownSelected2.bind(this)}
                                  value={this.state.both_parent_work}
                                  required
                                >
                                  <option value="">-Pilih-</option>
                                  {/* {console.log(this.state.both_parent_work)} */}
                                  {this.createSelectWorkingParents()}
                                </select>
                              </div>
                            </>
                          ) : null}
                        </div>
                        {this.state.parent_status_id === "1" ? (
                          <div>
                            <label
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Data {this.state.guardian_id_name1}
                            </label>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Nama Lengkap *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fullname1"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.fullname1}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  NIK (Nomor Induk Kependudukan) ={">"} cek di
                                  Kartu Keluarga *
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="nik1"
                                  onChange={this.onChange}
                                  value={this.state.nik1}
                                  maxLength="25"
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Tanggal Lahir *</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dob1"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.dob1}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Agama *</label>
                                <select
                                  className="form-control"
                                  name="religion_guardian_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.religion_guardian_id1 +
                                    "_" +
                                    this.state.religion_guardian_id1_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Agama-</option>
                                  {console.log(
                                    this.state.religion_guardian_id1
                                  )}
                                  {this.createSelectReligion()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Status *</label>
                                <select
                                  className="form-control"
                                  name="status_guardian_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.status_guardian_id1 +
                                    "_" +
                                    this.state.status_guardian_id1_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Status-</option>
                                  {console.log(this.state.status_guardian_id1)}
                                  {this.createSelectStatus()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>Pendidikan Terakhir *</label>
                                <select
                                  className="form-control"
                                  name="education_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.education_id1 +
                                    "_" +
                                    this.state.education_id1_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Pendidikan-</option>
                                  {this.createSelectPendidikan()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Pekerjaan *</label>
                                <select
                                  className="form-control"
                                  name="occupation_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.occupation_id1 +
                                    "_" +
                                    this.state.occupation_id1_name
                                  }
                                  style={{ marginTop: "4%" }}
                                  required
                                >
                                  <option value="">-Pilih Pekerjaan-</option>
                                  {this.createSelectPekerjaan()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Penghasilan (isi hanya angka jangan diberi
                                  titik dan koma, jika tidak ada diisi dengan
                                  angka 0)
                                </label>
                                <CurrencyFormat
                                  name="salary1"
                                  onChange={this.onChangeNom}
                                  value={this.state.salary1}
                                  maxLength="50"
                                  thousandSeparator={true}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>No HP</label>
                                   <CurrencyFormat 
                                    className="form-control"
                                    input="number"
                                    name="hp1" 
                                    format="+62############"
                                    onChange={this.onChange} 
                                    value={this.state.hp1}
                                    maxLength="25"
                                    style={{ width : '100%'}} 
                                    />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Alamat Tempat Tinggal *</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="address1"
                                  maxLength="200"
                                  value={this.state.address1}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                            <label
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Data {this.state.guardian_id_name2}
                            </label>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Nama Lengkap *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fullname2"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.fullname2}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  NIK (Nomor Induk Kependudukan) ={">"} cek di
                                  Kartu Keluarga *
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="nik2"
                                  onChange={this.onChange}
                                  value={this.state.nik2}
                                  maxLength="25"
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Tanggal Lahir *</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dob2"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.dob2}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Agama *</label>
                                <select
                                  className="form-control"
                                  name="religion_guardian_id2"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.religion_guardian_id2 +
                                    "_" +
                                    this.state.religion_guardian_id2_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Agama-</option>
                                  {this.createSelectReligion()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Status *</label>
                                <select
                                  className="form-control"
                                  name="status_guardian_id2"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.status_guardian_id2 +
                                    "_" +
                                    this.state.status_guardian_id2_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Status-</option>
                                  {console.log(this.state.status_guardian_id2)}
                                  {this.createSelectStatus()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>Pendidikan Terakhir *</label>
                                <select
                                  className="form-control"
                                  name="education_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.education_id1 +
                                    "_" +
                                    this.state.education_id1_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Pendidikan-</option>
                                  {this.createSelectPendidikan()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Pekerjaan *</label>
                                <select
                                  className="form-control"
                                  name="occupation_id2"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.occupation_id2 +
                                    "_" +
                                    this.state.occupation_id2_name
                                  }
                                  style={{ marginTop: "4%" }}
                                  required
                                >
                                  <option value="">-Pilih Pekerjaan-</option>
                                  {this.createSelectPekerjaan()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Penghasilan (isi hanya angka jangan diberi
                                  titik dan koma, jika tidak ada diisi dengan
                                  angka 0)
                                </label>
                                <CurrencyFormat
                                  name="salary2"
                                  onChange={this.onChangeNom}
                                  value={this.state.salary2}
                                  maxLength="50"
                                  thousandSeparator={true}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>No HP</label>
                                   <CurrencyFormat 
                                    className="form-control"
                                    input="number"
                                    name="hp2" 
                                    format="+62############"
                                    onChange={this.onChange} 
                                    value={this.state.hp2}
                                    maxLength="25"
                                    style={{ width : '100%'}} 
                                    />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Alamat Tempat Tinggal *</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="address2"
                                  maxLength="200"
                                  value={this.state.address2}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.parent_status_id === "2" ? (
                          <div>
                            <label
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Data {this.state.guardian_id_name1}
                            </label>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Nama Lengkap *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fullname1"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.fullname1}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  NIK (Nomor Induk Kependudukan) ={">"} cek di
                                  Kartu Keluarga *
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="nik1"
                                  onChange={this.onChange}
                                  value={this.state.nik1}
                                  maxLength="25"
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Tanggal Lahir *</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dob1"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.dob1}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Agama *</label>
                                <select
                                  className="form-control"
                                  name="religion_guardian_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.religion_guardian_id1 +
                                    "_" +
                                    this.state.religion_guardian_id1_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Agama-</option>
                                  {console.log(
                                    this.state.religion_guardian_id1
                                  )}
                                  {this.createSelectReligion()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Status *</label>
                                <select
                                  className="form-control"
                                  name="status_guardian_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.status_guardian_id1 +
                                    "_" +
                                    this.state.status_guardian_id1_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Status-</option>
                                  {console.log(this.state.status_guardian_id1)}
                                  {this.createSelectStatus()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>Pendidikan Terakhir *</label>
                                <select
                                  className="form-control"
                                  name="education_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.education_id1 +
                                    "_" +
                                    this.state.education_id1_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Pendidikan-</option>
                                  {this.createSelectPendidikan()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Pekerjaan *</label>
                                <select
                                  className="form-control"
                                  name="occupation_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.occupation_id1 +
                                    "_" +
                                    this.state.occupation_id1_name
                                  }
                                  style={{ marginTop: "4%" }}
                                  required
                                >
                                  <option value="">-Pilih Pekerjaan-</option>
                                  {this.createSelectPekerjaan()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Penghasilan (isi hanya angka jangan diberi
                                  titik dan koma, jika tidak ada diisi dengan
                                  angka 0)
                                </label>
                                <CurrencyFormat
                                  name="salary1"
                                  onChange={this.onChangeNom}
                                  value={this.state.salary1}
                                  maxLength="50"
                                  thousandSeparator={true}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>No HP</label>
                                   <CurrencyFormat 
                                    className="form-control"
                                    input="number"
                                    name="hp1" 
                                    format="+62############"
                                    onChange={this.onChange} 
                                    value={this.state.hp1}
                                    maxLength="25"
                                    style={{ width : '100%'}} 
                                    />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Alamat Tempat Tinggal *</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="address1"
                                  maxLength="200"
                                  value={this.state.address1}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.parent_status_id === "3" ? (
                          <div>
                            <label
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Data {this.state.guardian_id_name2}
                            </label>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Nama Lengkap *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fullname2"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.fullname2}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  NIK (Nomor Induk Kependudukan) ={">"} cek di
                                  Kartu Keluarga *
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="nik2"
                                  onChange={this.onChange}
                                  value={this.state.nik2}
                                  maxLength="25"
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Tanggal Lahir *</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dob2"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.dob2}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Agama *</label>
                                <select
                                  className="form-control"
                                  name="religion_guardian_id2"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.religion_guardian_id2 +
                                    "_" +
                                    this.state.religion_guardian_id2_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Agama-</option>
                                  {this.createSelectReligion()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Status *</label>
                                <select
                                  className="form-control"
                                  name="status_guardian_id2"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.status_guardian_id2 +
                                    "_" +
                                    this.state.status_guardian_id2_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Status-</option>
                                  {console.log(this.state.status_guardian_id2)}
                                  {this.createSelectStatus()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>Pendidikan Terakhir *</label>
                                <select
                                  className="form-control"
                                  name="education_id1"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.education_id1 +
                                    "_" +
                                    this.state.education_id1_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Pendidikan-</option>
                                  {this.createSelectPendidikan()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Pekerjaan *</label>
                                <select
                                  className="form-control"
                                  name="occupation_id2"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.occupation_id2 +
                                    "_" +
                                    this.state.occupation_id2_name
                                  }
                                  style={{ marginTop: "4%" }}
                                  required
                                >
                                  <option value="">-Pilih Pekerjaan-</option>
                                  {this.createSelectPekerjaan()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Penghasilan (isi hanya angka jangan diberi
                                  titik dan koma, jika tidak ada diisi dengan
                                  angka 0)
                                </label>
                                <CurrencyFormat
                                  name="salary2"
                                  onChange={this.onChangeNom}
                                  value={this.state.salary2}
                                  maxLength="50"
                                  thousandSeparator={true}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>No HP</label>
                                   <CurrencyFormat 
                                    className="form-control"
                                    input="number"
                                    name="hp2" 
                                    format="+62############"
                                    onChange={this.onChange} 
                                    value={this.state.hp2}
                                    maxLength="25"
                                    style={{ width : '100%'}} 
                                    />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Alamat Tempat Tinggal *</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="address2"
                                  maxLength="200"
                                  value={this.state.address2}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.parent_status_id === "4" ? (
                          <div>
                            <label
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Data {this.state.guardian_id_name3}
                            </label>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Nama Lengkap *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="fullname3"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.fullname3}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  NIK (Nomor Induk Kependudukan) ={">"} cek di
                                  Kartu Keluarga *
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="nik3"
                                  onChange={this.onChange}
                                  value={this.state.nik3}
                                  maxLength="25"
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Tanggal Lahir *</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dob3"
                                  onChange={this.onChange}
                                  maxLength="200"
                                  value={this.state.dob3}
                                  style={{ width: "100%" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Agama *</label>
                                <select
                                  className="form-control"
                                  name="religion_guardian_id3"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.religion_guardian_id3 +
                                    "_" +
                                    this.state.religion_guardian_id3_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Agama-</option>
                                  {this.createSelectReligion()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Pendidikan Terakhir *</label>
                                <select
                                  className="form-control"
                                  name="education_id3"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.education_id3 +
                                    "_" +
                                    this.state.education_id3_name
                                  }
                                  required
                                >
                                  <option value="">-Pilih Pendidikan-</option>
                                  {this.createSelectPendidikan()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>Pekerjaan *</label>
                                <select
                                  className="form-control"
                                  name="occupation_id3"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={
                                    this.state.occupation_id3 +
                                    "_" +
                                    this.state.occupation_id3_name
                                  }
                                  //   style={{marginTop:'4%'}}
                                  required
                                >
                                  <option value="">-Pilih Pekerjaan-</option>
                                  {this.createSelectPekerjaan()}
                                </select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>
                                  Penghasilan (isi hanya angka jangan diberi
                                  titik dan koma, jika tidak ada diisi dengan
                                  angka 0)
                                </label>
                                <CurrencyFormat
                                  name="salary3"
                                  onChange={this.onChangeNom}
                                  value={this.state.salary3}
                                  maxLength="50"
                                  thousandSeparator={true}
                                  style={{ width: "100%" }}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>No HP</label>
                                   <CurrencyFormat 
                                    className="form-control"
                                    input="number"
                                    name="hp3" 
                                    format="+62############"
                                    onChange={this.onChange} 
                                    value={this.state.hp3}
                                    maxLength="25"
                                    style={{ width : '100%'}} 
                                    />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Alamat Tempat Tinggal *</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="address3"
                                  maxLength="200"
                                  value={this.state.address3}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="row">
                          <div className="col-md-6">
                            <MDBBtn
                              block
                              color="primary"
                              onClick={this.handleBack}
                              style={{ height: 50 }}
                            >
                              Prev
                            </MDBBtn>
                          </div>
                          <div className="col-md-6">
                            <MDBBtn
                              block
                              color="primary"
                              type="submit"
                              // disabled={!this.validateForm()}
                              style={{ height: 50 }}
                            >
                              Next
                            </MDBBtn>
                          </div>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
