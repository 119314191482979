import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
import SelectSearch from "react-select-search";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
  MDBSelectV5,
} from "mdbreact";
// import remSep from "string-remove-thousand-separators";

class Tambah_unit extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const { state } = location;
    const { data } = state;
    this.state = {
      subdomain: "",
      name: "",
      education_id: "",
      city: "",
      logo: "",
      email: "",
      header: "",
      va: "",
      registration_year: "",
      registration_fee: "",
      // registration_fee: 0,
      open_registration: "",
      close_registration: "",
      image: "",
      logo_unit: "",
      kop: "",
      // units_name: "",
      // va: "",
      image_data: [],
      searchOptions: [],
      user_level: data ? data.user_level : '',
      organizations_id: data ? data.organizations_id : '',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state } = location;
    const { isEdit = false, data } = state;

    if (isEdit && data) {
      this.setState({ ...this.state, ...data });
    }
  }

  createSelectEducation() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_education;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectPaymentType() {
    let items = [];
    let params = [
      { id: 1, name: "Transfer" },
      { id: 2, name: "PG (Faspay)" },
    ];
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectRegistration() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_study_year;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectOrganizations() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).organizations;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].id + " - " + params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectUnit() {
    let organizations_id = this.state.organizations_id;
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).units;
    let selected = params.filter((e) => e.org_id == organizations_id);

    for (let i = 0; i < selected.length; i++) {
      items.push(
        // <option key={i} value={selected[i].value}>
        { value: selected[i].id, label: selected[i].name }
        // </option>
      );
    }

    return items;
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onDropdownSelected(e) {
    if (e.target.name === 'organizations_id' && e.target.value === "3") {
      this.setState({
        [e.target.name]: e.target.value,
        payment_type: 2,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  onDropdownSelectedUnits(e) {
    this.setState({ units_id: e.value });
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).units;
    let units = params.find((i) => i.id == e.value);
    let units_name = units ? units.name : "";
    let va_code = units ? units.va_code : "";
    this.setState({
      units_name: units_name,
      va: va_code,
      units_id: e.value,
    });
  }

  onChangeDate = (jsDate, dateString) => {
    this.setState({ dob: dateString });
  };

  validateForm() {
    const { location } = this.props;
    const { state } = location;
    const { isEdit = false, data } = state;
    if (this.state.registration_fee.length < 1) {
      return true;
    }
    return false;
    // return (
    //   this.state.registration_fee?this.state.registration_fee.length : 0 > 0
    //   || data.registration_fee
    // );
    //&& this.state.birth_certificate.length > 0;
  }

  handleSubmit = (event) => {
    const { prm_document, subdomain, user_level } = this.state;
    event.preventDefault();

    fetch(global.variable.LINK_API + "settings/createunit", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(this.state),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success").then((value) => {
            window.location.href = user_level == 7 ? "/pengaturanpusat" : "/pengaturanunit";
          });
        } else {
          global.swal("Error", resData.error, "info");
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal(
              "Error",
              "Subdomain sudah ada mohon diperiksa kembali.",
              "info"
            );
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleErrors = async (response) => {
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors };
      throw errorMessage;
    }
    return response;
  };

  handleUpdate = (event) => {
    const { prm_document, subdomain, user_level } = this.state;

    event.preventDefault();
    // let kop = JSON.parse(localStorage.getItem("kop")).header;
    let kop = this.state.kop;
    // let logo = JSON.parse(localStorage.getItem("logo")).logo;
    let logo = this.state.image;

    if (logo == null || kop == null) {
      global.swal("Error", "Harap pilih file gambar", "info");
    } else {
      if (this.state.image) {
        const file_upload = new FormData();
        // file_upload.append("download_document", this.state.selectedFile);
        file_upload.append("image", this.state.selectedFile);

        return fetch(global.variable.LINK_API_UPLOAD + "image/upload", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: file_upload,
        })
          .then(this.handleErrors)
          .then((response) => response.json())
          .then((resData) => {
            if (resData.name) {
              const data_name = resData.name;

              const form_data = new FormData();
              form_data.append("name", data_name);
              form_data.append("folder", "kliksekolah/ppdb");

              return fetch(global.variable.LINK_API_UPLOAD + "image/move", {
                method: "post",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + global.cookies.get("access_token"),
                },
                body: form_data,
              })
                .then(this.handleErrors)
                .then((response) => response.json())
                .then((resData2) => {
                  if (resData2 === "Berhasil dipindahkan") {
                    this.setState({
                      logo:
                        global.variable.LINK_API_UPLOAD +
                        "cache/kliksekolah/500/0/ppdb/" +
                        data_name,
                    });

                    if (this.state.kop) {
                      const file_upload = new FormData();
                      // file_upload.append("download_document", this.state.selectedFile);
                      file_upload.append("image", this.state.selectedFile2);

                      return fetch(
                        global.variable.LINK_API_UPLOAD + "image/upload",
                        {
                          method: "post",
                          headers: {
                            Accept: "application/json",
                            Authorization:
                              "Bearer " + global.cookies.get("access_token"),
                          },
                          body: file_upload,
                        }
                      )
                        .then(this.handleErrors)
                        .then((response) => response.json())
                        .then((resData) => {
                          if (resData.name) {
                            const data_name = resData.name;

                            const form_data = new FormData();
                            form_data.append("name", data_name);
                            form_data.append("folder", "kliksekolah/ppdb");

                            return fetch(
                              global.variable.LINK_API_UPLOAD + "image/move",
                              {
                                method: "post",
                                headers: {
                                  Accept: "application/json",
                                  Authorization:
                                    "Bearer " +
                                    global.cookies.get("access_token"),
                                },
                                body: form_data,
                              }
                            )
                              .then(this.handleErrors)
                              .then((response) => response.json())
                              .then((resData) => {
                                if (resData === "Berhasil dipindahkan") {
                                  this.setState({
                                    header:
                                      global.variable.LINK_API_UPLOAD +
                                      "cache/kliksekolah/500/0/ppdb/" +
                                      data_name,
                                  });
                                }
                              });
                          }
                        });
                    }
                  }
                })
                .then(() => {
                  let levels = JSON.parse(localStorage.getItem("levels_id"));

                  fetch(global.variable.LINK_API + "settings/editunit", {
                    method: "post",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization:
                        "Bearer " + global.cookies.get("access_token"),
                    },
                    body: JSON.stringify(this.state),
                  })
                    .then((response) => response.json())
                    .then((resData) => {
                      if (resData.message === "Success") {
                        global
                          .swal("Success", resData.message, "success")
                          .then((value) => {
                            if (levels == 5) {
                              window.location.href = "/pengaturanadmin";
                            }
                            //  else if (user_level == 7) {
                            //   window.location.href = "/pengaturanpusat";
                            // } 
                            else {
                              window.location.href = user_level == 7 ? "/pengaturanpusat" : "/pengaturanunit";
                            }
                          });
                      } else {
                        global.swal("Error", resData.error, "info");
                      }
                    })
                    .catch((err) => {
                      if (err) {
                        if (err.code !== 403) {
                          global.swal(
                            "Error",
                            "Koneksi ke server gagal.",
                            "info"
                          );
                        }
                      } else {
                        global.swal.stopLoading();
                        global.swal.close();
                      }
                    });
                });
            }
          });
      }

      if (this.state.kop && this.state.image == "") {
        const file_upload2 = new FormData();

        // file_upload.append("download_document", this.state.selectedFile);
        file_upload2.append("image", this.state.selectedFile2);

        return fetch(global.variable.LINK_API_UPLOAD + "image/upload", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: file_upload2,
        })
          .then(this.handleErrors)
          .then((response) => response.json())
          .then((resData) => {
            if (resData.name) {
              const data_name = resData.name;

              const form_data = new FormData();
              form_data.append("name", data_name);
              form_data.append("folder", "kliksekolah/ppdb");

              return fetch(global.variable.LINK_API_UPLOAD + "image/move", {
                method: "post",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + global.cookies.get("access_token"),
                },
                body: form_data,
              })
                .then(this.handleErrors)
                .then((response) => response.json())
                .then((resData) => {
                  if (resData === "Berhasil dipindahkan") {
                    this.setState({
                      header:
                        global.variable.LINK_API_UPLOAD +
                        "cache/kliksekolah/500/0/ppdb/" +
                        data_name,
                    });
                  }
                })
                .then(() => {
                  let levels = JSON.parse(localStorage.getItem("levels_id"));

                  fetch(global.variable.LINK_API + "settings/editunit", {
                    method: "post",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization:
                        "Bearer " + global.cookies.get("access_token"),
                    },
                    body: JSON.stringify(this.state),
                  })
                    .then((response) => response.json())
                    .then((resData) => {
                      if (resData.message === "Success") {
                        global
                          .swal("Success", resData.message, "success")
                          .then((value) => {
                            if (levels == 5) {
                              window.location.href = "/pengaturanadmin";
                            } else {
                              window.location.href = user_level == 7 ? "/pengaturanpusat" : "/pengaturanunit";
                            }
                          });
                      } else {
                        global.swal("Error", resData.error, "info");
                      }
                    })
                    .catch((err) => {
                      if (err) {
                        if (err.code !== 403) {
                          global.swal(
                            "Error",
                            "Koneksi ke server gagal.",
                            "info"
                          );
                        }
                      } else {
                        global.swal.stopLoading();
                        global.swal.close();
                      }
                    });
                });
            }
          });
      }

      if (this.state.kop == "" && this.state.image == "") {
        let levels = JSON.parse(localStorage.getItem("levels_id"));

        fetch(global.variable.LINK_API + "settings/editunit", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(this.state),
        })
          .then((response) => response.json())
          .then((resData) => {
            if (resData.message === "Success") {
              global
                .swal("Success", resData.message, "success")
                .then((value) => {
                  if (levels == 5) {
                    window.location.href = "/pengaturanadmin";
                  } else {
                    window.location.href = user_level == 7 ? "/pengaturanpusat" : "/pengaturanunit";
                  }
                });
            } else {
              global.swal("Error", resData.error, "info");
            }
          })
          .catch((err) => {
            if (err) {
              if (err.code !== 403) {
                global.swal("Error", "Koneksi ke server gagal.", "info");
              }
            } else {
              global.swal.stopLoading();
              global.swal.close();
            }
          });
      }
    }
  };

  onChangeHandler = (event) => {
    var file = event.target.files[0];

    if (file.size > 2000000) {
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info");
      this.setState({ selectedFile: "", image: "" });
    } else if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      this.setState({ selectedFile: file, image: file.name });
    } else {
      global.swal(
        "Error",
        "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf",
        "info"
      );
      this.setState({ selectedFile: "", image: "" });
    }
  };

  onChangeHandler2 = (event) => {
    var file = event.target.files[0];

    if (file.size > 2000000) {
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info");
      this.setState({ selectedFile2: "", kop: "" });
    } else if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      this.setState({ selectedFile2: file, kop: file.name });
    } else {
      global.swal(
        "Error",
        "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf",
        "info"
      );
      this.setState({ selectedFile2: "", kop: "" });
    }
  };

  download = (param) => (e) => {
    e.preventDefault();
    window.open(
      // "/image/" + param,
      param,
      "_blank",
      "location=yes,height=600,width=600,scrollbars=yes,status=yes"
    );

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  };

  handleBack = () => {
    window.location.href = "/pendaftaran";
  };

  render() {
    const { location } = this.props;
    const { state } = location;
    const { isEdit = false } = state;
    const { form, organizations_id, user_level } = this.state;

    // let kop = JSON.parse(localStorage.getItem("kop")).header;
    let kop = this.state.header;
    // let logo = JSON.parse(localStorage.getItem("logo")).logo;
    let logo = this.state.logo;

    let subdomain = this.state.subdomain;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          {isEdit ? "Edit Unit" : "Tambah Unit"}
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form
                        onSubmit={
                          isEdit ? this.handleUpdate : this.handleSubmit
                        }
                      >
                        {isEdit ? (
                          <div>
                            <div className="form-row">
                              <div className="col-md-6">
                                <b>Subdomain</b>
                                <input
                                  className="form-control"
                                  type="Text"
                                  name="jalur"
                                  value={subdomain || ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Logo</label>
                                <input
                                  enctype="multipart/form-data"
                                  className="form-control"
                                  type="file"
                                  name="file"
                                  maxLength="50"
                                  fieldname="image_data"
                                  onChange={this.onChangeHandler}
                                />
                                {logo ? (
                                  <Link
                                    to=""
                                    target="_blank"
                                    onClick={this.download(logo)}
                                  >
                                    Lihat File
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="form-group col-md-6">
                                <label>Kop Surat</label>
                                <input
                                  enctype="multipart/form-data"
                                  className="form-control"
                                  type="file"
                                  name="kop"
                                  maxLength="50"
                                  fieldname="image_data"
                                  onChange={this.onChangeHandler2}
                                />
                                {kop ? (
                                  <Link
                                    to=""
                                    target="_blank"
                                    onClick={this.download(kop)}
                                  >
                                    Lihat File
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>
                                  Tahun Registrasi{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                {/* <input
                                  className="form-control"
                                  type="Text"
                                  name="registration_year"
                                  maxLength="100"
                                  value={this.state.registration_year}
                                  onChange={this.onChange}
                                  required
                                /> */}
                                <select
                                  className="form-control"
                                  name="registration_year"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={this.state.registration_year}
                                  required
                                >
                                  <option value="">-Pilih Tipe-</option>
                                  {this.createSelectRegistration()}
                                </select>

                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Uang Pendaftaran{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                {/* <CurrencyFormat
                                  input="number"
                                  name="registration_fee"
                                  onChange={this.onChange}
                                  value={this.state.registration_fee}
                                  maxLength="100"
                                  style={{ width: "100%" }}
                                  required
                                /> */}

                                <input
                                  className="form-control"
                                  type="number"
                                  name="registration_fee"
                                  maxLength="100"
                                  value={this.state.registration_fee}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>
                                  Buka Pendaftaran{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  name="open_registration"
                                  maxLength="100"
                                  value={this.state.open_registration}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                              {/* format nya kayaknya harus di ganti deh
                              karna pas setstate bentuknya beda
                           */}
                              <div className="form-group col-md-6">
                                <label>
                                  Tutup Pendaftaran{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="date"
                                  name="close_registration"
                                  maxLength="100"
                                  value={this.state.close_registration}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                            {
                              parseFloat(organizations_id) !== 3 ?
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label>
                                      Tipe Pembayaran{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      className="form-control"
                                      name="payment_type"
                                      onChange={this.onDropdownSelected.bind(this)}
                                      value={this.state.payment_type}
                                      disabled={organizations_id == 3 ? true : false}
                                      required
                                    >
                                      <option value="">-Pilih Tipe-</option>
                                      {this.createSelectPaymentType()}
                                    </select>
                                  </div>
                                  {this.state.payment_type == 1 ? (
                                    <div className="form-group col-md-6">
                                      <label>
                                        Nama Bank{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="Text"
                                        name="bank_name"
                                        maxLength="100"
                                        value={this.state.bank_name}
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div> : null
                            }
                            {this.state.payment_type == 1 ? (
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label>
                                    Nomor Rekening{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="account_number"
                                    maxLength="50"
                                    value={this.state.account_number}
                                    onChange={this.onChange}
                                    required
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>
                                    Nama Rekening{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="account_name"
                                    maxLength="100"
                                    value={this.state.account_name}
                                    onChange={this.onChange}
                                    required
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>
                                  Id Organisasi{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-control"
                                  name="organizations_id"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={this.state.organizations_id}
                                  disabled={user_level == 7 ? true : false}
                                  required
                                >
                                  <option value="">-Pilih Organisasi-</option>
                                  {this.createSelectOrganizations()}
                                </select>
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Unit <span className="text-danger">*</span>
                                </label>
                                <Select
                                  className="basic-single"
                                  name="units_id"
                                  onChange={this.onDropdownSelectedUnits.bind(
                                    this
                                  )}
                                  defaultValue={this.state.units_id}
                                  options={this.createSelectUnit()}
                                  classNamePrefix="select"
                                  required
                                ></Select>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>
                                  Subdomain{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="Text"
                                  name="subdomain"
                                  maxLength="100"
                                  value={this.state.subdomain}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="exampleForm2">
                                  Nama Sekolah{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="Text"
                                  name="name"
                                  maxLength="100"
                                  value={this.state.units_name}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>
                                  Tingkat <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-control"
                                  name="education_id"
                                  onChange={this.onDropdownSelected.bind(this)}
                                  value={this.state.education_id}
                                  required
                                >
                                  <option value="">-Pilih Tingkat-</option>
                                  {this.createSelectEducation()}
                                </select>
                              </div>
                              {/* format nya kayaknya harus di ganti deh
                               karna pas setstate bentuknya beda
                            */}
                              <div className="form-group col-md-6">
                                <label>Kota</label>
                                <input
                                  className="form-control"
                                  name="city"
                                  onChange={this.onChange}
                                  value={this.state.city}
                                  maxLength="100"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>email</label>
                                <input
                                  className="form-control"
                                  type="Text"
                                  name="email"
                                  maxLength="100"
                                  value={this.state.email}
                                  onChange={this.onChange}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>
                                  Virtual Account{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  name="va"
                                  maxLength="100"
                                  value={this.state.va}
                                  onChange={this.onChange}
                                  required
                                />
                              </div>
                            </div>
                            {
                              parseFloat(organizations_id) !== 3 ?
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label>
                                      Tipe Pembayaran{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      className="form-control"
                                      name="payment_type"
                                      onChange={this.onDropdownSelected.bind(this)}
                                      value={this.state.payment_type}
                                      // disabled={organizations_id == 3 ? true : false}
                                      required
                                    >
                                      <option value="">-Pilih Tipe-</option>
                                      {this.createSelectPaymentType()}
                                    </select>
                                  </div>
                                  <div className="form-group col-md-6"></div>
                                </div> : null
                            }
                          </div>
                        )}
                        <MDBBtn
                          color="primary"
                          block
                          type="submit"
                          disabled={isEdit && this.validateForm()}
                        >
                          {isEdit ? "Update" : "Submit"}
                        </MDBBtn>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Tambah_unit;
