import React, { Component } from 'react';
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  // MDBContainer,
  // MDBNavbar,
  // MDBNavbarNav,
  // MDBNavItem,
  // MDBRow,
  // MDBCol,
  MDBCard,
  MDBCardBody,
  // MDBInput,
  // MDBSwitch,
  // MDBFileInput,
  // MDBRangeInput,
  // MDBSelect,
  // MDBSelectInput,
  // MDBSelectOptions,
  // MDBSelectOption,
  // MDBLink,
  MDBBtn,
} from "mdbreact";
import Select from 'react-select'

class Report_Pendftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CB_STUDYYEAR: '',
      CB_REGTYPE: '',
      CB_JALUR: '',
      CB_FLOW: '',
      val_jalur: '',
      val_studyyear: '',
      valueYear: {},
      valueStatus: '',
      is_show: false,
    }
  }

  componentDidMount() {
    console.log(global.cookies)
    /*fetch(global.variable.LINK_API + 'student/flow/3',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

        this.setState({CB_JALUR : resData.data});

      }
      console.log(resData);
       
    })*/

    fetch(global.variable.LINK_API + 'auth/parameter',
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          this.setState({ CB_FLOW: JSON.parse(resData.data.prm_flow.flow), CB_STUDYYEAR: resData.data.prm_study_year });
          localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
        }
        console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));

      })
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(<option key={i} value={this.state.CB_JALUR[i].id + '-' + this.state.CB_JALUR[i].fullname}>{this.state.CB_JALUR[i].id + ' - ' + this.state.CB_JALUR[i].fullname}</option>);
    }
    return items;
  }

  createSelectItemsStudyYear() {
    // let items = [];
    // for (let i = 0; i < this.state.CB_STUDYYEAR.length; i++) {
    //   items.push(<option key={i} value={this.state.CB_STUDYYEAR[i].id}>{this.state.CB_STUDYYEAR[i].name}</option>);
    // }
    // return items;
    let items = [];
    for (let i = 0; i < this.state.CB_STUDYYEAR.length; i++) {
      items.push(
          {
            value: this.state.CB_STUDYYEAR[i].id,
            label: this.state.CB_STUDYYEAR[i].name
          }
      );
    }
    return <Select options={items} isClearable onChange={e => this.onDropdownSelected2('valueYear',e)} placeholder="- Pilih Tahun -"/>;
  }

  createSelectItemsREG() {
    let items = [];
    for (let i = 0; i < this.state.CB_REGTYPE.length; i++) {
      items.push(<option key={i} value={this.state.CB_REGTYPE[i].id}>{this.state.CB_REGTYPE[i].id + ' - ' + this.state.CB_REGTYPE[i].name}</option>);
    }
    return items;
  }

  getMaster(id = ''){
    var array = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_flow;
    var arrayfilter = array.filter((data) => { if(data.id == id) return data});
    var name = arrayfilter[0].name || '';
    return name;
  }

  createSelectItemsFlow() {
    // let items = [];
    // for (let i = 0; i < this.state.CB_FLOW.length; i++) {
    //   items.push(<option key={i} value={this.state.CB_FLOW[i].id}>{this.state.CB_FLOW[i].name}</option>);
    // }
    // return items;
    let items = [];
    for (let i = 0; i < this.state.CB_FLOW.length; i++) {
      items.push(
          {
            value: this.state.CB_FLOW[i],
            label: this.getMaster(this.state.CB_FLOW[i])
          }
      );
    }
    return <Select options={items} isClearable onChange={e => this.onDropdownSelected2('valueStatus',e)} placeholder="- Semua Status -"/>;
  }

  onDropdownSelected2(name,e) {
    const value = e ? e.value : {};
    this.setState({ [name]: value });
  }

  onDropdownSelected(e) {
    console.log([e.target.name] + ' - ' + e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }

  onDropdownSelectedJalur(e) {
    if (e.target.value === "") {
      this.setState({ is_show: false });
    } else {

      fetch(global.variable.LINK_API + 'student/flow/7?registration_type_id=' + e.target.value,
        {
          method: 'get',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          //body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(resData => {
          if (resData.message === "Success") {
            //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

            this.setState({ CB_JALUR: resData.data, is_show: true });

          }
          console.log(resData);

        })
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    // let jalur = this.state.val_regtype ? this.state.val_regtype : '';
    // let flow = this.state.val_flow ? this.state.val_flow : '';

    /*localStorage.setItem('DATA_JALUR', this.state.val_jalur);*/


    // const data = { registration_type_id: jalur, flow_id: flow, study_year: this.state.val_studyyear }

    // console.log(data);
    let params = '?type=registration';
    if(typeof this.state.valueYear === 'number'){
      params += '&year='+this.state.valueYear;
    }

    if(typeof this.state.valueStatus === 'string'){
      params += '&flow='+this.state.valueStatus;
    }
    fetch(global.variable.LINK_API + 'report' + params.trim(),
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        // body: JSON.stringify(data),
      })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(resData => {
        console.log(resData)
        if (resData.message === "Success") {
          localStorage.setItem('report_pendaftaran', JSON.stringify(resData.data));
          // window.location.href = "/reportpendaftarandetail";
          this.props.history.push("/reportpendaftarandetail")
          // this.state.history.push('reportpendaftarandetail')
        } else {
          global.swal("Error", resData.error, "info")
        }

      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            let message;
            if('errors' in err){
              if(Array.isArray(err.errors)){
                message = err.errors[0];
              } else {
                message = err.errors;
              }
            } else {
              message = "Koneksi ke server gagal.";
            }
            global.swal("Error", message, "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

  }

  handleErrors = async (response) => {
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = {code: response.status,...errors}
      throw errorMessage;
    }
    return response;
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">

                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Laporan Pendaftaran
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>

                        <div className='form-group'>
                          <label htmlFor="exampleForm2">Pilih Tahun</label>
                          {/* <select
                            className="form-control" as="select" name="val_studyyear" onChange={this.onDropdownSelected.bind(this)}>
                            <option value="">- Semua Tahun -</option> */}
                            {this.createSelectItemsStudyYear()}

                          {/* </select> */}
                        </div>

                        {/* <div className='form-group'>
                          <label htmlFor="exampleForm2">Pilih Jalur</label>
                          <select
                            className="form-control" as="select" name="val_regtype" onChange={this.onDropdownSelected.bind(this)}>
                            <option value="">- Semua Jalur -</option>
                            {this.createSelectItemsREG()}

                          </select>
                        </div> */}

                        <div className='form-group'>
                          <label htmlFor="exampleForm2">Pilih Status</label>
                          {/* <select
                            className="form-control" as="select" name="val_flow" onChange={this.onDropdownSelected.bind(this)}>
                            <option value="">- Semua Status -</option> */}
                            {this.createSelectItemsFlow()}

                          {/* </select> */}
                        </div>

                        <MDBBtn
                          color="primary"
                          block
                          type="submit">
                          Submit
                        </MDBBtn>




                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>


              </div>
            </div>
          </div>
        </section>
      </div>

    );
  }
}

export default Report_Pendftaran;