import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
import remSep from "string-remove-thousand-separators";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";

class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stay_id: "",
      transportation_id: "",
      electrical_power_id: "",
      school_distance_hour: "",
      school_distance_minute: "",
      hp: "",
      email: "",
      child: "",
      total_children: "",
      // blood_type_id : '',
      sibling_dependent: "",
      bill_pln: "",
      // height : '',
      // weight : '',
      // head_length : '',
      school_from_id: "",
      school_from_name: "",
      school_from_address: "",
      study_year: "",
      spp: "",
    };
  }

  validateForm() {
    return (
      this.state.stay_id.length > 0 &&
      this.state.bill_pln.length > 0 &&
      this.state.sibling_dependent.length > 0 &&
      this.state.transportation_id.length > 0 &&
      this.state.electrical_power_id.length > 0 &&
      this.state.school_distance_hour.length > 0 &&
      this.state.school_distance_minute.length > 0 &&
      this.state.hp.length > 0 &&
      this.state.email.length > 0 &&
      this.state.child.length > 0 &&
      this.state.total_children.length > 0 &&
      // && this.state.blood_type_id.length > 0
      // && this.state.weight.length > 0
      // && this.state.head_length.length > 0
      this.state.school_from_id.length > 0 &&
      this.state.school_from_name.length > 0 &&
      this.state.school_from_address.length > 0 &&
      this.state.study_year.length > 0
    );
  }

  componentDidMount() {
    if (localStorage.getItem("reg3")) {
      this.setState(JSON.parse(localStorage.getItem("reg3")));
      console.log(localStorage.getItem("reg3"));
    }
  }

  createSelectStay() {
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prmStay;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectTransportation() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prmTransportation;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectElectricalPower() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prmElectricalPower;
    console.log(JSON.parse(localStorage.getItem("DATA_PARAMETER")));
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectBloodtype() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prmBloodType;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectSchoolfrom() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prmSchoolForm;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onChangeNom = (e) =>
    this.setState({ [e.target.name]: remSep(e.target.value) });

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.stay_id === "") {
      global.swal("Info", "Harap pilih tinggal dengan", "info");
    } else if (this.state.transportation_id === "") {
      global.swal("Info", "Harap pilih moda transportasi", "info");
    } else if (this.state.blood_type_id === "") {
      global.swal("Info", "Harap pilih golongan darah", "info");
    } else if (this.state.electrical_power_id === "") {
      global.swal("Info", "Harap pilih golongan listrik", "info");
    } else if (this.state.school_from_id === "") {
      global.swal("Info", "Harap pilih sekolah asal", "info");
    } else {
      localStorage.setItem("reg3", JSON.stringify(this.state));
      console.log(localStorage.getItem("reg3"));
      window.location.href = "/pendaftaran4";
    }
  };

  handleBack = () => {
    window.location.href = "/pendaftaran2";
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pendaftaran Step 3
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleForm2">
                              Tinggal Dengan *
                            </label>
                            <select
                              type="text"
                              id="exampleForm2"
                              className="form-control"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={this.state.stay_id}
                              name="stay_id"
                              required
                            >
                              <option value="">- Pilih Tinggal Dengan -</option>
                              {this.createSelectStay()}
                            </select>
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleForm2">
                              Moda Transportasi *
                            </label>
                            <select
                              type="text"
                              id="exampleForm2"
                              className="form-control"
                              name="transportation_id"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={this.state.transportation_id}
                              required
                            >
                              <option value="">
                                - Pilih Moda Transportasi -
                              </option>
                              {this.createSelectTransportation()}
                            </select>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Jarak Tempuh (KM) *</label>
                            <input
                              type="number"
                              className="form-control"
                              name="school_distance_hour"
                              onChange={this.onChange}
                              value={this.state.school_distance_hour}
                              maxLength="25"
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Waktu Tempuh (Menit) *</label>
                            <input
                              className="form-control"
                              type="number"
                              name="school_distance_minute"
                              onChange={this.onChange}
                              value={this.state.school_distance_minute}
                              maxLength="25"
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>
                              Nomor Handphone (No HP Anak atau Orang Tua) *
                            </label>
                            <CurrencyFormat 
                              className="form-control"
                              input="number"
                              name="hp" 
                              format="+62############"
                              onChange={this.onChange} 
                              value={this.state.hp}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              />
                           
                          </div>
                          <div className="form-group col-md-6">
                            <label>Email Anak *</label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              maxLength="100"
                              value={this.state.email}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Anak ke *</label>
                            <input
                              className="form-control"
                              type="number"
                              name="child"
                              onChange={this.onChange}
                              value={this.state.child}
                              maxLength="25"
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Dari bersaudara *</label>
                            <input
                              className="form-control"
                              type="number"
                              name="total_children"
                              onChange={this.onChange}
                              value={this.state.total_children}
                              maxLength="2"
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>
                              Saudara Yang Masih Menjadi Tanggungan Orang Tua
                              ... Orang *
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              name="sibling_dependent"
                              onChange={this.onChange}
                              value={this.state.sibling_dependent}
                              maxLength="2"
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Sekolah Asal *</label>
                            <select
                              type="text"
                              id="exampleForm2"
                              className="form-control"
                              name="school_from_id"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={this.state.school_from_id}
                              required
                            >
                              <option value="">- Pilih Sekolah Asal -</option>
                              {this.createSelectSchoolfrom()}
                            </select>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>
                              Nama Sekolah Asal (Sesuai Identitas Raport)*
                            </label>
                            <input
                              className="form-control"
                              type="Text"
                              name="school_from_name"
                              maxLength="50"
                              value={this.state.school_from_name}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              Alamat Sekolah Asal (Sesuai Identitas Raport)*
                            </label>
                            <input
                              className="form-control"
                              type="Text"
                              name="school_from_address"
                              maxLength="100"
                              value={this.state.school_from_address}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Lama Belajar (Dalam Tahun)</label>
                            <input
                              className="form-control"
                              input="number"
                              name="study_year"
                              onChange={this.onChange}
                              value={this.state.study_year}
                              maxLength="5"
                              style={{ width: "100%", marginTop: "5%" }}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>
                              Uang SPP Sekolah Asal (isi hanya angka jangan
                              diberi titik dan koma, jika tidak ada diisi dengan
                              angka 0)
                            </label>
                            <CurrencyFormat
                              className="form-control"
                              input="number"
                              name="spp"
                              onChange={this.onChangeNom}
                              value={this.state.spp}
                              maxLength="25"
                              thousandSeparator={true}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Daya Listrik *</label>
                            <select
                              type="text"
                              id="exampleForm2"
                              className="form-control"
                              name="electrical_power_id"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={this.state.electrical_power_id}
                              required
                            >
                              <option value="">
                                - Pilih Golongan Listrik -
                              </option>
                              {this.createSelectElectricalPower()}
                            </select>
                          </div>
                          <div className="form-group col-md-6">
                            <label>Biaya rata-rata listrik per bulan *</label>
                            <CurrencyFormat
                              className="form-control"
                              input="number"
                              name="bill_pln"
                              onChange={this.onChangeNom}
                              value={this.state.bill_pln}
                              maxLength="25"
                              thousandSeparator={true}
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <MDBBtn
                              block
                              color="primary"
                              onClick={this.handleBack}
                            >
                              Prev
                            </MDBBtn>
                          </div>
                          <div className="col-md-6">
                            <MDBBtn
                              block
                              color="primary"
                              type="submit"
                              disabled={!this.validateForm()}
                            >
                              Next
                            </MDBBtn>
                          </div>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
