import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBTable,
} from "mdbreact";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import CurrencyFormat from "react-currency-format";
import remSep from "string-remove-thousand-separators";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
      </div>
    )
  );
};

class Edit_prm_payment extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const { state } = location;
    const { data } = state;

    this.state = {
      isEdit: false,
      name: "",
      nick_name: "",
      coa: "",
      value: "",
      is_edit: 0,
      is_routine: 1,
      is_potong: 0,
      is_dp: 0,
      data: data,
      subdomain: data.subdomain,
      organizations_id: data.organizations_id,
      listPayment: [],
      prm_potong: [],
      dp_percent: 0,
      potongan_percent: 0,
    };
  }



  async resetState() {
    window.scrollTo(0, 0);
    const { location } = this.props;
    const { state } = location;
    const { data } = state;

    this.setState({
      isEdit: false,
      name: "",
      nick_name: "",
      coa: "",
      value: "",
      payments_id: "",
      is_edit: 0,
      is_routine: 1,
      is_potong: 0,
      is_dp: 0,
      data: data,
      subdomain: data.subdomain,
      listPayment: [],
      prm_potong: [],
      dp_percent: 0,
      potongan_percent: 0,
    });
  }
  componentDidMount() {

    fetch(global.variable.LINK_API + "settings/param/prmPayments", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify({ subdomain: this.state.subdomain })
    })
      .then((response) => response.json())
      .then((resData) => {
        if (!isEmpty(resData)) {
          this.setState({ prmPayments: resData, is_show: true });
        }
      });
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {

    await this.resetState();

    // list payment
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/param/listPayment", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify({ subdomain: this.state.subdomain })
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listPayment: resData, is_show: true });
          }
        })
    );
  }

  createSelectItems() {
    const param = [
      { value: 0, label: "Tidak Ada Potongan" },
      { value: 1, label: "Potongan Presentase" },
      { value: 2, label: "Potongan Lainnya" },
    ]

    let items = [];
    param.map((list, idx) => {
      items.push(
        <option key={idx} value={list.value}>
          {list.label}
        </option>
      );
    })
    return items;
  }

  createSelectPrmPayments() {
    const { prmPayments } = this.state;
    let items = [];
    let params = prmPayments ? prmPayments : '';
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }


  createSelectIsEdit() {
    const param = [
      { value: 1, label: "Ya" },
      { value: 0, label: "Tidak" },
    ]

    let items = [];
    param.map((list, idx) => {
      items.push(
        <option key={idx} value={list.value}>
          {list.label}
        </option>
      );
    })
    return items;
  }

  onChange = (e) => {
    var a = e.target.value;
    if (e.target.name === "is_potong" && e.target.value != 2) {
      this.setState({ prm_potong: [] })
    }
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onDropdownSelected(e) {
    const { prmPayments } = this.state;
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);

    let prm = prmPayments.find((i) => i.id == e.target.value);
    let name_prm = prm ? prm.name : '';
    let nickname_prm = prm ? prm.nick_name : '';
    let coa_prm = prm ? prm.coa : '';
    let is_routine_prm = prm ? prm.is_routine : '';
    this.setState({
      name: name_prm,
      nick_name: nickname_prm,
      coa: coa_prm,
      is_routine: is_routine_prm,
      value: '',
      dp_percent: 0,
      potongan_percent: 0,
    })
    // console.log(e)
  }

  onChangeNom = (e) => {
    this.setState({
      [e.target.name]:
        e.target.value !== "" ? parseFloat(remSep(e.target.value)) : 0
    });
  };

  onChangeArr = (e) => {
    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")

    let newList = []
    newList = this.state[fieldname];

    newList[idx][e.target.name] = e.target.value
    this.setState({ [fieldname]: newList });
  };

  onChangeArrNom = (e) => {
    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")

    let newList = []
    newList = this.state[fieldname];

    newList[idx][e.target.name] = e.target.value != "" ? parseFloat(remSep(e.target.value)) : 0;
    this.setState({ [fieldname]: newList });
  };

  onChangeCheck = (e) => {
    const val = e.target.checked ? "1" : "0";
    this.setState({ [e.target.name]: val });
  };

  onClickDel(idx) {
    const { prm_potong } = this.state;
    prm_potong.splice(idx, 1)
    this.setState({ prm_potong: prm_potong })
  }

  onClickAdd() {
    const { prm_potong } = this.state;
    prm_potong.push({
      name: "",
      coa: "",
      value: 0,
      is_edit: 1,
    });
    this.setState({ prm_potong: prm_potong })
  }

  onClickEdit(val) {
    window.scrollTo(0, 0);
    // if (val) {
    this.setState({
      isEdit: true,
      id: val.id,
      payments_id: val.payments_id,
      name: val.name,
      nick_name: val.nick_name,
      coa: val.coa,
      value: val.value,
      is_edit: val.is_edit,
      is_routine: val.is_routine,
      is_potong: val.is_potong,
      is_dp: val.is_dp,
      prm_potong: val.potong,
      dp_percent: val.dp_percent ?? 0,
      potongan_percent: val.potongan_percent ?? 0,
    })
    // }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    Swal.fire({
      title: "Mohon Tunggu Sebentar !",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(global.variable.LINK_API + 'settings/param/createPrmPayments',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(this.state),
      })
      .then(response => response.json())
      .then(resData => {
        Swal.close();
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success",)
            .then((value) => {
              this._onSearchContent();
            });
        } else {
          global.swal("Error", resData.error, "info")
        }

      }).catch(err => {
        Swal.close();
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

  };

  handleUpdate = (event) => {
    event.preventDefault();

    Swal.fire({
      title: "Mohon Tunggu Sebentar !",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(global.variable.LINK_API + 'settings/param/editPrmPayments',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(this.state),
      })
      .then(response => response.json())
      .then(resData => {
        Swal.close();
        console.log(resData)
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success",)
            .then((value) => {
              this._onSearchContent();
            });
        } else {
          global.swal("Error", resData.error, "info")
        }

      }).catch(err => {
        Swal.close();
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

  };

  onShowDetail(data) {
    this.openModalShowDetail(data);
  }

  openModalShowDetail(data) {
    MySwal.fire({
      width: "750px",
      html: this.renderModalContentShowDetail(data),
      showCancelButton: false,
      showConfirmButton: true,
    });
  }

  renderModalContentShowDetail(data) {
    let currency = Intl.NumberFormat("en-ID");
    return (
      <div className="form-group col-md-12 p-4">
        <MDBTable bordered>
          <thead className="blue-grey lighten-4">
            <tr>
              <th style={{ textAlign: "center", width: "1%" }}>No</th>
              <th style={{ textAlign: "center" }}>Nama</th>
              <th style={{ textAlign: "center" }}>COA</th>
              <th style={{ textAlign: "center" }}>Nominal</th>
              <th style={{ textAlign: "center" }}>Is Edit</th>
            </tr>
          </thead>
          <tbody>
            {data.map((list, idx) => {
              return (
                <tr key={idx}>
                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>{idx + 1}</td>
                  <td style={{ verticalAlign: "middle" }}>{list.name}</td>
                  <td style={{ verticalAlign: "middle" }}>{list.coa}</td>
                  <td style={{ verticalAlign: "middle" }}>Rp. {currency.format(list.value)}</td>
                  <td style={{ verticalAlign: "middle", textAlign: "center", width: "20%" }}>
                    {
                      (list.is_edit == 1) ? "Ya" : "Tidak"
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
        </MDBTable>
      </div>
    );
  }

  render() {
    const { isEdit, data, listPayment, prm_potong } = this.state;
    let currency = Intl.NumberFormat("en-ID");

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        {/* <MDBBreadcrumbItem href>
                          <a href="/pengaturanunit">Pengaturan Unit</a>
                        </MDBBreadcrumbItem> */}
                        <MDBBreadcrumbItem active>
                          Edit Pengaturan Wawancara
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}>
                        <div className="form-row">
                          <div className="col-md-6">
                            <b>Subdomain</b>
                            <input
                              className="form-control"
                              type="Text"
                              name="jalur"
                              value={data.subdomain || ""}
                              disabled
                            />
                          </div>
                        </div>
                        <br></br>
                        <div className="form-row">
                          <div className="form-group col-md-3">
                            <label>Nama Pembayaran *</label>
                            {/* <input
                              className="form-control"
                              type="Text"
                              name="name"
                              maxLength="100"
                              value={this.state.name || ""}
                              onChange={this.onChange}
                              required
                            /> */}
                            <select
                              className="form-control"
                              name="payments_id"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={this.state.payments_id}
                              required
                            >
                              <option value="">-Pilih Tipe-</option>
                              {this.createSelectPrmPayments()}
                            </select>
                          </div>
                          <div className="form-group col-md-3">
                            <label>Nama Singkat *</label>
                            <input
                              className="form-control"
                              type="Text"
                              name="nick_name"
                              disabled
                              maxLength="100"
                              value={this.state.nick_name || ""}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>COA *</label>
                            <input
                              className="form-control"
                              type="Text"
                              name="coa"
                              maxLength="100"
                              disabled
                              value={this.state.coa || ""}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Nominal</label>
                            <CurrencyFormat
                              className="form-control"
                              input="number"
                              name="value"
                              value={this.state.value || 0}
                              maxLength="25"
                              thousandSeparator={true}
                              style={{ width: "100%" }}
                              onChange={this.onChangeNom}
                            />
                          </div>
                          {
                            parseInt(this.state.is_routine) === 0 ?
                              <div className="form-group col-md-6">
                                <label>Potongan(%)</label>
                                <CurrencyFormat
                                  className="form-control"
                                  input="number"
                                  name="potongan_percent"
                                  value={this.state.potongan_percent}
                                  maxLength="2"
                                  thousandSeparator={true}
                                  style={{ width: "100%" }}
                                  onChange={this.onChangeNom}
                                />
                                {/* <select
                              className="form-control"
                              name="is_potong"
                              value={this.state.is_potong}
                              onChange={this.onChange}
                              required
                            >
                              <option value="">- Pilih Potongan -</option>
                              {this.createSelectItems()}
                            </select> */}
                              </div> : ''
                          }
                          {
                            parseInt(this.state.is_routine) === 0 ?
                              <div className="form-group col-md-6">
                                <label>DP(%)</label>
                                <CurrencyFormat
                                  className="form-control"
                                  input="number"
                                  name="dp_percent"
                                  value={this.state.dp_percent}
                                  maxLength="2"
                                  thousandSeparator={true}
                                  style={{ width: "100%" }}
                                  onChange={this.onChangeNom}
                                />
                              </div> : ''
                          }

                          <div className="form-group col-md-6">
                            <div className="row pl-3">
                              {console.log(this.state.is_edit)}
                              <MDBInput
                                label="Is Edit"
                                labelClass='mr-5'
                                type='checkbox'
                                value={this.state.is_edit}
                                name='is_edit'
                                checked={parseInt(this.state.is_edit) === 1 ? true : false}
                                id={'is_edit'}
                                onChange={this.onChangeCheck}
                                containerClass='my-3'
                              />
                              <MDBInput
                                label="Is Rutin"
                                labelClass='mr-5'
                                type='checkbox'
                                value={this.state.is_routine}
                                name='is_routine'
                                checked={parseInt(this.state.is_routine) === 1}
                                id={'is_routine'}
                                onChange={this.onChangeCheck}
                                containerClass='my-3'
                                disabled
                              />
                              {/* <MDBInput
                                label="Is DP"
                                labelClass='mr-5'
                                type='checkbox'
                                value={this.state.is_dp}
                                name='is_dp'
                                checked={this.state.is_dp === 1 ? true : false}
                                id={'is_dp'}
                                disabled={this.state.is_routine == 1 ? true : false}
                                onChange={this.onChangeCheck}
                                containerClass='my-3'
                              /> */}
                            </div>
                          </div>
                          {this.state.is_potong == 2 && (
                            <div className="form-group col-md-12 p-4">
                              <MDBTable bordered>
                                <thead className="blue-grey lighten-4">
                                  <tr>
                                    <th style={{ textAlign: "center", width: "1%" }}>No</th>
                                    <th style={{ textAlign: "center" }}>Nama</th>
                                    <th style={{ textAlign: "center" }}>COA</th>
                                    <th style={{ textAlign: "center" }}>Nominal</th>
                                    <th style={{ textAlign: "center" }}>Is Edit</th>
                                    <th style={{ textAlign: "center", width: "1%" }}>Opsi</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {prm_potong.map((list, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td style={{ verticalAlign: "middle", textAlign: "center" }}>{idx + 1}</td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          <input
                                            className="form-control"
                                            type="Text"
                                            name="name"
                                            value={list.name}
                                            onChange={this.onChangeArr}
                                            fieldname="prm_potong"
                                            index={idx}
                                            required
                                          />
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          <input
                                            className="form-control"
                                            type="Text"
                                            name="coa"
                                            value={list.coa}
                                            onChange={this.onChangeArr}
                                            fieldname="prm_potong"
                                            index={idx}
                                          />
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          <CurrencyFormat
                                            className="form-control"
                                            input="number"
                                            name="value"
                                            value={list.value}
                                            maxLength="25"
                                            thousandSeparator={true}
                                            style={{ width: "100%" }}
                                            onChange={this.onChangeArrNom}
                                            fieldname="prm_potong"
                                            index={idx}
                                            required
                                          />
                                        </td>
                                        <td style={{ verticalAlign: "middle", textAlign: "center", width: "20%" }}>
                                          <select
                                            className="form-control"
                                            name="is_edit"
                                            value={list.is_edit}
                                            onChange={this.onChangeArr}
                                            fieldname="prm_potong"
                                            index={idx}
                                          >
                                            {this.createSelectIsEdit()}
                                          </select>
                                        </td>
                                        <td style={{ textAlign: "center", width: "1%" }}>
                                          <MDBBtn outline color="primary" onClick={() => this.onClickDel(idx)}>
                                            <i className="fa fa-trash"></i>
                                          </MDBBtn>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </MDBTable>
                              <MDBBtn outline color="primary" block onClick={() => this.onClickAdd()}>
                                <i className="fa fa-plus"></i>
                              </MDBBtn>
                            </div>
                          )}
                        </div>
                        <MDBBtn color="primary" block type="submit">
                          {isEdit ? "Update" : "Submit"}
                        </MDBBtn>
                      </form>
                      <br></br>
                      <label htmlFor="exampleForm2">Daftar Pengaturan Wawancara</label>
                      <MDBTable bordered>
                        <thead className="blue-grey lighten-4">
                          <tr>
                            <th style={{ textAlign: "center" }}>No</th>
                            <th style={{ textAlign: "center" }}>Nama</th>
                            <th style={{ textAlign: "center" }}>Nama Singkat</th>
                            <th style={{ textAlign: "center" }}>COA</th>
                            <th style={{ textAlign: "center" }}>Nominal</th>
                            <th style={{ textAlign: "center" }}>DP(%)</th>
                            <th style={{ textAlign: "center" }}>Potongan(%)</th>
                            <th style={{ textAlign: "center" }}>Opsi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listPayment.map((list, idx) => {
                            return (
                              <tr key={idx}>
                                <td style={{ textAlign: "center", width: "1%" }}>{idx + 1}</td>
                                <td>
                                  {list.name}
                                  {parseInt(list.is_edit) === 1 && (
                                    <MDBInput
                                      label="Is Edit"
                                      labelClass='mr-5'
                                      type='checkbox'
                                      value={list.is_edit}
                                      name='is_edit'
                                      checked={parseInt(list.is_edit) === 1 ? true : false}
                                      id={'list_edit'}
                                      containerClass='my-3'
                                    />
                                  )}
                                  {parseInt(list.is_routine) === 1 && (
                                    <MDBInput
                                      label="Is Rutin"
                                      labelClass='mr-5'
                                      type='checkbox'
                                      value={list.is_routine}
                                      name='is_routine'
                                      checked={parseInt(list.is_routine) === 1 ? true : false}
                                      id={'list_routine'}
                                      containerClass='my-3'
                                    />
                                  )}
                                  {parseInt(list.is_dp) === 1 && (
                                    <MDBInput
                                      label="Is DP"
                                      labelClass='mr-5'
                                      type='checkbox'
                                      value={list.is_dp}
                                      name='is_dp'
                                      checked={parseInt(list.is_dp) === 1 ? true : false}
                                      id={'list_dp'}
                                      containerClass='my-3'
                                    />
                                  )}
                                </td>
                                <td>{list.nick_name}</td>
                                <td>{list.coa}</td>
                                <td>Rp. {currency.format(list.value)}</td>
                                {/* <td style={{ textAlign: "center" }}>
                                  {
                                    (list.is_potong == 0) ? "Tidak Ada Potongan" :
                                      (list.is_potong == 1) ? "Potongan Presentase" :
                                        (list.is_potong == 2) ? (
                                          <div>
                                            Potongan Lainnya
                                            <hr style={{ margin: 10 }}></hr>
                                            <a style={{ color: "#007bff" }} onClick={() =>
                                              this.onShowDetail(list.potong)
                                            }>
                                              Lihat Potongan lainnya
                                            </a>
                                          </div>
                                        ) :
                                          ""
                                  }
                                </td> */}
                                <td>{(list.dp_percent !== 0 && list.dp_percent !== null ? list.dp_percent : 0) + '%'}</td>
                                <td>{(list.potongan_percent !== '' && list.potongan_percent !== null ? list.potongan_percent : 0) + '%'}</td>
                                <td style={{ textAlign: "center" }}>
                                  <MDBBtn
                                    color="primary"
                                    block
                                    onClick={() => this.onClickEdit(list)}
                                  >Edit</MDBBtn>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </MDBTable>
                      <LoadingIndicator />
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Edit_prm_payment;
