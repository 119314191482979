import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
import { trackPromise } from "react-promise-tracker";
import { isEmpty, map } from "lodash";
import remSep from "string-remove-thousand-separators";

import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";
// import remSep from "string-remove-thousand-separators";

class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      isLoading: false
      // registration_type: "",
      // fullname: "",
      // nickname: "",
      // gender_id: "",
      // nik: "",
      // no_kk: "",
      // nis: "",
      // nisn: "",
      // pob: "",
      // dob: "",
      // birth_certificate: "",
    };
  }

  componentDidMount() {
    fetch(global.variable.LINK_API + "auth/parameter", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
        }
      });

    const listField = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_ms_student;
    var set = localStorage.getItem("id");
    set = set.replace("+62", "0");
    {
      map(listField, (item, idx) => {
        if (item.field == "hp") {
          this.setState({
            hp: set,
            fields: { hp: set }
          });
        } else {
          this.setState({
            [item.field]: "",
          });
        }
      });
    }
  }

  createSelectGender() {
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_gender;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  onChange = (e) => {
    let fields = this.state.fields;
    var a = e.target.value;
    if (e.target.name.toLowerCase() == 'nik') {
      a = a.slice(0, 16);
    } else {
      a = a.replace("/", "");
      a = a.replace('"', "");
    }
    fields[e.target.name] = a;
    this.setState({ fields });
  };

  onChangeNom = (e) => {
    let fields = this.state.fields;
    var a = e.target.value;
    fields[e.target.name] = remSep(a);
    this.setState({ fields });
  };

  onDropdownSelected(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  onChangeDate = (jsDate, dateString) => {
    let fields = this.state.fields;
    fields['dob'] = dateString;
    this.setState({ fields });
  };

  createSelectItems(item) {
    const pdf_table = item.pdf_table;

    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER"))[pdf_table];

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  validateForm() {
    const listField = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_ms_student;
    let res = true;
    {
      map(listField, (item, idx) => {
        if (
          parseInt(item.is_required) === 1 &&
          parseInt(item.is_pra_regis) === 1 &&
          this.state.fields[item.field]
        ) {
          // res = false;
          if (this.state.fields[item.field].length > 0) {
            res = true;
          }
        }
      });
    }
    return res;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });

    let valid = this.state.fields.hp.substring(0, 2);

    if (valid !== "08") {
      global.swal("Error", "Tolong masukan nomor Handphone yang valid");
      this.setState({ isLoading: false });
      return false;
    }
    if ('nik' in this.state.fields && this.state.fields.nik.length !== 16) {
      this.setState({ isLoading: false });
      global.swal("Error", "NIK harus 16 digit");
      return false;
    }
    trackPromise(
      fetch(global.variable.LINK_API + "flow/prapendaftaran", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify({ 'data': this.state.fields }),
      })
        .then(this.handleErrors)
        .then((response) => response.json())
        .then((resData) => {
          if (resData.message === "Success") {
            const student_id = resData.data.registration_id + " - " + resData.data.fullname
            localStorage.setItem("DATA_JALUR", student_id);
            localStorage.setItem("STUDENT_DETAIL", JSON.stringify(resData.data));
            global.swal(resData.message, "Berhasil Mendaftar").then((value) => {
              if (resData.data.flow_id === 'VPD') {
                this.props.history.push("/pendaftaran1");
              } else {
                this.props.history.push("/bayarpendaftarandetail");
              }
            });
          } else {
            global.swal(resData.error);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.code !== 403) {
              let message;
              if ('errors' in err) {
                if (Array.isArray(err.errors)) {
                  message = err.errors[0];
                } else {
                  message = err.errors;
                }
              } else {
                message = "Koneksi ke server gagal.";
              }
              global.swal("Error", message, "info");
            }
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
    );
  };

  handleErrors = async (response) => {
    this.setState({ isLoading: false });
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors }
      throw errorMessage;
    }
    return response;
  }

  handleBack = () => {
    window.location.href = "/pendaftaran";
  };

  render() {
    const listField = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_ms_student;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pembelian Formulir
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          {map(listField, (item, idx) => {
                            const field = item.field;
                            if (item.field === 'fullname') {
                              item.name = "Nama Calon Siswa"
                            }
                            return (
                              <React.Fragment key={idx}>
                                {parseInt(item.is_pra_regis) === 1 ? (
                                  <div className="form-group col-md-6">
                                    <label>
                                      {item.name}
                                      {parseInt(item.is_required) === 1 ? (<span className="text-danger"> *</span>) : ""}
                                    </label>
                                    {parseInt(item.type) === 1 ? (
                                      <>
                                        {item.type_text === "nominal" ? (
                                          <CurrencyFormat
                                            className="form-control"
                                            input="number"
                                            name={item.field}
                                            onChange={this.onChangeNom}
                                            value={this.state.fields[item.field] || ''}
                                            maxLength="25"
                                            thousandSeparator={true}
                                            style={{ width: "100%" }}
                                          />
                                        ) : (
                                          <input
                                            className="form-control"
                                            type={item.type_text}
                                            name={item.field}
                                            maxLength="100"
                                            value={this.state.fields[item.field] || ''}
                                            onChange={this.onChange}
                                            disabled={item.field == 'hp' ? true : false}
                                            required={
                                              parseInt(item.is_required) === 1
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          id="exampleForm2"
                                          className="form-control"
                                          name={item.field}
                                          onChange={this.onDropdownSelected.bind(
                                            this
                                          )}
                                        >
                                          <option value="">
                                            - Pilih {item.name} -
                                          </option>
                                          {this.createSelectItems(item)}
                                        </select>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </React.Fragment>
                            );
                          })}
                          <br></br>
                        </div>

                        <MDBBtn color="primary" disabled={this.state.isLoading} block type="submit">
                          {
                            this.state.isLoading ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : 'Bayar Formulir'
                          }
                        </MDBBtn>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
