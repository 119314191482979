export default {
    //LINK_WS : 'http://127.0.0.1/project_lkp/lkp/public/api/',
    //LINK_API : 'https://api.ppdb.kliksekolah.com/public/api/',
    //LINK_API : 'https://api.ppdb.smppl1yogya.kliksekolah.com/public/api/',
    //LINK_API : 'https://api.ppdb.smapldonbosko.kliksekolah.com/public/api/',
    // LINK_API : 'https://api.dev.ppdb.smapldonbosko.kliksekolah.com/public/api/',
    LINK_API : 'https://api.ppdb.general.kliksekolah.com/',
    //LINK_API_AKADEMIS : 'https://dev.api.academics.kliksekolah.com/',
    LINK_API_AKADEMIS : 'https://academics.api.kliksekolah.com/',
    LINK_API_UPLOAD : 'https://api.image.v2.1toko.com/',

    subdomain: window.location.host.split('.')[1] ? window.location.host.split('.')[0] : window.location.hostname,
    //subdomain: 'demoppdb',
};