import React from "react";
import {
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBSideNavLink,
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBSideNavCatV5,
  MDBSideNavNavV5,
  MDBSideNavV5,
  MDBSideNavLinkV5,
} from "mdbreact";
// import { Button, FormGroup, FormControl, FormLabel ,Image } from "react-bootstrap";

import Logo from "../assets/images/logo-spin.png";
import background from "../assets/images/background2.png";

class SideNavigation extends React.Component {
  state = {
    sideNavLeft: false,
    sideNavRight: false,

    sideNavLeftV5: false,
    sideNavRightV5: false,
    sideNavFixedHeader: false,
    sideNavSide: false,
    sideNavPush: false,
    sideNavDark: true,
  };
  // render MDBSideNav Link
  rSNL(to, text) {
    return (
      <MDBSideNavLink to={to} onClick={this.props.onLinkClick}>
        {text}
      </MDBSideNavLink>
    );
  }

  render() {
    const {
      sideNavRight,
      sideNavLeft,
      sideNavLeftV5,
      sideNavRightV5,
      sideNavFixedHeader,
      sideNavSide,
      sideNavPush,
      sideNavDark,
    } = this.state;
    const { onLinkClick } = this.props;
    let menu = JSON.parse(localStorage.getItem("menu"));
    let logo = JSON.parse(localStorage.getItem("logo")).logo;
    
    return (
      <div className="white-skin">
        <MDBSideNav
          // logo={Logo}
          // bg='https://mdbootstrap.com/img/Photos/Others/sidenav5.jpg'
          // bg={background}
          // dark
          // mask='light'
          fixed
          breakWidth={this.props.breakWidth}
          triggerOpening={this.props.triggerOpening}
          // className='deep-purple'
          style={{ transition: "padding-left .3s", color: "white" }}
        >
        
          
          <li>
            <ul className="sidebar-title">
              <div className="row">
                <div className="col-sm-12">
                  <img alt="logo" src={logo} />
                </div>
                {/* <div className="col-sm-8">
                  <h1>YPL</h1>
                </div> */}
              </div>
            </ul>
          </li>
          <li>
            <ul className="sidebar-menu">
              <label>Menu</label>
            </ul>
          </li>
          <MDBSideNavNavV5>
            <div style={{ marginLeft: -12 }}>
              <MDBSideNavLinkV5 href="home" topLevel color="warning" style="">
                <i className="fa fa-home text-center" style={{ minWidth: 40 }}></i>
                <span style={{ fontSize: 14 }}>Home</span>
              </MDBSideNavLinkV5>
            </div>

            {menu.map((item,index) => {
              return (
                <div style={{ marginLeft: -12 }} key={index}>
                  <MDBSideNavLinkV5 href={item.redirect_to} topLevel color="warning">
                    <i className={item.icon + " text-center"} style={{ minWidth: 40 }} ></i>
                    <span style={{ fontSize: 14 }}>{item.name}</span>
                  </MDBSideNavLinkV5>
                </div>
              );
            })}
          </MDBSideNavNavV5>

          {/* <MDBSideNavNav>
              <MDBSideNavCat
                name='Menu'
              // id='dashboard-cat'
              // icon='tachometer-alt'
              >
                <li style={{marginLeft: -20}}>
                  <a href="/home">
                    <i className="fa fa-home"></i>
                    <span style={{color:'white', fontSize:14}}>Home</span>
                  </a>
                </li>
                {menu.map((item) => {
                  return (
                    <li key={item.menu_id}>
                      <a href={item.redirect_to}>
                        <i className={item.icon}></i>
                        &nbsp;
                        <span style={{color:'white', fontSize:14}}>{item.name}</span>
                      </a>
                    </li>
                  )
                })}
              </MDBSideNavCat>


            </MDBSideNavNav> */}
          {/* </div> */}
        </MDBSideNav>
      </div>
    );
  }
}

export default SideNavigation;
