import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";
import Swal from "sweetalert2";
import Select from "react-select";

class Report_wawancara extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CB_REGTYPE: "",
      CB_STUDYYEAR: "",
      CB_JALUR: "",
      CB_FLOW: "",
      CB_STATUS: "",
      val_jalur: "",
      val_studyyear: "",
      valueYear: {},
      valueStatus: {},
      is_show: false,
    };
  }

  componentDidMount() {
    fetch(global.variable.LINK_API + 'auth/parameter', {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
        }
      });
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(<option key={i} value={this.state.CB_JALUR[i].id + '-' + this.state.CB_JALUR[i].fullname}>{this.state.CB_JALUR[i].id + ' - ' + this.state.CB_JALUR[i].fullname}</option>);
    }
    return items;
  }

  createSelectItemsStudyYear() {
    // let items = [];   
    // var data = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_study_year;
    // for (let i = 0; i < data.length; i++) {             
    //   items.push(<option key={i} value={data[i].id}>{data[i].name}</option>);   
    // }
    let items = [];
    var data = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_study_year;
    for (let i = 0; i < data.length; i++) {
      items.push(
        {
          value: data[i].id,
          label: data[i].name
        }
      );
    }
    return <Select options={items} isClearable onChange={e => this.onDropdownSelected2('valueYear', e)} placeholder="- Pilih Tahun -" />;
  }

  onDropdownSelected2(name, e) {
    const value = e ? e.value : {};
    this.setState({ [name]: value });
  }

  createSelectItemsREG() {
    let items = [];
    for (let i = 0; i < this.state.CB_REGTYPE.length; i++) {
      items.push(<option key={i} value={this.state.CB_REGTYPE[i].id}>{this.state.CB_REGTYPE[i].id + ' - ' + this.state.CB_REGTYPE[i].name}</option>);
    }
    return items;
  }

  createSelectItemsFlow() {
    let items = [];
    for (let i = 0; i < this.state.CB_FLOW.length; i++) {
      items.push(<option key={i} value={this.state.CB_FLOW[i].id}>{this.state.CB_FLOW[i].name}</option>);
    }
    return items;
  }

  createSelectItemsstatus() {
    // let items = [];         
    // var data = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_interview_status;
    // for (let i = 0; i < data.length; i++) {             
    //   items.push(<option key={i} value={data[i].id}>{data[i].name}</option>);   
    // }
    // return items;
    let items = [];
    var data = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_interview_status;
    for (let i = 0; i < data.length; i++) {
      items.push(
        {
          value: data[i].id,
          label: data[i].name
        }
      );
    }
    return <Select options={items} isClearable onChange={e => this.onDropdownSelected2('valueStatus', e)} placeholder="- Semua Status Wawancara -" />;
  }

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onDropdownSelectedJalur(e) {
    if (e.target.value === "") {
      this.setState({ is_show: false });
    } else {

      fetch(global.variable.LINK_API + 'student/flow/7?registration_type_id=' + e.target.value,
        {
          method: 'get',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          //body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(resData => {
          if (resData.message === "Success") {
            //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

            this.setState({ CB_JALUR: resData.data, is_show: true });

          }
          console.log(resData);

        })
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    // let jalur = this.state.val_regtype?this.state.val_regtype : '';
    // let flow = this.state.val_flow?this.state.val_flow : '';
    // let status = this.state.val_status?this.state.val_status : '';

    // console.log(jalur)

    /*localStorage.setItem('DATA_JALUR', this.state.val_jalur);*/

    // const data = { registration_type_id :  jalur, flow_id : flow, interview_status_id : status, study_year : this.state.val_studyyear}

    let params = '?type=interview';
    if (typeof this.state.valueYear === 'number') {
      params += '&year=' + this.state.valueYear;
    }
    if (typeof this.state.valueStatus === 'number') {
      params += '&interview_status_id=' + this.state.valueStatus;
    }
    fetch(global.variable.LINK_API + 'report' + params.trim(),
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        // body: JSON.stringify(data), 
      })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          localStorage.setItem('report_wawancara', JSON.stringify(resData.data));
          // window.location.href="/reportwawancaradetail";
          this.props.history.push("/reportwawancaradetail")
        } else {
          global.swal("Error", resData.error, "info")
        }

      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            let message;
            if ('errors' in err) {
              if (Array.isArray(err.errors)) {
                message = err.errors[0];
              } else {
                message = err.errors;
              }
            } else {
              message = "Koneksi ke server gagal.";
            }
            global.swal("Error", message, "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

  }

  handleErrors = async (response) => {
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors }
      throw errorMessage;
    }
    return response;
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Laporan Wawancara
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="exampleForm2">Pilih Tahun</label>
                          {/* <select
                            className="form-control"
                            as="select"
                            name="val_studyyear"
                            onChange={this.onDropdownSelected.bind(this)}
                          >
                            <option value="">- Semua Tahun -</option> */}
                          {this.createSelectItemsStudyYear()}
                          {/* </select> */}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleForm2">Pilih Status Wawancara</label>
                          {/* <select
                            className="form-control"
                            as="select"
                            name="val_status"
                            onChange={this.onDropdownSelected.bind(this)}
                          > */}
                          {/* <option value="">- Semua Status Wawancara -</option> */}
                          {this.createSelectItemsstatus()}
                          {/* </select> */}
                        </div>

                        <MDBBtn color="primary" block type="submit">
                          Submit
                        </MDBBtn>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Report_wawancara;
