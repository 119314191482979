import React, { Component } from "react";
import ReactExport from "react-export-excel";
import { map, isEmpty } from "lodash"
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import CurrencyFormat from "react-currency-format";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Report_wawancara_detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data_student: "",
      is_show: false,
      data: "",
      totalUpp: 0,
      totalSpp: 0,
      rataUpp: 0,
      titlePayment: '',
      reportData: [],
      listMaster: {},
      rataSpp: 0,
    };
  }

  handleClick = (param) => (e) => {
    e.preventDefault();
    // localStorage.setItem("student_id", param);
    const findIdx = this.state.reportData.findIndex((i) => i.registration_id === param.trim());
    var dataStudent = this.state.reportData[findIdx]
    var dataJalur = dataStudent.registration_id + ' - ' + dataStudent.fullname;
    localStorage.setItem("DATA_JALUR", dataJalur);
    localStorage.setItem("STUDENT_DETAIL", JSON.stringify(dataStudent));
    window.open("/detailstudent", "_blank");
  };

  getMaster(id = '', table) {
    var array = this.state.listMaster[table];
    var arrayfilter = array.filter((data) => { if (data.id === parseInt(id)) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getMasterBeforeState(id = 0, table) {
    var array = JSON.parse(localStorage.getItem('DATA_PARAMETER'))[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getTitlePayment() {
    var array = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prm_payment_interview;
    var arrayfilter = array.filter((data) => { if (data.is_routine === 0) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getTotalUpp(array = []) {
    let total = 0;
    if (array.length > 0) {
      map(array, (data) => {
        if (data.subdomain === global.variable.subdomain) {
          console.log(data.is_routine)
          if (data.is_routine === '0' || data.is_routine === 0) {
            data.nominal = data.nominal ? parseFloat(data.nominal) : parseFloat(data.value);
            //hitung jumlah potongan
            if (data.discount > 0 && data.discount !== null) {
              data.potongan = parseFloat(data.discount);
            } else {
              data.potongan = 0;
            }

            //hitung total bayar
            data.ttl = parseFloat(data.nominal) - parseFloat(data.potongan);
            if (data.is_dp == 1) {
              data.dp = data.dp ? data.dp : 0;
              data.ttl_bayar = parseFloat(data.ttl) - parseFloat(data.dp);
            } else {
              data.ttl_bayar = parseFloat(data.ttl)
            }
            total = data.ttl_bayar;
          }
        }
      })
    }
    return total;
  }

  getTotalSpp(array = []) {
    let total = 0;
    if (array.length > 0) {
      map(array, (data) => {
        if (data.subdomain === global.variable.subdomain) {
          if (data.is_routine === 1 || data.is_routine === '1') {
            data.nominal = data.nominal ? parseFloat(data.nominal) : parseFloat(data.value);
            total += data.nominal
          }
        }
      })
    }
    return total;
  }

  componentDidMount() {
    // console.log(localStorage.getItem("report_wawancara"));
    let data_report = JSON.parse(localStorage.getItem("report_wawancara"));

    let data = [];
    let total1 = 0;
    let total2 = 0;
    let rata = 0;
    let rataSpp = 0;
    let Data2 = [];

    data_report.map((datax) => {
      let items3 = Object.create({});
      const total = this.getTotalUpp(datax.interview_result);
      const totalNonRutin = this.getTotalSpp(datax.interview_result);
      // console.log(datax.fullname, datax.interview_result)
      datax.totalPaymentNotRoutine = total;
      datax.totalSPP = totalNonRutin;
      items3[`Nama Lengkap`] = datax.fullname;
      items3[`Nomor Pendaftaran`] = datax.year + "-" + datax.registration_number;
      items3[`Nomor Handphone`] = datax.hp;
      items3[`Status`] = this.getMasterBeforeState(datax.interview_status_id, 'pdf_interview_status');
      items3[`Pewawancara`] = datax.interview_by ? datax.interview_user.name : "";
      items3[this.getTitlePayment()] = total;
      items3['SPP'] = totalNonRutin;
      items3[`Catatan Tentang Pendaftar`] = datax.note_verification ? datax.note_verification : "";
      total1 += parseFloat(total);
      total2 += parseFloat(totalNonRutin);
      Data2.push(items3);
      return { ...datax };
    })

    rata = parseFloat(total1) / parseInt(data_report.length)
    rataSpp = parseFloat(total2) / parseInt(data_report.length)

    let items = Object.create({});
    items.fullname = "TOTAL";
    items.upp =
      "Rp" + total1.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    let items2 = Object.create({});
    items2.fullname = "Rata-Rata";
    items2.upp =
      "Rp" + rata.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    data.push(items2);

    // console.log("check total :" + total2);
    // console.log(Data2);

    this.setState(
      {
        report: Data2,
        totalUpp: total1,
        totalSpp: total2,
        rataUpp: rata,
        rataSpp: rataSpp,
      },
      () => {
        this.setState({
          is_show: true,
          reportData: data_report,
          listMaster: JSON.parse(localStorage.getItem("DATA_PARAMETER")),
          titlePayment: this.getTitlePayment()
        });
      }
    );
  }

  createExcelSheet(data) {
    let final = [];
    for (var key in data[0]) {
      final.push(<ExcelColumn label={key} value={key} key={key} />);
    }
    return final;
  }

  render() {
    let data_report = JSON.parse(localStorage.getItem("data_report"));
    let counter = 0;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Laporan Wawancara
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      {this.state.is_show ? (
                        <ExcelFile
                          element={
                            <MDBBtn
                              color="primary"
                              block
                              style={{ margin: 5, height: 50 }}
                            >
                              Download Data
                            </MDBBtn>
                          }
                        >
                          <ExcelSheet data={this.state.report} name="Report Wawancara">
                            {this.state.is_show ? this.createExcelSheet(this.state.report) : null}
                            {/* <ExcelColumn label="Nama Lengkap" value="fullname"/>
                            <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran"/>
                            <ExcelColumn label="Nomor Handphone" value="hp" />
                            <ExcelColumn label="Status" value="status" />
                            <ExcelColumn label="Pewawancara" value="pewawancara"/>
                            <ExcelColumn label={this.getTitlePayment} value="totalPaymentNotRoutine" />
                            <ExcelColumn label="Catatan Tentang Pendaftar" value="note"/> */}
                          </ExcelSheet>
                        </ExcelFile>
                      ) : (
                        ""
                      )}

                      {/* <div className="box-body">
                        <div className="row"> */}
                      <br></br>
                      <MDBTable>
                        <thead className="blue-grey lighten-4">
                          <tr>
                            <th>#</th>
                            <th>Nama Lengkap (Nama dapat diklik)</th>
                            <th>Nomor Pendaftaran</th>
                            <th>Nomor Handphone</th>
                            <th>Status</th>
                            <th>Pewawancara</th>
                            <th>{this.state.titlePayment}</th>
                            <th>SPP</th>
                            {/* <th>SPP</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.reportData.map((item, idx) => {
                            counter++;
                            return (
                              <tr key={idx}>
                                <td>{counter}</td>
                                <td
                                  style={{ color: "blue" }}
                                  onClick={this.handleClick(item.registration_id
                                  )}
                                >
                                  {item.fullname}
                                </td>
                                <td>
                                  {item.year +
                                    "-" +
                                    item.registration_number}
                                </td>
                                <td>{item.hp}</td>

                                <td>
                                  {this.getMaster(item.interview_status_id, 'pdf_interview_status')}
                                </td>
                                <td>
                                  {item.interview_name
                                    ? item.interview_name
                                    : "Panitia PPDB"}
                                </td>
                                <td>
                                  {item.totalPaymentNotRoutine > 0 ? (
                                    <CurrencyFormat
                                      input="number"
                                      prefix={"Rp. "}
                                      readOnly
                                      value={item.totalPaymentNotRoutine > 0 ? item.totalPaymentNotRoutine : 'Rp. 0'}
                                      maxLength="25"
                                      thousandSeparator={true}
                                      style={{ width: "100%" }}
                                      displayType={"text"}
                                    />
                                  ) : 'Rp. 0'}
                                </td>
                                <td>
                                  {item.totalSPP > 0 ? (
                                    <CurrencyFormat
                                      input="number"
                                      prefix={"Rp. "}
                                      readOnly
                                      value={item.totalSPP > 0 ? item.totalSPP : '0'}
                                      maxLength="25"
                                      thousandSeparator={true}
                                      style={{ width: "100%" }}
                                      displayType={"text"}
                                    />
                                  ) : 0}
                                </td>
                                {/* <td>
                                  {item.interview_by_user ? (
                                    <CurrencyFormat
                                      input="number"
                                      prefix={"Rp. "}
                                      readOnly
                                      value={
                                        item.data_bayar[1].nominal +
                                        item.data_bayar[2].nominal +
                                        item.data_bayar[3].nominal +
                                        item.data_bayar[4].nominal
                                      }
                                      maxLength="25"
                                      thousandSeparator={true}
                                      style={{ width: "100%" }}
                                      displayType={"text"}
                                    />
                                  ) : null}
                                </td> */}
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={6}>TOTAL </td>
                            <td>
                              <CurrencyFormat
                                input="number"
                                prefix={"Rp. "}
                                readOnly
                                value={this.state.totalUpp}
                                maxLength="25"
                                thousandSeparator={true}
                                style={{ width: "100%" }}
                                displayType={"text"}
                              />
                              &nbsp;
                            </td>
                            <td>
                              <CurrencyFormat
                                input="number"
                                prefix={"Rp. "}
                                readOnly
                                value={this.state.totalSpp}
                                maxLength="25"
                                thousandSeparator={true}
                                style={{ width: "100%" }}
                                displayType={"text"}
                              />
                              &nbsp;
                            </td>
                            {/* <td>
                            <CurrencyFormat
                              input="number"
                              prefix={"Rp. "}
                              readOnly
                              value={this.state.totalSpp}
                              maxLength="25"
                              thousandSeparator={true}
                              style={{ width: "100%" }}
                              displayType={"text"}
                            />
                          </td> */}
                          </tr>

                          <tr>
                            <td colSpan={6}>RATA-RATA</td>
                            <td>
                              <CurrencyFormat
                                input="number"
                                prefix={"Rp. "}
                                readOnly
                                value={this.state.rataUpp.toFixed(0)}
                                maxLength="25"
                                thousandSeparator={true}
                                style={{ width: "100%" }}
                                displayType={"text"}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                input="number"
                                prefix={"Rp. "}
                                readOnly
                                value={this.state.rataSpp.toFixed(0)}
                                maxLength="25"
                                thousandSeparator={true}
                                style={{ width: "100%" }}
                                displayType={"text"}
                              />
                            </td>
                            {/* 
                          <td>
                            <CurrencyFormat
                              input="number"
                              prefix={"Rp. "}
                              readOnly
                              value={this.state.rataSpp.toFixed(0)}
                              maxLength="25"
                              thousandSeparator={true}
                              style={{ width: "100%" }}
                              displayType={"text"}
                            />
                          </td> */}
                          </tr>
                        </tbody>
                      </MDBTable>
                      {/* </div>
                      </div> */}
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Report_wawancara_detail;
