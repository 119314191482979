import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter as Router } from 'react-router-dom';

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge
} from 'mdbreact';

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

export const authenticate = async () => {
  if (getRefreshToken()) {
    try {
      //const tokens = await refreshTokens() // call an API, returns tokens

      const tokens = Cookies.get('access_token');

      const expires = (tokens.expires_in || 60 * 60) * 1000
      const inOneHour = new Date(new Date().getTime() + expires)

      // you will have the exact same setters in your Login page/app too
      //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
      //Cookies.set('refresh_token', tokens.refresh_token)

      Cookies.set('access_token', tokens, { expires: inOneHour })
      Cookies.set('refresh_token', 'refresh_token')

      return true
    } catch (error) {
      redirectToLogin()
      return false
    }
  }

  redirectToLogin()
  return false
}

const redirectToLogin = () => {
  //history.push('/')// if your Login page is inside the same app
  window.location.href=localStorage.getItem('redirect');;
}

class TopNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    isAuthenticated: false,

    };
    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleToggleClickA = this.handleToggleClickA.bind(this);
  }

componentDidMount() {
    authenticate().then(isAuthenticated => {
        this.setState({ isAuthenticated })
    })
}

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  logout = () => {
    //unauthenticate();
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    localStorage.removeItem('menu');
    window.location.href=localStorage.getItem('redirect');;
}

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleToggleClickA() {
    this.props.onSideNavToggleClick();
  }

  changepassword = () => {
    window.location.href="/changepassword";
  }

  render() {
    const navStyle = {
      paddingLeft: this.props.toggle ? '16px' : '240px',
      transition: 'padding-left .3s'
    };
    return (
      <Router>
        <MDBNavbar
          className='flexible-MDBNavbar'
          light
          expand='md'
          scrolling
          fixed='top'
          style={{ zIndex: 3, color: '#303f9f' }}
        >
          <div
            onClick={this.handleToggleClickA}
            key='sideNavToggleA'
            style={{
              lineHeight: '32px',
              marginleft: '1em',
              verticalAlign: 'middle',
              cursor: 'pointer'
            }}
          >
            <MDBIcon icon='bars' color='white' size='lg' />
          </div>

          <MDBNavbarBrand href='/' style={navStyle}>
            <strong>{this.props.routeName}</strong>
          </MDBNavbarBrand>
          <MDBNavbarNav expand='sm' right style={{ flexDirection: 'row' }}>
    
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon='user' />{' '}
                <span className='d-none d-md-inline'>{localStorage.getItem('id')}</span>
              </MDBDropdownToggle>
              <MDBDropdownMenu right style={{ minWidth: '200px' }}>
                <MDBDropdownItem href='#!' onClick={this.changepassword}>Change Password</MDBDropdownItem>
                <MDBDropdownItem href='#!' onClick={this.logout}>Log Out</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarNav>
        </MDBNavbar>
      </Router>
    );
  }
}

export default TopNavigation;
