import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";
import Select from "react-select"

class Upload_dokumen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CB_JALUR: "",
      val_jalur: "",
      valueOption: {}
    };
  }

  componentDidMount() {
    fetch(global.variable.LINK_API + "flow/liststudent?flow=SDF", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

          this.setState({ CB_JALUR: resData.data });
        }
      });

    fetch(global.variable.LINK_API + 'auth/parameter', {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
        }
        // console.log(JSON.parse(localStorage.getItem("DATA_PARAMETER")));
      });
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(
        // <option
        //   key={i}
        //   value={
        //     this.state.CB_JALUR[i].registration_id + "-" + this.state.CB_JALUR[i].fullname
        //   }
        // >
        {
          value: this.state.CB_JALUR[i].registration_id + " - " + this.state.CB_JALUR[i].fullname,
          label: this.state.CB_JALUR[i].registration_id + " - " + this.state.CB_JALUR[i].fullname
        }
        // </option> 
      );
    }
    return <Select options={items} onChange={this.onDropdownSelected2.bind(this)} placeholder="- Pilih Siswa -" />;
  }

  onDropdownSelected2(e) {
    this.setState({ valueOption: e.value });
  }

  onDropdownSelected(e) {
    console.log("THE VAL", e.target.value);
    this.setState({ val_jalur: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const student_id = Object.keys(this.state.valueOption).length === 0 ? '' : this.state.valueOption.split("-")[0];
    const findIdx = this.state.CB_JALUR.findIndex((i) => i.registration_id === student_id.trim());
    localStorage.setItem("DATA_JALUR", this.state.valueOption);
    localStorage.setItem("STUDENT_DETAIL", JSON.stringify(this.state.CB_JALUR[findIdx]));

    if (Object.keys(this.state.valueOption).length === 0) {
      global.swal("Info", "Harap pilih siswa", "info");
    } else {
      this.props.history.push("/uploaddokumendetail");
    }
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Upload Dokumen
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <label htmlFor="exampleForm2">Nama Calon Siswa</label>
                        {/* <select
                          id="exampleForm2"
                          className="form-control"
                          onChange={this.onDropdownSelected.bind(this)}
                        >
                          <option value="">- Pilih Jalur -</option> */}
                        {this.createSelectItems()}
                        {/* </select> */}
                        <br></br>

                        <MDBBtn color="primary" block type="submit">
                          Submit
                        </MDBBtn>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Upload_dokumen;
