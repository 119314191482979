import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBSwitch,
  MDBTable,
} from "mdbreact";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
      </div>
    )
  );
};

class Edit_prm_dokumen extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const { state } = location;
    const { data } = state;

    this.state = {
      data: data,
      subdomain: data.subdomain,
      user_level: data.user_level,
      listDocUpload: [],
      prm_document: [],
    }
  }

  componentDidMount() {
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {
    window.scrollTo(0, 0);
    // list param dokumen
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/master/list", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listDocUpload: resData, is_show: true });
          }
        })
    );

    // get prm_document
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/param/getDocs", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify({ subdomain: this.state.subdomain })
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData.prm_document)) {
            // buat set di listDocUpload
            let newList = [];
            newList = this.state.listDocUpload;
            console.log(newList)
            resData.prm_document.map((list, idx) => {
              const findIdx = newList.findIndex((i) => i.id == list.id);
              if (findIdx !== -1) {
                newList[findIdx].checked = "true";
                newList[findIdx].download_document = list.download_document;
                newList[findIdx].is_required = list.is_required;
                newList[findIdx].name_file = list.name_file;
                newList[findIdx].sequence = list.sequence;
              }
            });
            this.setState({
              prm_document: resData.prm_document,
              listDocUpload: newList
            });
          }
        })
    );
  }

  createSelectItems() {
    let items = [];
    for (let i = 1; i <= this.state.prm_document.length; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }

  onChangeHandlerArr = (param) => (e) => {
    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")
    let newList = []
    newList = this.state[fieldname];
    var file = e.target.files[0];
    var newFile = {
      arrayBuffer: file.arrayBuffer,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      slice: file.slice,
      stream: file.stream,
      text: file.text,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath,
    }

    if (file.size > 2000000) {
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info");
      newList[idx].file_upload = "";
      newList[idx].name_file = "";

      if (file.type === "image/png" || file.type === "image/jpeg") {
        newList[idx].file_upload = file;
        newList[idx].name_file = file.name;
      } else {
        global.swal(
          "Error",
          "Harap pilih gambar berupa (*.jpg atau *.png) untuk pas foto",
          "info"
        );
        newList[idx].file_upload = "";
        newList[idx].name_file = "";
      }
    } else if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      newList[idx].file_upload = file;
      newList[idx].name_file = file.name;
    } else {
      global.swal(
        "Error",
        "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf",
        "info"
      );
      newList[idx].file_upload = "";
      newList[idx].name_file = "";
    }

    // set prm_document
    const findIdx = this.state.prm_document.findIndex((i) => i.id == newList[idx].id);
    // this.state.prm_document[findIdx].file = newFile;
    this.state.prm_document[findIdx].file_upload = file;
    this.state.prm_document[findIdx].name_file = file.name;

    // setstate
    this.setState({ [fieldname]: newList })
  };

  handleChangeArr = (e) => {
    const { prm_document } = this.state;

    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")
    let newList = []
    newList = this.state[fieldname];

    console.log("data " + prm_document.length)

    // khusus checkbox
    if (e.target.type === "checkbox") {
      const formattedValue = e.target.checked ? "true" : "";
      newList[idx][e.target.name] = formattedValue;
      if (formattedValue === "true") {

        //ini biar urutannya otomatis ketika checked true
        newList[idx].sequence = prm_document.length + 1;

        // push data ke prm_document jika checked true
        this.state.prm_document.push(newList[idx]);
      } else {
        // hapus data dri prm_document jika checked tidak true
        const findIdx = this.state.prm_document.findIndex((i) => i.id == newList[idx].id);
        this.state.prm_document.splice(findIdx, 1);
      }
    } else {
      // cari urutan yang sudah dipakai
      if (e.target.name === "sequence") {
        const findIdx = this.state.prm_document.findIndex((i) => i.sequence == e.target.value);
        if (findIdx !== -1 && e.target.value !== "") {
          e.target.value = "";
          newList[idx].errseq = "Urutan sudah dipakai"
        } else {
          newList[idx].errseq = ""
        }
      }

      newList[idx][e.target.name] = e.target.value

      // set prm_document
      const findIdx = this.state.prm_document.findIndex((i) => i.id == newList[idx].id);
      this.state.prm_document[findIdx][e.target.name] = e.target.value;
    }

    // setstate
    this.setState({ [fieldname]: newList });
  };

  handleSwitchChangeArr(idx) {
    let newList = []
    newList = this.state.listDocUpload;
    const formattedValue = newList[idx].is_required === 1 ? 0 : 1;
    newList[idx].is_required = formattedValue;

    // set prm_document
    const findIdx = this.state.prm_document.findIndex((i) => i.id == newList[idx].id);
    this.state.prm_document[findIdx].is_required = formattedValue;

    this.setState({ listDocUpload: newList });
  };

  async uploadfile() {
    const { prm_document, subdomain, user_level } = this.state;
    const newList = this.state.prm_document;

    console.log(user_level)
    Swal.fire({
      title: "Mohon Tunggu Sebentar !",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    Promise.all(prm_document.map(async (list, idx) => {
      if (list.file_upload && list.file_upload != "") {
        const data = new FormData();
        data.append("download_document", list.file_upload);
        data.append("name_file", list.name_file);

        await fetch(global.variable.LINK_API + "settings/param/uploadFile", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: data,
        })
          .then((response) => response.json())
          .then((resData) => {
            if (resData.message === "Success") {
              newList[idx].download_document = resData.link;

            } else {
              global.swal("Error", resData.error, "info");
            }
          });
      }
      return list;
    })).then((res) => {
      // panggil save nya
      fetch(global.variable.LINK_API + 'settings/param/createDocs',
        {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          body: JSON.stringify({
            subdomain: subdomain,
            prm_document: res,
          }),
        })
        .then(response => response.json())
        .then(resData => {
          Swal.close();
          if (resData.message === "Success") {
            global.swal("Success", resData.message, "success",)
              .then((value) => {
                if (user_level == 5) {
                  window.location.href = "/pengaturanadmin";
                }
                else {
                  window.location.href = user_level == 7 ? "/pengaturanpusat" : "/pengaturanunit";
                }
              });
          } else {
            global.swal("Error", resData.error, "info")
          }

        }).catch(err => {
          Swal.close();
          if (err) {
            if (err.code !== 403) {
              global.swal("Error", "Koneksi ke server gagal.", "info")
            }
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        });
    })
  }

  handleSubmit = async event => {
    event.preventDefault();
    await this.uploadfile();
  };

  render() {
    const { data, listDocUpload, prm_document } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        {/* <MDBBreadcrumbItem href>
                          Pengaturan Unit
                        </MDBBreadcrumbItem> */}
                        <MDBBreadcrumbItem active>
                          Edit Parameter Dokumen
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="col-md-6">
                            <b>Subdomain</b>
                            <input
                              className="form-control"
                              type="Text"
                              name="jalur"
                              value={data.subdomain || ""}
                              disabled
                            />
                          </div>
                        </div>
                        <br></br>
                        <label htmlFor="exampleForm2">Daftar Parameter Dokumen</label>
                        <MDBTable bordered>
                          <thead className="blue-grey lighten-4">
                            <tr>
                              <th style={{ textAlign: "center" }}>Nama</th>
                              <th style={{ textAlign: "center" }}>Upload Dokumen</th>
                              <th style={{ textAlign: "center" }}>Urutan</th>
                              <th style={{ textAlign: "center" }}>Is Required</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listDocUpload.map((list, idx) => {
                              list.download_document = list.download_document ? list.download_document : ""
                              list.name_file = list.name_file ? list.name_file : ""
                              if (list.checked !== "true") {
                                list.sequence = ""
                                list.is_required = 0
                              }
                              if (list.sequence > prm_document.length) {
                                list.sequence = ""
                                // set prm_document
                                const findIdx = this.state.prm_document.findIndex((i) => i.id == list.id);
                                this.state.prm_document[findIdx].sequence = ""
                              }
                              return (
                                <tr key={idx}>
                                  <td>
                                    <MDBInput
                                      label={list.name}
                                      labelClass='mr-5'
                                      type='checkbox'
                                      value={list.checked}
                                      name='checked'
                                      checked={list.checked === "true" ? true : false}
                                      id={'checkbox' + idx}
                                      onChange={this.handleChangeArr}
                                      containerClass='my-3'
                                      fieldname="listDocUpload"
                                      index={idx}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center", width: "25%" }}>
                                    {/* {(list.id == 4 || list.id == 16 || list.id == 21) && ( */}
                                    {(parseInt(list.is_upload_file) === 1) && (
                                      <>
                                        <input
                                          type="file"
                                          name="file"
                                          className="form-control"
                                          onChange={this.onChangeHandlerArr(list.id)}
                                          fieldname="listDocUpload"
                                          index={idx}
                                          disabled={list.checked === "true" ? false : true}
                                          required={(list.checked === "true" && list.download_document === "") ? true : false}
                                          accept=".jpg, .jpeg, .png, .pdf"
                                        />
                                        {(list.download_document !== "") && (
                                          <div>
                                            <span style={{ color: "red" }}>
                                              <strong>Dokumen sudah diupload</strong>
                                            </span>
                                            <hr style={{ margin: 10 }}></hr>
                                            <a
                                              href={list.download_document}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <strong>Lihat Dokumen</strong>
                                            </a>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center", width: "20%" }}>
                                    <select
                                      className="form-control"
                                      name="sequence"
                                      value={list.sequence}
                                      onChange={this.handleChangeArr}
                                      fieldname="listDocUpload"
                                      index={idx}
                                      disabled={list.checked === "true" ? false : true}
                                      required={list.checked === "true" ? true : false}
                                    >
                                      <option value="">- Pilih Urutan -</option>
                                      {this.createSelectItems()}
                                    </select>
                                    {list.errseq !== "" && (
                                      <small className='form-text text-error'>
                                        {list.errseq}
                                      </small>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center", width: "10%" }}>
                                    <MDBSwitch
                                      getValue={() => this.handleSwitchChangeArr(idx)}
                                      name="is_required"
                                      checked={list.is_required == "1" ? true : false}
                                      labelLeft='' labelRight=''
                                      disabled={list.checked === "true" ? false : true}
                                    />
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </MDBTable>
                        <LoadingIndicator />
                        {!isEmpty(prm_document) && (
                          <MDBBtn color="primary" block type="submit">
                            Submit
                          </MDBBtn>
                        )}
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Edit_prm_dokumen;
