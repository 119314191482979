import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBTable
} from "mdbreact";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height={100} width={100} />
      </div>
    )
  );
};

class Tambah_organisasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      data: "",
      name: "",
      code:"",
      listorganisasi: [],
    }
  }

  componentDidMount() {
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {
    window.scrollTo(0, 0);
    this.setState({ 
      isEdit: false,
      name: "",
      code: "",
      listorganisasi: [],
    });
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/listOrganizations", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listorganisasi: resData, is_show: true });
          }
        })
    );
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onClickEdit(val) {
    window.scrollTo(0, 0);
    if (val) {
      this.setState({
        isEdit: true,
        id: val.id,
        name: val.name,
        code: val.code,
      })
    }
  }

  onClickNonactive(val) {
    fetch(global.variable.LINK_API + 'settings/nonActiveOrg',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(val),
      })
      .then(response => response.json())
      .then(resData => {
        console.log(resData)
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success", );
          this._onSearchContent();
        } else {
          global.swal("Error", resData.error, "info")
        }
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    fetch(global.variable.LINK_API + 'settings/createOrganizations',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(this.state),
      })
      .then(response => response.json())
      .then(resData => {
        console.log(resData)
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success", )
          .then((value) => {
            this._onSearchContent();
          });
        } else {
          global.swal("Error", resData.error, "info")
        }

      }) .catch(err => {
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
 
  };

  handleUpdate = (event) => {
    event.preventDefault();

    fetch(global.variable.LINK_API + 'settings/editOrganizations',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(this.state),
      })
      .then(response => response.json())
      .then(resData => {
        console.log(resData)
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success", )
          .then((value) => {
            this._onSearchContent();
          });
        } else {
          global.swal("Error", resData.error, "info")
        }

      }) .catch(err => {
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
 
  };

  render() {
    const { isEdit, listorganisasi } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Tambah Organisasi
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}>
                       
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Nama Organisasi *</label>
                            <input
                              className="form-control"
                              type="Text"
                              name="name"
                              maxLength="100"
                              value={this.state.name}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Kode Organisasi <span className="text-danger">*</span></label>
                            <input
                              className="form-control"
                              type="Text"
                              name="code"
                              maxLength="100"
                              value={this.state.code}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                        </div>

                        <MDBBtn color="primary" block type="submit">
                          { isEdit ? "Update" : "Submit" }
                        </MDBBtn>
                          <br></br>
                          <label htmlFor="exampleForm2">Daftar Organisasi</label>
                          <MDBTable bordered>
                            <thead className="blue-grey lighten-4">
                              <tr>
                                <th style={{ textAlign: "center" }}>No.</th>
                                <th style={{ textAlign: "center" }}>Nama</th>
                                <th style={{ textAlign: "center" }}>Kode</th>
                                <th style={{ textAlign: "center" }} colSpan={2}>Opsi</th>
                              </tr>
                            </thead>
                            <tbody>
                              {listorganisasi.map((data, idx) => {
                                return (
                                  <tr>
                                    <td style={{ textAlign: "center" }}>{idx + 1}</td>
                                    <td style={{ textAlign: "center" }}>{data.name}</td>
                                    <td style={{ textAlign: "center" }}>{data.code}</td>
                                    <td style={{ textAlign: "center" }}>
                                      <MDBBtn
                                        color="primary"
                                        block
                                        onClick={() => this.onClickEdit(data)}
                                      >Edit</MDBBtn>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <MDBBtn
                                        color="primary"
                                        block
                                        onClick={() => this.onClickNonactive(data)}
                                      >Non Aktif</MDBBtn>
                                    </td>
                                  </tr>
                                )
                              })

                              }
                            </tbody>
                          </MDBTable>
                          <LoadingIndicator />
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Tambah_organisasi;
