import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
// import remSep from "string-remove-thousand-separators";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";
class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      religion_id: "",
      citizen_id: "",
      address: "",
      rt: "",
      rw: "",
      sub_district: "",
      district: "",
      sub_province: "",
      province: "",
      // zipcode : '',
      // longitude : '',
      // latitude : '',
    };
  }

  validateForm() {
    return (
      this.state.religion_id.length > 0 &&
      this.state.citizen_id.length > 0 &&
      this.state.address.length > 0 &&
      this.state.rt.length > 0 &&
      this.state.rw.length > 0 &&
      this.state.sub_district.length > 0 &&
      this.state.district.length > 0 &&
      this.state.sub_province.length > 0 &&
      this.state.province.length > 0
    );
    // && this.state.zipcode.length > 0
    // && this.state.longitude.length > 0
    // && this.state.latitude.length > 0;
  }

  componentDidMount() {
    if (localStorage.getItem("reg2")) {
      this.setState(JSON.parse(localStorage.getItem("reg2")));
    }
  }

  createSelectReligion() {
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prmReligion;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectCitizen() {
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prmCitizen;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.religion_id === "") {
      global.swal("Info", "Harap pilih agama", "info");
    } else if (this.state.citizen_id === "") {
      global.swal("Info", "Harap pilih kewarganegaraan", "info");
    } else {
      console.log("state 2 " + JSON.stringify(this.state));

      localStorage.setItem("reg2", JSON.stringify(this.state));
      window.location.href = "/pendaftaran3";
    }
  };

  handleBack = () => {
    window.location.href = "/pendaftaran1";
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pendaftaran Step 2
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleForm2">Agama *</label>
                            <select
                              type="text"
                              id="exampleForm2"
                              className="form-control"
                              name="religion_id"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={this.state.religion_id}
                              required
                            >
                              <option value="">- Pilih Agama -</option>
                              {this.createSelectReligion()}
                            </select>
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleForm2">
                              Kewarganegaraan *
                            </label>
                            <select
                              type="text"
                              id="exampleForm2"
                              className="form-control"
                              name="citizen_id"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={this.state.citizen_id}
                              required
                            >
                              <option value="">
                                - Pilih Kewarganegaraan -
                              </option>
                              {this.createSelectCitizen()}
                            </select>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Alamat *</label>
                            <input
                              className="form-control"
                              type="Text"
                              name="address"
                              maxLength="200"
                              value={this.state.address}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <MDBRow>
                              <MDBCol>
                                <label>RT *</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="rt"
                                  onChange={this.onChange}
                                  value={this.state.rt}
                                  maxLength="5"
                                  required
                                />
                              </MDBCol>
                              <MDBCol>
                                <label>RW *</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="rw"
                                  onChange={this.onChange}
                                  value={this.state.rw}
                                  maxLength="5"
                                  required
                                />
                              </MDBCol>
                            </MDBRow>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Kelurahan *</label>
                            <input
                              className="form-control"
                              type="Text"
                              maxLength="100"
                              name="sub_district"
                              value={this.state.sub_district}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Kecamatan *</label>
                            <input
                              className="form-control"
                              type="Text"
                              name="district"
                              maxLength="100"
                              value={this.state.district}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Kabupaten/Kota *</label>
                            <input
                              className="form-control"
                              type="Text"
                              name="sub_province"
                              maxLength="100"
                              value={this.state.sub_province}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Provinsi *</label>
                            <input
                              className="form-control"
                              type="Text"
                              name="province"
                              maxLength="100"
                              value={this.state.province}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <MDBBtn
                              block
                              color="primary"
                              onClick={this.handleBack}
                            >
                              Prev
                            </MDBBtn>
                          </div>
                          <div className="col-md-6">
                            <MDBBtn
                              block
                              color="primary"
                              type="submit"
                              disabled={!this.validateForm()}
                            >
                              Next
                            </MDBBtn>
                          </div>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
