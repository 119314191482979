import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
import SelectSearch from "react-select-search";
import Select from "react-select";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
  MDBSelectV5,
} from "mdbreact";
// import remSep from "string-remove-thousand-separators";

class Tambah_user extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations_id: "",
      subdomain: "",
      name: "",
      user_level_id: ""
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { state } = location;

    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_unit;

    this.setState({ subdomain: params.subdomain })
  }


  createSelectUserLevel() {
    let items = [];
    // let params = JSON.parse(
    //   localStorage.getItem("DATA_PARAMETER")
    // ).prm_user_level;
    let params = [
      { id: 3, name: "Pendataan" },
      { id: 4, name: "Pewawancara" },
      { id: 6, name: "Admin PPDB" },
    ];
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectPaymentType() {
    let items = [];
    let params = [
      { id: 1, name: "Transfer" },
      { id: 2, name: "PG (Faspay)" },
    ];
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectOrganizations() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).organizations;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].id + " - " + params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectUnit() {
    let organizations_id = this.state.organizations_id;
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).units;
    let selected = params.filter((e) => e.org_id == organizations_id);

    for (let i = 0; i < selected.length; i++) {
      items.push(
        // <option key={i} value={selected[i].value}>
        { value: selected[i].id, label: selected[i].name }
        // </option>
      );
    }

    return items;
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.name);
  }

  onDropdownSelectedUnits(e) {
    console.log(e);
    this.setState({ units_id: e.value });
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).units;
    let units = params.find((i) => i.id == e.value);
    let units_name = units ? units.name : "";
    let va_code = units ? units.va_code : "";
    console.log(units);
    this.setState({
      units_name: units_name,
      va: va_code,
      units_id: e.value,
    });
  }

  onChangeDate = (jsDate, dateString) => {
    this.setState({ dob: dateString });
  };

  validateForm() {
    return (
      this.state.fullname.length > 0 &&
      this.state.nickname.length > 0 &&
      this.state.nickname.length > 0 &&
      this.state.gender_id.length > 0 &&
      this.state.nik.length > 0 &&
      this.state.no_kk.length > 0 &&
      //&& this.state.nis.length > 0
      this.state.nisn.length > 0 &&
      this.state.pob.length > 0 &&
      this.state.dob.length > 0
    );
    //&& this.state.birth_certificate.length > 0;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    fetch(global.variable.LINK_API + "auth/createuser", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    })
      .then((response) => response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success").then((value) => {
            window.location.href = "/tambahuser";
          });
        } else {
          global.swal("Error", resData.error, "info");
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal(
              "Error",
              "Mohon Dicoba Kembali.",
              "info"
            );
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleErrors = async (response) => {
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors };
      throw errorMessage;
    }
    return response;
  };



  onChangeHandler = (event) => {
    var file = event.target.files[0];

    console.log(file);
    if (file.size > 2000000) {
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info");
      this.setState({ selectedFile: "", image: "" });
    } else if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      this.setState({ selectedFile: file, image: file.name });
    } else {
      global.swal(
        "Error",
        "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf",
        "info"
      );
      this.setState({ selectedFile: "", image: "" });
    }
  };
  onChangeHandler2 = (event) => {
    var file = event.target.files[0];

    console.log(file);
    if (file.size > 2000000) {
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info");
      this.setState({ selectedFile: "", kop: "" });
    } else if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      this.setState({ selectedFile: file, kop: file.name });
    } else {
      global.swal(
        "Error",
        "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf",
        "info"
      );
      this.setState({ selectedFile: "", kop: "" });
    }
  };

  handleBack = () => {
    window.location.href = "/pendaftaran";
  };

  render() {
    const { location } = this.props;
    const { state } = location;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Tambah User
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>
                                Username
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="Text"
                                name="user"
                                maxLength="100"
                                value={this.state.user}
                                onChange={this.onChange}
                                autoComplete="new-password"
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Password <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="pass"
                                maxLength="100"
                                value={this.state.pass}
                                onChange={this.onChange}
                                autoComplete="new-password"
                                required
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>
                                Nama <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="Text"
                                name="name"
                                maxLength="100"
                                value={this.state.name}
                                onChange={this.onChange}
                                required
                              />

                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                User Level{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                name="user_level_id"
                                maxLength="100"
                                value={this.state.user_level_id}
                                onChange={this.onChange}
                                // disabled
                                required
                              >
                                <option>Pilih User Level</option>
                                {this.createSelectUserLevel()}
                              </select>
                            </div>
                          </div>
                        </div>
                        <MDBBtn color="primary" block type="submit">
                          Submit
                        </MDBBtn>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Tambah_user;
