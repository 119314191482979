import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBTable,
} from "mdbreact";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";


const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height={100} width={100} />
      </div>
    )
  );
};

class Pengaturan_admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_show: false,
      listunit: [],
      organizations_id: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.organizations_id,
      subdomain: JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.subdomain,
      user_level: JSON.parse(localStorage.getItem("levels_id")),

    };
  }

  componentDidMount() {

    fetch(global.variable.LINK_API + 'auth/parameter',
        {
            method: 'get',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + global.cookies.get('access_token'),
            },
            //body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(resData => {
            if (resData.message === "Success") {
                localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
            }
            // console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));

        })
    this._onSearchContent();

  }

  async _onSearchContent(params = {}) {
    const { form, subdomain } = this.state;

    await trackPromise(
      fetch(global.variable.LINK_API + "settings/listunitorganizations", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify({subdomain: subdomain})
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listunit: resData, is_show: true });
            // this._onChecked(resData[0]);
          }
        })
    );
  }

  onClickAddOrg() {
    const { history } = this.props;
    history.push('/tambahorganisasi');
  }

  onClickAdd() {
    const { history } = this.props;
    history.push('/tambahunit', { isEdit: false });
  }

  onClickEdit(val) {
    const { history } = this.props;
    history.push("/tambahunit", {
      data: val,
      isEdit: true,
    });
  }

  onClickNonactive(val) {
    fetch(global.variable.LINK_API + 'settings/nonActive',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(val),
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success", );
          this._onSearchContent();
        } else {
          global.swal("Error", resData.error, "info")
        }
      });
  }

  handleClick = val => e => {
    e.preventDefault();
    const { history } = this.props;
    history.push("/editparamunit", {
      data: val,
    });
    // localStorage.setItem('student_id', param)
    // window.open("/detailstudent", '_blank');
  }

  render() {
    const { filters, form, listunit, user_level } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pengaturan Admin
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form>
                        <div className="form-group">
                          <div className="row">
                            {/* <div className="col-md-6">
                              <MDBBtn color="primary"
                                block
                                onClick={() => this.onClickAddOrg()}
                              >Tambah Organisasi
                              </MDBBtn>
                            </div>
                            <div className="col-md-6">
                              <MDBBtn color="primary"
                                block
                                onClick={() => this.onClickAdd()}
                              >Tambah Unit
                              </MDBBtn>
                            </div> */}
                          </div>
                          <br></br>
                          <label htmlFor="exampleForm2">Daftar Unit</label>
                          <MDBTable bordered>
                            <thead className="blue-grey lighten-4">
                              <tr>
                                <th style={{ textAlign: "center" }}>No.</th>
                                <th style={{ textAlign: "center" }}>Subdomain</th>
                                <th style={{ textAlign: "center" }}>Nama</th>
                                {/* <th style={{ textAlign: "center" }}>Id Organisasi</th> */}
                                <th style={{ textAlign: "center" }}>Kota</th>
                                <th style={{ textAlign: "center" }}>email</th>
                                <th style={{ textAlign: "center" }} colSpan={2}>Opsi</th>
                              </tr>
                            </thead>
                            <tbody>
                              {listunit.map((data, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td style={{ textAlign: "center" }}>{idx + 1}</td>
                                    <td style={{ textAlign: "center", color: "blue", cursor: "pointer" }} onClick={this.handleClick({...data, user_level})}>{data.subdomain}</td>
                                    <td style={{ textAlign: "center" }}>{data.name}</td>
                                    {/* <td style={{ textAlign: "center" }}>{data.organizations_id}</td> */}
                                    <td style={{ textAlign: "center" }}>{data.city}</td>
                                    <td style={{ textAlign: "center" }}>{data.email}</td>
                                    <td style={{ textAlign: "center" }}>
                                      <MDBBtn
                                        color="primary"
                                        block
                                        onClick={() => this.onClickEdit(data)}
                                      >Edit</MDBBtn>
                                    </td>
                                  </tr>
                                )
                              })

                              }
                            </tbody>
                          </MDBTable>
                          <LoadingIndicator />
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pengaturan_admin;
