import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBSwitch,
  MDBTable,
} from "mdbreact";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
      </div>
    )
  );
};

class Edit_prm_student extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const { state } = location;
    const { data } = state;

    this.state = {
      data: data,
      subdomain: data.subdomain,
      user_level: data.user_level,
      listprmstudent: [],
      prm_student: [],
    }
  }

  componentDidMount() {
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {
    window.scrollTo(0, 0);

    // list menu
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/param/listPrmStudent", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            let filterList = resData.filter((list) => parseInt(list.is_edit) === 0);
            if (filterList.length > 0) {
              filterList.map((list, idx) => {
                list.checked = "true";
                list.sequence = idx + 1;
                list.is_required = 1;
                list.is_pra_regis = 1;
                this.state.prm_student.push(list);
              })
            }
            this.setState({ listprmstudent: resData, is_show: true });
          }
        })
    );

    // get prm_student
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/param/getPrmStudent", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify({ subdomain: this.state.subdomain })
      }).then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData.prm_student)) {
            // buat set di listprmstudent
            let newList = [];
            newList = this.state.listprmstudent;
            resData.prm_student.map((list, idx) => {
              const findIdx = newList.findIndex((i) => i.id == list.id);
              if (findIdx !== -1) {
                newList[findIdx].checked = "true";
                newList[findIdx].is_required = list.is_required;
                newList[findIdx].is_pra_regis = list.is_pra_regis;
                newList[findIdx].sequence = list.sequence;
              }
            });
            this.setState({
              prm_student: resData.prm_student,
              listprmstudent: newList
            });
          }
        })
    );
  }

  createSelectItems() {
    let items = [];
    for (let i = 1; i <= this.state.prm_student.length; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }

  handleCheckAll = (e) => {
    const { listprmstudent, prm_student } = this.state;
    const formattedValue = e.target.checked ? "true" : "";
    let newList = this.state.listprmstudent;
    if (formattedValue === "true") {
      // kosongkan data dri prm_student
      this.state.prm_student.splice(0, listprmstudent.length);

      listprmstudent.map((list, idx) => {
        if (parseInt(list.is_edit) === 0) {
          list.checked = "true";
        } else {
          list.checked = "true";
        }
        list.sequence = idx + 1;

        // ubah data listprmstudent
        newList[idx] = list;

        // push data ke prm_student jika checked true
        this.state.prm_student.push(list);
      })
    } else {
      // hapus data dri prm_student jika checked tidak true
      this.state.prm_student.splice(0, prm_student.length);
      listprmstudent.map((list, idx) => {
        if (parseInt(list.is_edit) === 0) {
          list.checked = this.state.listprmstudent[idx].checked;
          list.sequence = this.state.prm_student.length + 1;
          list.is_required = this.state.listprmstudent[idx].is_required;
          list.is_pra_regis = this.state.listprmstudent[idx].is_pra_regis;
          // push data ke prm_student jika checked true
          this.state.prm_student.push(list);
        } else {
          list.checked = "";
          list.sequence = "";
        }

      })
    }

    // setstate
    this.setState({
      [e.target.name]: formattedValue,
      listprmstudent: newList,
    });
  };

  handleChangeArr = (e) => {
    const { listprmstudent, prm_student } = this.state;

    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")
    let newList = []
    newList = this.state[fieldname];
    // khusus checkbox
    if (e.target.type === "checkbox") {
      const formattedValue = e.target.checked ? "true" : "";
      newList[idx][e.target.name] = formattedValue;
      if (formattedValue === "true") {

        //ini biar urutannya otomatis ketika checked true
        newList[idx].sequence = prm_student.length + 1;

        // push data ke prm_student jika checked true
        this.state.prm_student.push(newList[idx]);
      } else {
        // hapus data dri prm_student jika checked tidak true
        const findIdx = this.state.prm_student.findIndex((i) => i.id == newList[idx].id);
        this.state.prm_student.splice(findIdx, 1);
      }
    } else {
      // cari urutan yang sudah dipakai
      if (e.target.name === "sequence") {
        const findIdx = this.state.prm_student.findIndex((i) => i.sequence == e.target.value);
        if (findIdx !== -1 && e.target.value !== "") {
          e.target.value = "";
          newList[idx].errseq = "Urutan sudah dipakai"
        } else {
          newList[idx].errseq = ""
        }
      }

      newList[idx][e.target.name] = e.target.value

      // set prm_student
      const findIdx = this.state.prm_student.findIndex((i) => i.id == newList[idx].id);
      this.state.prm_student[findIdx][e.target.name] = e.target.value;
    }

    // setstate
    this.setState({ [fieldname]: newList });
  };

  handleSwitchChangeArr(idx) {
    let newList = []
    newList = this.state.listprmstudent;
    const formattedValue = parseInt(newList[idx].is_required) === 1 ? 0 : 1;
    newList[idx].is_required = formattedValue;

    // set prm_student
    const findIdx = this.state.prm_student.findIndex((i) => i.id == newList[idx].id);
    this.state.prm_student[findIdx].is_required = formattedValue;

    this.setState({ listprmstudent: newList });
  };

  handleSwitchPraRegis(idx) {
    let newList = []
    newList = this.state.listprmstudent;
    const formattedValue = newList[idx].is_pra_regis === 1 ? 0 : 1;
    newList[idx].is_pra_regis = formattedValue;

    // set prm_student
    const findIdx = this.state.prm_student.findIndex((i) => i.id == newList[idx].id);
    this.state.prm_student[findIdx].is_pra_regis = formattedValue;

    this.setState({ listprmstudent: newList });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { prm_document, subdomain, user_level } = this.state;

    fetch(global.variable.LINK_API + 'settings/param/createPrmStudent',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(this.state),
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success",)
            .then((value) => {
              if (user_level == 5) {
                window.location.href = "/pengaturanadmin";
              } else {
                window.location.href = user_level == 7 ? "/pengaturanpusat" : "/pengaturanunit";
              }
            });
        } else {
          global.swal("Error", resData.error, "info")
        }

      }).catch(err => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

  };

  render() {
    const { data, listprmstudent, prm_student } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        {/* <MDBBreadcrumbItem href>
                          <a href="/pengaturanunit">Pengaturan Unit</a>
                        </MDBBreadcrumbItem> */}
                        <MDBBreadcrumbItem active>
                          Edit Pengaturan Data Calon Siswa
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="col-md-6">
                            <b>Subdomain</b>
                            <input
                              className="form-control"
                              type="Text"
                              name="jalur"
                              value={data.subdomain || ""}
                              disabled
                            />
                          </div>
                        </div>
                        <br></br>
                        <label htmlFor="exampleForm2">Daftar Pengaturan Data Calon Siswa</label>
                        <MDBInput
                          label="Pilih Semua"
                          labelClass='mr-5'
                          type='checkbox'
                          value={this.state.check_all}
                          name='check_all'
                          checked={this.state.check_all === "true" ? true : false}
                          id={'check_all'}
                          onChange={this.handleCheckAll}
                          containerClass='my-3'
                        />
                        <MDBTable bordered>
                          <thead className="blue-grey lighten-4">
                            <tr>
                              <th style={{ textAlign: "center" }}>Nama</th>
                              <th style={{ textAlign: "center" }}>Urutan</th>
                              <th style={{ textAlign: "center" }}>Is required</th>
                              <th style={{ textAlign: "center" }}>Tampil di Pembelian Formulir</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listprmstudent.map((list, idx) => {
                              if (list.checked !== "true") {
                                list.is_required = 0
                                list.is_pra_regis = 0
                                list.sequence = ""
                              }
                              if (list.sequence > prm_student.length) {
                                list.sequence = ""
                                // set prm_student
                                const findIdx = this.state.prm_student.findIndex((i) => i.id == list.id);
                                this.state.prm_student[findIdx].sequence = ""
                              }
                              return (
                                <tr key={idx}>
                                  <td>
                                    <MDBInput
                                      label={list.name}
                                      labelClass='mr-5'
                                      type='checkbox'
                                      value={list.checked}
                                      name='checked'
                                      checked={list.checked === "true" ? true : false}
                                      id={'checkbox' + idx}
                                      onChange={this.handleChangeArr}
                                      containerClass='my-3'
                                      fieldname="listprmstudent"
                                      index={idx}
                                      disabled={parseInt(list.is_edit) === 1 ? false : true}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center", width: "20%" }}>
                                    <select
                                      className="form-control"
                                      name="sequence"
                                      value={list.sequence}
                                      onChange={this.handleChangeArr}
                                      fieldname="listprmstudent"
                                      index={idx}
                                      disabled={parseInt(list.is_edit) === 1 && list.checked === "true" ? false : true}
                                      required={list.checked === "true" ? true : false}
                                    >
                                      <option value="">- Pilih Urutan -</option>
                                      {this.createSelectItems()}
                                    </select>
                                    {list.errseq !== "" && (
                                      <small className='form-text text-error'>
                                        {list.errseq}
                                      </small>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center", width: "10%" }}>
                                    <MDBSwitch
                                      getValue={() => this.handleSwitchChangeArr(idx)}
                                      name="is_required"
                                      checked={list.is_required.toString() === "1" ? true : false}
                                      labelLeft='' labelRight=''
                                      disabled={parseInt(list.is_edit) === 1 && list.checked === "true" ? false : true}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center", width: "10%" }}>
                                    <MDBSwitch
                                      getValue={() => this.handleSwitchPraRegis(idx)}
                                      name="is_pra_regis"
                                      checked={parseInt(list.is_pra_regis) === 1 ? true : false}
                                      labelLeft='' labelRight=''
                                      disabled={parseInt(list.is_edit) === 1 && list.checked === "true" ? false : true}
                                    />
                                  </td>
                                </tr>
                              )
                            })

                            }
                          </tbody>
                        </MDBTable>
                        <LoadingIndicator />
                        {!isEmpty(prm_student) && (
                          <MDBBtn color="primary" block type="submit">
                            Submit
                          </MDBBtn>
                        )}
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Edit_prm_student;
