import React, { Component } from "react";
// import { MDBBtn, FormGroup, FormControl, label, Breadcrumb } from "react-bootstrap";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";

class Changepassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      new_password: "",
      new_password_confirmation: "",
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.new_password !== this.state.new_password_confirmation) {
      global.swal(
        "Error",
        "Ketik ulang password baru tidak sesuai dengan password baru.",
        "info"
      );
    } else if (this.state.password === this.state.new_password) {
      global.swal(
        "Error",
        "Password baru tidak boleh sama dengan password lama.",
        "info"
      );
    } else {
      const data = {
        user_id: localStorage.getItem("userid"),
        password: this.state.password,
        new_password: this.state.new_password,
      };
      console.log(data);

      fetch(global.variable.LINK_API + "password/change", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log(resData);
          if (resData.message === "Success") {
            global
              .swal("Success", "Berhasil Ubah Password", "success")
              .then((value) => {
                window.location.href = "/";
              });
          } else {
            global.swal("Error", resData.error, "info");
          }
        });
    }
  };

  back() {
    window.location.href = "/home";
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBBreadcrumb color="cyan lighten-4">
                    <MDBBreadcrumbItem href>
                      <a href="/home">Home</a>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>
                      Change Password
                    </MDBBreadcrumbItem>
                  </MDBBreadcrumb>

                  <div className="box-body">
                    <form onSubmit={this.handleSubmit}>
                      <MDBCard className="mb-5">
                        <MDBCardBody>
                          <label style={{ fontSize: 18, fontWeight: "bold" }}>
                            Silakan ubah password anda
                          </label>
                          <br></br>
                          <label>Password lama</label>
                          <input
                            className="form-control"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                            required
                          />
                          <label>Password baru</label>
                          <input
                            className="form-control"
                            name="new_password"
                            value={this.state.new_password}
                            onChange={this.handleChange}
                            type="password"
                            required
                          />
                          <label>Ketik ulang password baru</label>
                          <input
                            className="form-control"
                            name="new_password_confirmation"
                            value={this.state.new_password_confirmation}
                            onChange={this.handleChange}
                            required
                            type="password"
                          />
                        </MDBCardBody>
                      </MDBCard>
                      <div className="row">
                        <div className="col-md-6">
                          <MDBBtn color="primary" block type="submit" >
                            Submit
                          </MDBBtn>
                        </div>
                        <div className="col-md-6">
                          <MDBBtn color="primary" block onClick={this.back} type="MDBBtn">
                            Back
                          </MDBBtn>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Changepassword;
