import React, { Component } from "react";
import "moment/locale/id.js";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";
import Select from "react-select";

class Verifikasi_bukti_bayar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CB_JALUR: "",
      val_jalur: "",
      valueOption: {}
    };
  }

  componentDidMount() {

    fetch(global.variable.LINK_API + "flow/liststudent?flow=SBP", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

          this.setState({ CB_JALUR: resData.data });
        }
      });

    fetch(global.variable.LINK_API + 'auth/parameter', {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
        }
      });
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(
        // <option
        //   key={i}
        //   value={
        //     this.state.CB_JALUR[i].registration_id + "-" + this.state.CB_JALUR[i].fullname
        //   }
        // >
          {
            value: this.state.CB_JALUR[i].registration_id + " - " + this.state.CB_JALUR[i].fullname,
            label: this.state.CB_JALUR[i].registration_id + " - " + this.state.CB_JALUR[i].fullname
          }
        // </option> 
      );
    }
    return <Select options={items} onChange={this.onDropdownSelected2.bind(this)} placeholder="- Pilih Siswa -" />;
  }

  onDropdownSelected2(e) {
    this.setState({ valueOption: e.value });
  }

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    // cari index untuk di simpan di student_detail berdasarkan registration_id
    const student_id = Object.keys(this.state.valueOption).length === 0 ? '' : this.state.valueOption.split("-")[0];
    const findIdx = this.state.CB_JALUR.findIndex((i) => i.registration_id === student_id.trim());
    localStorage.setItem("DATA_JALUR", this.state.valueOption);
    localStorage.setItem("STUDENT_DETAIL", JSON.stringify(this.state.CB_JALUR[findIdx]));

    if (Object.keys(this.state.valueOption).length === 0) {
      global.swal("Info", "Harap pilih siswa", "info");
    } else {
      this.props.history.push("/verifikasibuktipendaftarandetail");
    }
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">

                  <form onSubmit={this.handleSubmit}>
                    <MDBCard className="mb-5">
                      <MDBCardBody>
                        <MDBBreadcrumb color="cyan lighten-4">
                          <MDBBreadcrumbItem active>
                            <a href="/home">Home</a>
                          </MDBBreadcrumbItem>
                          <MDBBreadcrumbItem active>Pendaftaran</MDBBreadcrumbItem>
                        </MDBBreadcrumb>
                        <label htmlFor="exampleForm2">Pilih Siswa</label>
                        {/* <select
                          id="exampleForm2"
                          className="form-control"
                          name="val_jalur"
                          onChange={this.onDropdownSelected.bind(this)}
                        >
                          <option value="">- Pilih Siswa -</option> */}
                          {this.createSelectItems()}
                        {/* </select> */}
                      </MDBCardBody>
                    </MDBCard>

                    <MDBBtn color="primary" block type="submit">
                      Submit
                    </MDBBtn>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Verifikasi_bukti_bayar;
