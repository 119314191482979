import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
import { trackPromise } from "react-promise-tracker";
import { isEmpty, map } from "lodash";
import remSep from "string-remove-thousand-separators";

import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";
// import remSep from "string-remove-thousand-separators";

class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      student_id: "",
      registration_type: "",
      fullname: "",
      nickname: "",
      gender_id: "",
      nik: "",
      no_kk: "",
      nis: "",
      nisn: "",
      pob: "",
      dob: "",
      birth_certificate: "",
      class: "",
      // sibling: [{ name: '', class: '0'}],
      // achieve: [{ achieve_type_id: "0", achieve_rank_id: "0", name: "", promoter: "", rank: "" }],
      sibling: [],
      achieve: [],
      data: [],
      data_list: {},
      fields: {},
      students: {}
    };
  }

  componentDidMount() {
    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
    });

    fetch(global.variable.LINK_API + "auth/parameter", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
        }
      });

    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_guardian;
    var set = JSON.parse(localStorage.getItem("STUDENT_DETAIL")).hp;
    set = set.replace("+62", "0");
    this.setState(
      {
        guardian_id1: params[0].id,
        guardian_id2: params[1].id,
        guardian_id3: params[2].id,
        guardian_id_name1: params[0].name,
        guardian_id_name2: params[1].name,
        guardian_id_name3: params[2].name,

        hp: set,
        student_detail: JSON.parse(localStorage.getItem("STUDENT_DETAIL")),
        data_list: JSON.parse(localStorage.getItem("DATA_PARAMETER"))
          .prm_ms_student,
      },
      () => {
        map(this.state.data_list, (item, idx) => {
          if (item.is_pra_regis) {
            this.state.data_list[idx].value = JSON.parse(
              localStorage.getItem("STUDENT_DETAIL")
            )[item.field];
            this.forceUpdate();
            if (this.state.data_list[idx].field == "hp") {
              this.state.data_list[idx].value = set;
            }
          }
        });
      }
    );
  }

  createSelectGender() {
    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).pdf_gender;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectGuardian() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_guardian;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectStatus() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_parent_status;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectParentStatus() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_parent_status;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectStatusGuardian() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_status_guardian;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectWorkingParents() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_working_parents;

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  onChangeData2 = (e, index) => {
    let data = this.state.sibling;
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    data[index][e.target.name] = a;
    this.setState({
      data
    });
  };

  onChangeDataAchieve = (e, index) => {
    let data = this.state.achieve;
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    data[index][e.target.name] = a;
    this.setState({
      data
    });
  };

  onDropdownSelectedSibling(e, index) {
    let data = this.state.sibling;
    data[index][e.target.name] = e.target.value;
    this.setState({
      data
    });
  }

  onDropdownSelectedAchieve(e, index) {
    let data = this.state.achieve;
    data[index][e.target.name] = e.target.value;
    this.setState({
      data
    });
  }

  createSelectAchieveType() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_achieve_type;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectAchieveRank() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_achieve_rank;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  showRemove() {
    if (this.state.sibling != "") {
      return (
        <MDBBtn
          onClick={this.handleClear}
          block
          style={{ height: 50 }}
        >
          Clear Data
        </MDBBtn>
      );
    }
  }

  showPrestasi() {
    // console.log(this.state.sibling);
    if (this.state.sibling) {
      let guardian = this.state.sibling;
      // console.log(localStorage.getItem("reg5"));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) {
        showitem.push(
          <div key={guardian[i].name}>
            <hr />
            <div className="rowHorizontal">
              <label
                className="col-md-3"
                style={{ width: 200, margin: 5, fontWeight: "bold" }}
              >
                Nama Siswa{" "}
              </label>
              <label
                className="col-md-6"
                style={{ margin: 5, fontWeight: "bold" }}
              >
                {guardian[i].name}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label
                className="col-md-3"
                style={{ width: 200, margin: 5, fontWeight: "bold" }}
              >
                Kelas{" "}
              </label>
              <label
                className="col-md-6"
                style={{ margin: 5, fontWeight: "bold" }}
              >
                {guardian[i].class !== "0" ? guardian[i].class : ""}{" "}
              </label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  getMaster(id = 0, table) {
    var array = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    )[table];
    let name;
    if (id != '') {
      var arrayfilter = array.filter((data) => { if (data.id == id) return data });
      name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    } else {
      name = '';
    }
    return name;
  }

  showRemove2() {
    if (this.state.achieve != "") {
      return (
        <MDBBtn
          onClick={this.handleClearAchieve}
          block
          style={{ height: 50 }}
        >
          Clear Data
        </MDBBtn>
      );
    }
  }

  showPrestasi2() {
    if (this.state.achieve) {
      let guardian = this.state.achieve;
      // console.log(localStorage.getItem("reg6"));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) {
        showitem.push(
          <div key={i}>
            <hr />
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Jenis{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {this.getMaster(guardian[i].achieve_type_id, 'pdf_achieve_type')}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Nama Prestasi{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {guardian[i].name}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Penyelenggara{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {guardian[i].promoter}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Tingkat{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {this.getMaster(guardian[i].achieve_rank_id, 'pdf_achieve_rank')}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3 font-weight-bold" style={{ width: 200, margin: 5 }}>
                Peringkat{" "}
              </label>
              <label className="col-md-6 font-weight-bold" style={{ margin: 5 }}>
                {guardian[i].rank}{" "}
              </label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  formGuardian() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="exampleForm2">Keadaan Orang Tua <span className="text-danger">*</span></label>
            <select
              className="form-control"
              name="parent_status_id"
              onChange={this.onDropdownSelected3.bind(this)}
              value={this.state.parent_status_id}
              required
            >
              <option value="">-Pilih Status-</option>
              {this.createSelectParentStatus()}
            </select>
          </div>
          {this.state.parent_status_id !== "4" ? (
            <>
              <div className="form-group col-md-6">
                <label htmlFor="exampleForm2">
                  Keadaan Pekerjaan Orang Tua <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="both_parent_work"
                  onChange={this.onDropdownSelected2.bind(this)}
                  value={this.state.both_parent_work}
                  required
                >
                  <option value="">-Pilih-</option>
                  {/* {console.log(this.state.both_parent_work)} */}
                  {this.createSelectWorkingParents()}
                </select>
              </div>
            </>
          ) : null}
        </div>
        {this.state.parent_status_id === "1" ? (
          <div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {this.state.guardian_id_name1}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname1"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.fullname1 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik1"
                  onChange={this.onChange}
                  value={this.state.fields.nik1 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob1"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.dob1 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id1"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.religion_guardian_id1 +
                    "_" +
                    this.state.religion_guardian_id1_name
                  }
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {/* {console.log(
                                    this.state.religion_guardian_id1
                                  )} */}
                  {this.createSelectReligion()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan<span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="status_guardian_id1"
                  onChange={this.onDropdownSelected2.bind(this)}
                  value={this.state.status_guardian_id1}
                  required
                >
                  <option value="">-Pilih Status-</option>
                  {/* {console.log(this.state.status_guardian_id1)} */}
                  {this.createSelectStatusGuardian()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id1"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.education_id1 +
                    "_" +
                    this.state.education_id1_name
                  }
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {this.createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id1"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.occupation_id1 +
                    "_" +
                    this.state.occupation_id1_name
                  }
                  style={{ marginTop: "4%" }}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {this.createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary1"
                  className="form-control"
                  onChange={this.onChangeNomSalary}
                  value={this.state.fields.salary1 || ''}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp1"
                  format="+62############"
                  onChange={this.onChange}
                  value={this.state.fields.hp1}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address1"
                  maxLength="200"
                  value={this.state.fields.address1 || ''}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {this.state.guardian_id_name2}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname2"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.fullname2 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik2"
                  onChange={this.onChange}
                  value={this.state.fields.nik2 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob2"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.dob2 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id2"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.religion_guardian_id2 +
                    "_" +
                    this.state.religion_guardian_id2_name
                  }
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {this.createSelectReligion()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="status_guardian_id2"
                  onChange={this.onDropdownSelected2.bind(this)}
                  value={
                    this.state.status_guardian_id2
                  }
                  required
                >
                  <option value="">-Pilih Status-</option>
                  {/* {console.log(this.state.status_guardian_id2)} */}
                  {this.createSelectStatusGuardian()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id2"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.education_id2 +
                    "_" +
                    this.state.education_id2_name
                  }
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {this.createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id2"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.occupation_id2 +
                    "_" +
                    this.state.occupation_id2_name
                  }
                  style={{ marginTop: "4%" }}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {this.createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary2"
                  className="form-control"
                  onChange={this.onChangeNomSalary}
                  value={this.state.fields.salary2 || ''}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp2"
                  format="+62############"
                  onChange={this.onChange}
                  value={this.state.fields.hp2 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address2"
                  maxLength="200"
                  value={this.state.fields.address2 || ''}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.parent_status_id === "2" ? (
          <div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {this.state.guardian_id_name1}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname1"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.fullname1 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik1"
                  onChange={this.onChange}
                  value={this.state.fields.nik1 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob1"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.dob1 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id1"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.religion_guardian_id1 +
                    "_" +
                    this.state.religion_guardian_id1_name
                  }
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {/* {console.log(
                                    this.state.religion_guardian_id1
                                  )} */}
                  {this.createSelectReligion()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="status_guardian_id1"
                  onChange={this.onDropdownSelected2.bind(this)}
                  value={
                    this.state.status_guardian_id1
                  }
                  required
                >
                  <option value="">-Pilih Status-</option>
                  {/* {console.log(this.state.status_guardian_id1)} */}
                  {this.createSelectStatusGuardian()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id1"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.education_id1 +
                    "_" +
                    this.state.education_id1_name
                  }
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {this.createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id1"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.occupation_id1 +
                    "_" +
                    this.state.occupation_id1_name
                  }
                  style={{ marginTop: "4%" }}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {this.createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary1"
                  className="form-control"
                  onChange={this.onChangeNomSalary}
                  value={this.state.fields.salary1}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp1"
                  format="+62############"
                  onChange={this.onChange}
                  value={this.state.fields.hp1 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address1"
                  maxLength="200"
                  value={this.state.fields.address1 || ''}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.parent_status_id === "3" ? (
          <div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {this.state.guardian_id_name2}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname2"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.fullname2 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik2"
                  onChange={this.onChange}
                  value={this.state.fields.nik2 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob2"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.dob2 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id2"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.religion_guardian_id2 +
                    "_" +
                    this.state.religion_guardian_id2_name
                  }
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {this.createSelectReligion()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="status_guardian_id2"
                  onChange={this.onDropdownSelected2.bind(this)}
                  value={this.state.status_guardian_id2}
                  required
                >
                  <option value="">-Pilih Status-</option>
                  {/* {console.log(this.state.status_guardian_id2)} */}
                  {this.createSelectStatusGuardian()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id2"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.education_id2 +
                    "_" +
                    this.state.education_id2_name
                  }
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {this.createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id2"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.occupation_id2 +
                    "_" +
                    this.state.occupation_id2_name
                  }
                  style={{ marginTop: "4%" }}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {this.createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary2"
                  className="form-control"
                  onChange={this.onChangeNomSalary}
                  value={this.state.fields.salary2 || ''}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp2"
                  format="+62############"
                  onChange={this.onChange}
                  value={this.state.fields.hp2 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address2"
                  maxLength="200"
                  value={this.state.fields.address2 || ''}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.parent_status_id === "4" ? (
          <div>
            <label
              style={{
                fontSize: 20,
                textDecorationLine: "underline",
              }}
            >
              Data {this.state.guardian_id_name3}
            </label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nama Lengkap <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  name="fullname3"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.fullname3 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Jenis Kelamin <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="gender_id3"
                  onChange={this.onChange}
                  value={this.state.fields.gender_id3 || ''}
                  required
                >
                  <option value="">-Pilih Jenis Kelamin-</option>
                  {this.createSelectGender()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>
                  NIK (Nomor Induk Kependudukan) ={">"} cek di Kartu Keluarga <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="nik3"
                  onChange={this.onChange}
                  value={this.state.fields.nik3 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Tanggal Lahir <span className="text-danger">*</span></label>
                <input
                  type="date"
                  className="form-control"
                  name="dob3"
                  onChange={this.onChange}
                  maxLength="200"
                  value={this.state.fields.dob3 || ''}
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Agama <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="religion_guardian_id3"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.religion_guardian_id3 +
                    "_" +
                    this.state.religion_guardian_id3_name
                  }
                  required
                >
                  <option value="">-Pilih Agama-</option>
                  {this.createSelectReligion()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Pendidikan Terakhir <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="education_id3"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.education_id3 +
                    "_" +
                    this.state.education_id3_name
                  }
                  required
                >
                  <option value="">-Pilih Pendidikan-</option>
                  {this.createSelectPendidikan()}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Pekerjaan <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  name="occupation_id3"
                  onChange={this.onDropdownSelected.bind(this)}
                  value={
                    this.state.occupation_id3 +
                    "_" +
                    this.state.occupation_id3_name
                  }
                  //   style={{marginTop:'4%'}}
                  required
                >
                  <option value="">-Pilih Pekerjaan-</option>
                  {this.createSelectPekerjaan()}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>
                  Penghasilan (isi hanya angka jangan diberi titik dan koma,
                  jika tidak ada diisi dengan angka 0)
                </label>
                <CurrencyFormat
                  name="salary3"
                  className="form-control"
                  onChange={this.onChangeNomSalary}
                  value={this.state.fields.salary3 || ''}
                  maxLength="50"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>No HP <span className="text-danger">*</span></label>
                <CurrencyFormat
                  className="form-control"
                  input="number"
                  name="hp3"
                  format="+62############"
                  onChange={this.onChange}
                  value={this.state.fields.hp3 || ''}
                  maxLength="25"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label>Alamat Tempat Tinggal <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="address3"
                  maxLength="200"
                  value={this.state.fields.address3 || ''}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Status Hubungan <span className="text-danger">*</span></label>
                <input
                  className="form-control"
                  type="text"
                  name="status_guardian"
                  maxLength="200"
                  value={this.state.fields.status_guardian || ''}
                  onChange={this.onChange}
                  required
                />
              </div>
              <div className="form-group col-md-6">
              </div>
            </div>

          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  createSelectPendidikan() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_education_guardian;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectPekerjaan() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_occupation;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectReligion() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_religion;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectClass() {
    // ini valuenya
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).classrooms;

    // console.log(params)

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].name}>
          {params[i].name}
        </option>
      );
    }

    return items;
  }

  onChangeData = (e) => {
    var a = e.target.value;
    if (e.target.name.toLowerCase() == 'nik' || e.target.name.toLowerCase() == 'no_kk') {
      a = a.slice(0, 16);
    } else if (e.target.name.toLowerCase() == 'nisn') {
      a = a.slice(0, 10);
    }
    else {
      a = a.replace("/", "");
      a = a.replace('"', "");
    }
    // this.setState({ [e.target.name]: a });
    // console.log(e.target.id)
    // console.log(e.target.id)
    this.state.data_list[e.target.id].value = a;
    this.forceUpdate();
  };

  onChangeNomData = (e) => {
    // this.setState({ [e.target.name]: remSep(e.target.value) });
    this.state.data_list[e.target.id].value = remSep(e.target.value);
    this.forceUpdate();
  };

  onChange = (e) => {
    let fields = this.state.fields;
    var a = e.target.value;
    if (e.target.name.toLowerCase() == 'nik1' || e.target.name.toLowerCase() == 'nik2' || e.target.name.toLowerCase() == 'nik3') {
      a = a.slice(0, 16);
    } else {
      a = a.replace("/", "");
      a = a.replace('"', "");
    }
    fields[e.target.name] = a;
    this.setState({ fields });
  };
  onChangeNomSalary = (e) => {
    let fields = this.state.fields;
    var a = e.target.value;
    fields[e.target.name] = remSep(a);
    this.setState({ fields });
  }

  onChangeNom = (e) =>
    this.setState({ [e.target.name]: remSep(e.target.value) });
  // this.state.guardian[e.target.id].value = remSep(e.target.value);
  // this.forceUpdate();}

  onChangeDate = (jsDate, dateString) => {
    let fields = this.state.fields;
    fields['dob'] = dateString;
    this.setState({ fields });
  };

  createSelectItems(item) {
    const pdf_table = item.pdf_table;
    const test = "." + pdf_table;

    let items = [];
    let params = JSON.parse(localStorage.getItem("DATA_PARAMETER"))[pdf_table];

    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  onDropdownSelected4 = (e) => {
    // this.setState({ [e.target.name]: remSep(e.target.value) });
    this.state.data_list[e.target.id].value = remSep(e.target.value);
    this.forceUpdate();
  };

  onDropdownSelected2(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onDropdownSelected(e) {
    this.setState({
      [e.target.name]: e.target.value.split("_")[0],
      [e.target.name + "_name"]: e.target.value.split("_")[1],
    });
  }

  onDropdownSelected3(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateForm() {
    return (
      this.state.fullname.length > 0 &&
      this.state.nickname.length > 0 &&
      this.state.nickname.length > 0 &&
      this.state.gender_id.length > 0 &&
      this.state.nik.length > 0 &&
      this.state.no_kk.length > 0 &&
      //&& this.state.nis.length > 0
      this.state.nisn.length > 0 &&
      this.state.pob.length > 0 &&
      this.state.dob.length > 0
    );
    //&& this.state.birth_certificate.length > 0;
  }

  handleSubmit = (event) => {

    this.setState(
      { isLoading: true, data: this.state.data_list.filter((item) => item.value != null) }
      // ,() => console.log(this.state.data)
    );
    event.preventDefault();
    // console.log(this.state.data_list.filter((item) => item.value != null));

    const listField = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_ms_student;

    let valid = this.state.hp.substring(0, 2);

    if (valid != "08") {
      global.swal("Error", "Tolong masukan nomor Handphone yang valid");
      return;
    }
    let items = {};
    let item = [];

    // return;

    let items1 = Object.create({});
    items1.gender_id = 1;
    items1.guardian_id = this.state.guardian_id1;
    items1.fullname = this.state.fields.fullname1;
    items1.nik = this.state.fields.nik1;
    if (this.state.parent_status_id == "1" || this.state.parent_status_id == "2") {
      if ('nik1' in this.state.fields && this.state.fields.nik1.length != 16) {
        this.setState({ isLoading: false });
        global.swal("Error", "NIK Ayah harus 16 digit");
        return false;
      }
    }
    items1.dob = this.state.fields.dob1;
    items1.status_guardian_id = this.state.status_guardian_id1;
    items1.status_guardian = this.state.fields.status_guardian ?? null;
    items1.education_id = this.state.education_id1;
    items1.occupation_id = this.state.occupation_id1;
    items1.salary = this.state.fields.salary1 ? this.state.fields.salary1 : 0;
    items1.hp = this.state.fields.hp1;
    items1.address = this.state.fields.address1;
    items1.religion_guardian_id = this.state.religion_guardian_id1;
    if (this.state.fields.fullname1) {
      item.push(items1);
    }

    let items2 = Object.create({});
    items2.gender_id = 2;
    items2.guardian_id = this.state.guardian_id2;
    items2.fullname = this.state.fields.fullname2;
    items2.nik = this.state.fields.nik2;
    if (this.state.parent_status_id == "1" || this.state.parent_status_id == "3") {
      if ('nik2' in this.state.fields && this.state.fields.nik2.length != 16) {
        this.setState({ isLoading: false });
        global.swal("Error", "NIK Ibu harus 16 digit");
        return false;
      }
    }
    items2.dob = this.state.fields.dob2;
    items2.status_guardian_id = this.state.status_guardian_id2;
    items2.status_guardian = this.state.fields.status_guardian ?? null;
    items2.education_id = this.state.education_id2;
    items2.occupation_id = this.state.occupation_id2;
    items2.salary = this.state.fields.salary2 ? this.state.fields.salary2 : 0;
    items2.hp = this.state.fields.hp2;
    items2.address = this.state.fields.address2;
    items2.religion_guardian_id = this.state.religion_guardian_id2;
    if (this.state.fields.fullname2) {
      item.push(items2);
    }

    let items3 = Object.create({});
    items3.gender_id = this.state.fields.gender_id3;
    items3.guardian_id = this.state.guardian_id3;
    items3.fullname = this.state.fields.fullname3;
    items3.nik = this.state.fields.nik3;
    if (this.state.parent_status_id == "4") {
      if ('nik3' in this.state.fields && this.state.fields.nik3.length != 16) {
        this.setState({ isLoading: false });
        global.swal("Error", "NIK Wali harus 16 digit");
        return false;
      }
    }
    items3.dob = this.state.fields.dob3;
    items3.status_guardian_id = this.state.status_guardian_id3 ?? null;
    items3.education_id = this.state.education_id3;
    items3.occupation_id = this.state.occupation_id3;
    items3.salary = this.state.fields.salary3 ? this.state.fields.salary3 : 0;
    items3.hp = this.state.fields.hp3;
    items3.address = this.state.fields.address3;
    items3.religion_guardian_id = this.state.religion_guardian_id3;
    items3.status_guardian = this.state.fields.status_guardian;
    if (this.state.fields.fullname3) {
      item.push(items3);
    }
    localStorage.setItem("reg4", JSON.stringify(item));
    localStorage.setItem("statereg4", JSON.stringify(this.state));

    // map(listField, (item, idx) => {
    //   items = Object.create({});
    //   items.field = item.field;
    //   items.value = this.state[item.field]?this.state[item.field]:'NULL';
    //   data.push(items);
    // });
    let data = (this.state.data_list.filter((item) => item.value != null));

    let data1 = [];
    items = Object.create({});
    items.field = "parent_status_id"
    items.value = this.state.parent_status_id;
    data1.push((items))
    if (this.state.both_parent_work) {
      items = Object.create({});
      items.field = "working_parent_id"
      items.value = this.state.both_parent_work;
      data1.push((items))
    }

    let dataConcat = data.concat(data1)

    // console.log(dataConcat)
    // return false;
    let statereg4 = JSON.parse(localStorage.getItem("statereg4"));
    let reg4 = JSON.parse(localStorage.getItem("reg4"));

    // console.log(this.state.data);
    // return;
    const form_data = new FormData();
    form_data.append("registration_id", this.state.student_id);
    form_data.append("action", "next");
    form_data.append(
      "data",
      JSON.stringify(dataConcat)
      // JSON.stringify(this.state.data_list.filter((item) => item.value != null))
    );
    form_data.append("guardian", JSON.stringify(reg4));
    form_data.append("sibling", JSON.stringify(this.state.sibling));
    form_data.append("achieve", JSON.stringify(this.state.achieve));
    // form_data.append("parent_status_id", statereg4.parent_status_id);
    // form_data.append("both_parent_work", statereg4.both_parent_work);
    // console.log(JSON.stringify(dataConcat));
    // console.log(this.state.sibling)
    // console.log(this.state.achieve)
    // return false;
    trackPromise(
      fetch(global.variable.LINK_API + "flow/update", {
        method: "post",
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: form_data,
      })
        .then(this.handleErrors)
        .then((response) => response.json())
        .then((resData) => {
          if (resData.message === "Success") {
            global.swal(resData.message, "Berhasil Mendaftar", "success").then((value) => {
              this.props.history.push("/uploaddokumendetail");
            });
          } else {
            global.swal(resData.error);
          }
        })
        .catch((err) => {
          console.log('e', err)
          if (err) {
            if (err.code !== 403) {
              let message;
              if ('errors' in err) {
                if (Array.isArray(err.errors)) {
                  message = err.errors[0];
                } else {
                  message = err.errors;
                }
              } else {
                message = "Koneksi ke server gagal.";
              }
              global.swal("Error", message, "info");
            }
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })

    );
  };

  handleErrors = async (response) => {
    this.setState({ isLoading: false })
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors }
      throw errorMessage;
    }
    return response;
  }

  handleBack = () => {
    window.location.href = "/pendaftaran";
  };

  handleAdd = () => {
    // console.log("state 4 " + JSON.stringify(this.state));
    // let item = [];
    // if (localStorage.getItem("reg5")) {
    //   item = JSON.parse(localStorage.getItem("reg5"));
    // }
    this.setState(({
      sibling: [...this.state.sibling, { name: "", class: "0" }]
    }))
    // window.location.href = "/pendaftaran1";
  };

  handleClear = () => {
    // localStorage.removeItem("reg5");
    this.setState({
      name: "",
      class: "",
      sibling: [],
    });
    // window.location.href = "/pendaftaran1";
  };

  handleAddAchieve = () => {
    let item = [];
    // if (localStorage.getItem("reg6")) {
    //   item = JSON.parse(localStorage.getItem("reg6"));
    // }
    this.setState(({
      achieve: [...this.state.achieve, { achieve_type_id: "0", name: "", achieve_rank_id: "0", promoter: "", rank: "" }]
    }))

    // localStorage.setItem("reg6", JSON.stringify(item));

    // window.location.href = "/pendaftaran1";
  };

  handleClearAchieve = () => {
    this.setState({
      achieve_type_id: "",
      achieve_rank_id: "",
      name_achieve: "",
      promoter: "",
      rank: "",
      achieve: [],
    });
  };

  render() {
    const listField = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prm_ms_student;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pengisian Data Calon Siswa
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          {map(this.state.data_list, (item, idx) => {
                            const field = item.field;
                            return (
                              <div className="form-group col-md-6" key={idx}>
                                <label>
                                  {item.name}
                                  {item.is_required == 1 ? (<span className="text-danger"> *</span>) : ""}
                                </label>
                                {item.type == 1 ? (
                                  <>
                                    {item.type_text == "nominal" ? (
                                      <CurrencyFormat
                                        required={
                                          item.is_required == 1 ? true : false
                                        }
                                        className="form-control"
                                        input="number"
                                        id={idx}
                                        name={item.field}
                                        onChange={this.onChangeNomData}
                                        value={this.state.data_list[idx].value || ''}
                                        maxLength="25"
                                        thousandSeparator={true}
                                        style={{ width: "100%" }}
                                      />
                                    ) : (
                                      <>
                                        {item.field == 'nik' || item.field === 'hp' ? (
                                          <input
                                            className="form-control"
                                            type={item.type_text}
                                            name={item.field}
                                            id={idx}
                                            maxLength="100"
                                            disabled
                                            defaultValue={this.state.data_list[idx].value || ''}
                                            onChange={this.onChangeData}
                                            required={
                                              item.is_required == 1 ? true : false
                                            }
                                          />
                                        ) : (
                                          <input
                                            className="form-control"
                                            type={item.type_text}
                                            name={item.field}
                                            id={idx}
                                            maxLength="100"
                                            value={this.state.data_list[idx].value || ''}
                                            onChange={this.onChangeData}
                                            required={
                                              item.is_required == 1 ? true : false
                                            }
                                          />
                                        )}
                                        {/* {(() => {
                                      if (item.field == 'no_kk') {
                                        return (
                                          <input
                                          className="form-control"
                                          type={item.type_text}
                                          name={item.field}
                                          id={idx}
                                          maxLength="16"
                                          value={this.state.data_list[idx].value || ''}
                                          onChange={this.onChangeData}
                                          required={
                                            item.is_required == 1 ? true : false
                                          }
                                        />
                                        )
                                      } else if (item.field == 'nisn') {
                                        return (
                                          <input
                                          className="form-control"
                                          type={item.type_text}
                                          name={item.field}
                                          id={idx}
                                          maxLength="10"
                                          value={this.state.data_list[idx].value || ''}
                                          onChange={this.onChangeData}
                                          required={
                                            item.is_required == 1 ? true : false
                                          }
                                        />
                                        )
                                      }
                                    })()} */}
                                      </>

                                    )}
                                  </>
                                ) : (
                                  <>
                                    <select
                                      required={
                                        item.is_required == 1 ? true : false
                                      }
                                      id={idx}
                                      className="form-control"
                                      name={item.field}
                                      onChange={this.onDropdownSelected4.bind(
                                        this
                                      )}
                                    >
                                      <option value="">
                                        - Pilih {item.name} -
                                      </option>
                                      {this.createSelectItems(item)}
                                    </select>
                                  </>
                                )}
                              </div>
                            );
                          })}
                          <br></br>
                        </div>
                        <hr></hr>
                        <h5 style={{ textDecorationLine: "underline" }}>
                          Data Orangtua
                        </h5>
                        {this.formGuardian()}
                        <div>
                          <label
                            style={{
                              fontSize: 20,
                              textDecorationLine: "underline",
                            }}
                          >
                            Saudara yang masih bersekolah di{" "}
                            {
                              JSON.parse(localStorage.getItem("DATA_PARAMETER"))
                                .prm_unit.name
                            }
                          </label>
                          {map(this.state.sibling, (datax, idx) => (
                            <React.Fragment key={idx}>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label>Nama Siswa</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    onChange={e => this.onChangeData2(e, idx)}
                                    maxLength="200"
                                    value={datax.name}
                                    style={{ width: "100%" }}
                                  // required
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Kelas</label>
                                  <select
                                    className="form-control"
                                    name="class"
                                    onChange={e => this.onDropdownSelectedSibling(e, idx)}
                                    value={datax.class}
                                  >
                                    <option value="0">-Pilih Kelas-</option>
                                    {this.createSelectClass()}
                                  </select>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                          <div className="row">
                            <div className="col-12 mb-2">
                              <MDBBtn
                                onClick={this.handleAdd}
                                color="primary"
                                block
                                style={{ height: 50 }}
                              >
                                Tambah Saudara
                              </MDBBtn>
                            </div>
                            <div className="col-12">
                              {this.showRemove()}
                            </div>
                          </div>
                          {this.showPrestasi()}
                        </div>

                        <div>
                          <br></br>
                          <label
                            style={{
                              fontSize: 20,
                              textDecorationLine: "underline",
                            }}
                          >
                            Prestasi
                          </label>
                          {map(this.state.achieve, (datax, idx) => (
                            <React.Fragment key={idx}>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label>Jenis</label>
                                  <select
                                    className="form-control"
                                    name="achieve_type_id"
                                    onChange={e => this.onDropdownSelectedAchieve(e, idx)}
                                    value={datax.achieve_type_id}
                                  // required
                                  >
                                    <option value="0">-Pilih Jenis-</option>
                                    {this.createSelectAchieveType()}
                                  </select>
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Nama Prestasi</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    onChange={e => this.onChangeDataAchieve(e, idx)}
                                    maxLength="200"
                                    value={datax.name}
                                    style={{ width: "100%" }}
                                  // required
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label>Penyelenggara</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="promoter"
                                    onChange={e => this.onChangeDataAchieve(e, idx)}
                                    maxLength="200"
                                    value={datax.promoter}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label>Tingkat</label>
                                  <select
                                    className="form-control"
                                    name="achieve_rank_id"
                                    onChange={e => this.onDropdownSelectedAchieve(e, idx)}
                                    value={datax.achieve_rank_id}
                                  // required
                                  >
                                    <option value="0">-Pilih Tingkat-</option>
                                    {this.createSelectAchieveRank()}
                                  </select>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label>Peringkat</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="rank"
                                    maxLength="5"
                                    value={datax.rank}
                                    onChange={e => this.onChangeDataAchieve(e, idx)}
                                    style={{ width: "100%" }}
                                  // required
                                  />
                                </div>
                                <div className="form-group col-md-6"></div>
                              </div>
                            </React.Fragment>
                          ))

                          }

                          <div className="row">
                            <div className="col-12 mb-2">
                              <MDBBtn
                                color="primary"
                                onClick={this.handleAddAchieve}
                                block
                                style={{ height: 50 }}
                              >
                                Tambah Prestasi
                              </MDBBtn>
                            </div>
                            <div className="col-12">
                              {this.showRemove2()}
                            </div>
                          </div>
                          <br></br>

                          {this.showPrestasi2()}
                        </div>
                        <MDBBtn color="primary" disabled={this.state.isLoading} block type="submit">
                          {
                            this.state.isLoading ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : 'Submit'
                          }
                        </MDBBtn>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
