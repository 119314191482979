import React from 'react';
import './App.css';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
// import Home from './pendaftaran/Home';
import RoutesWithNavigation from './components/RoutesWithNavigation';

import Login from './Login';
import LoginAdmin from './LoginAdmin';
import Register from './Register';
import Pricing from './components/pages/Pricing';
import Lock from './components/pages/Lock';
import About from './components/pages/About';
import SinglePost from './components/pages/SinglePost';
import PostListing from './components/pages/PostListing';
import Landing from './components/pages/Landing';
import Forgetpassword from './Forgetpassword';
import Confirmation from './Confirmation';

const App = () => {
  return (
    <Switch>
      <Route exact path="/" component={LoginContainer}/>
      <Route exact path="/admin" component={LoginAdmin}/>
      <Route exact path="/register" component={RegisterContainer}/>
      <Route exact path="/forgetpassword" component={Forgetpassword}/>
      <Route exact path="/confirmation/:id" component={Confirmation}/>

      {/* <Route path='/pages/pricing' exact component={Pricing} />
      <Route path='/pages/lock' exact component={Lock} />
      <Route path='/pages/about' exact component={About} />
      <Route path='/pages/post' exact component={SinglePost} />
      <Route path='/pages/posts' exact component={PostListing} />
      <Route path='/pages/landing' exact component={Landing} /> */}
      <RoutesWithNavigation />
    </Switch>
  );
};

const LoginContainer = () => (
  <div>
    <Route exact path="/" render={() => <Redirect to="/" />} />
    <Route path="/" component={Login} />
  </div>
)

const RegisterContainer = () => (
  <div>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/" />} />
          <Route exact path="/register" component={Register} />
        </Switch>
    
    
  </div>
)


export default App;
