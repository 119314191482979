import React, { Component } from 'react';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";
import { map, isEmpty } from "lodash";
import 'moment/locale/id.js';
import ReactToPrint from "react-to-print";
const moment = require('moment');

class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      student_id: '',
      student_detail: {},
      fields: {},
      is_show: false,
      image_64: '',

    }
  }



  componentDidMount() {
    const student = JSON.parse(localStorage.getItem("STUDENT_DETAIL"));
    const filterInterview = student.interview_result.filter((data) => { if (data.subdomain === global.variable.subdomain) return data });
    student.interview_result = filterInterview
    let paymentData;
    let kop = JSON.parse(localStorage.getItem("kop")).header;
    const datax = student.interview_result;
    paymentData = datax.map((data, index) => {
      if (data.is_routine === 0) {
        data.errtermin = '';
        data.errcicil = '';
        if ('prm_potong' in data) {
          data.prm_potong.map((data2) => {
            data2.id = data2.potong_id;
            return data2;
          })
        } else {
          data.prm_potong = []
        }
        if ('discount' in data) {
          data.potong_persen = data.discount
        }
        if (data.is_dp > 0) {
          var valueTermin = data.dtcicilan.length - 1;
          data.termin = valueTermin;
          const dataCicilanNew = [];
          data.dtcicilan.filter((data2) => {
            const curDate = new Date(data2.payments_due_date);
            const date = curDate.getDate();
            const month = curDate.getMonth() + 1;
            const year = curDate.getFullYear();
            const newDate = year + '-' + month + '-' + date
            data2.tgl_angsuran = newDate
            dataCicilanNew.push(data2);
            return data2
          })
          data.dtcicilan = dataCicilanNew;
        } else {
          var valueTermin1 = data.dtcicilan.length;
          data.termin = valueTermin1;
          data.dtcicilan.filter((data2) => {
            const curDate = new Date(data2.payments_due_date);
            const date = curDate.getDate();
            const month = curDate.getMonth() + 1;
            const year = curDate.getFullYear();
            const newDate = year + '-' + month + '-' + date
            data2.tgl_angsuran = newDate
            return data2;
          })
        }
      } else {
        data.prm_potong = [];
      }
      return data;
    })
    this.setState({
      student_id: localStorage.getItem('DATA_JALUR').split('-')[0].trim(),
      student_detail: student,
      payment: paymentData,
      kop: kop,
      fields: JSON.parse(localStorage.getItem("DATA_PARAMETER")),
      is_show: true,
      flowId: student.flow_id,
    });
  }

  renderTanggal() {
    if (this.state.registration_type_id === 'JT' && this.state.is_reserve === '1') {
      return (<label>Sabtu, 7 Desember 2019 Pukul 12.00.</label>)
    } else {
      if (this.state.registration_type_id === 'JT') {
        return (<label>Sabtu, 7 Desember 2019 Pukul 12.00.</label>)
      } else {
        return (moment().add(5, 'days').format('DD-MM-YYYY'))
      }
    }
  }
  getMaster(id = "", table) {
    var array = JSON.parse(localStorage.getItem('DATA_PARAMETER'))[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getTitlePayment() {
    var array = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prm_payment_interview;
    var arrayfilter = array.filter((data) => { if (data.is_routine === 0) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }
  getDP() {
    let currency = Intl.NumberFormat('en-ID');
    var array = this.state.payment;
    var arrayfilter = array.filter((data) => { if (parseInt(data.is_routine) === 0) return data });
    var dp = arrayfilter.length > 0 ? currency.format(arrayfilter[0].dp) : 0;
    return dp;
  }

  getAngsuran() {
    var angsur = 0;
    var array = this.state.payment;

    var arrayfilter = array.filter((data) => { if (parseInt(data.is_routine) === 0) return data });
    if (arrayfilter.length > 0) {
      var cicilan = arrayfilter[0].dtcicilan.filter((data) => { if (data.periode !== "000") return data })
      angsur = cicilan.length > 0 ? (cicilan.length) : 0;
    }
    return angsur;
  }

  getDPPersen() {
    var array = this.state.payment;
    var arrayfilter = array.filter((data) => { if (parseInt(data.is_routine) === 0) return data });
    var persen = arrayfilter.length > 0 ? arrayfilter[0].dp_percent : 0;
    return persen;
  }

  render() {
    let currency = Intl.NumberFormat('en-ID');
    return (
      this.state.is_show ?
        <div id="divPrint" style={{ width: '90%', border: '0px solid black' }}>
          <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', textAlign: 'center', }}>
            {/* <img src={this.state.is_show ? this.state.fields.prm_unit.logo : ''} alt="kop" style={{width:700}}/> */}
            <img src={this.state.kop} alt="kop" style={{ width: 700 }} />
          </div>
          {/* <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:5, textAlign: 'center', }}>
              <label style={{textDecorationLine: 'underline'}}>PERNYATAAN KESANGGUPAN KEUANGAN SEKOLAH</label>
            </div> */}

          <div className="row mt-3" style={{ marginLeft: 50 }}>
            <div style={{ fontSize: 16, marginTop: 5 }}>
              <label style={{ fontWeight: 'bold' }}>PENERIMAAN PESERTA DIDIK BARU {this.getMaster(this.state.student_detail.year, 'prm_study_year')}</label>
              <br></br>
              <label style={{ fontWeight: 'bold' }}>FORMULIR PENENTUAN PEMBAYARAN LUNAS/ANGSURAN</label>
            </div>
            <div style={{ marginLeft: 40, width: 200, height: 40, border: '1px solid black' }}>
              <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize: 16, marginTop: 10, textAlign: 'center', textDecorationLine: 'underline' }}>
                <label style={{ fontWeight: 'bold' }}>NO DAFT : {this.state.student_detail.year + '-' + this.state.student_detail.registration_number}</label>
              </div>
              <div style={{ marginTop: 10, width: 200, height: 40, border: '1px solid black' }}>
                <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize: 16, marginTop: 10, textAlign: 'center', textDecorationLine: 'underline' }}>
                  <label style={{ fontWeight: 'bold' }}>NO VA : {this.state.student_detail.registration_id}</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <label></label><br></br>

            <table style={{ width: '95%', marginLeft: 50, padding: 5, fontSize: 14, border: 'none', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th></th>
                  <th style={{ width: '1%' }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '20%', border: 'none' }}>UNIT</td>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>:</td>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>{this.state.fields.prm_unit.name}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>NAMA</td>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>:</td>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>{this.state.student_detail.fullname}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', border: 'none', verticalAlign: 'top' }}> ALAMAT</td>
                  <td style={{ fontWeight: 'bold', border: 'none', verticalAlign: 'top' }}>:</td>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>{this.state.student_detail.address}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>NO. TELEPON</td>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>:</td>
                  <td style={{ fontWeight: 'bold', border: 'none' }}>{this.state.student_detail.hp}</td>
                </tr>
              </tbody>
            </table>

            {/* </div>
              <div style={{ margin:5, marginLeft:50, marginTop:5, fontSize:14}}>
                <label style={{width:175}}>UNIT</label>
                <label>: {this.state.nama_sekolah}</label>
                <br></br>
                <label style={{width:175}}>NAMA</label>
                <label>: {this.state.fullname}</label>
                <br></br>
                <label style={{width:175}}>ALAMAT</label>
                <label>: {this.state.address}</label>
                <br></br>
                <label style={{width:175}}>NO. TELEPON </label>
                <label>: {this.state.hp}</label>
                <br></br>
              </div>
            <div> */}
            <div style={{ margin: 5, marginLeft: 50, marginTop: 10, fontSize: 14 }}>
              <label style={{ width: '95%', fontWeight: 'bold' }}>Kami orang tua calon siswa {this.state.fields.prm_unit.name} menyatakan kesanggupan dan bersedia memenuhi kewajiban keuangan yang harus dibayarkan selama anak kami bersekolah, diantaranya sebagai berikut: </label>
            </div>
          </div>
          <div>

            <table style={{ width: '95%', marginLeft: 75, padding: 5, fontSize: 14 }}>
              <thead>
                {/* <tr>
                    <th style={{fontWeight:'bold',paddingLeft:10, width:'5%'}}>No</th>
                    <th style={{fontWeight:'bold'}}>Jenis Pembayaran</th>
                    <th style={{fontWeight:'bold',paddingLeft:10}}>Jumlah Pembayaran (Rp)</th>
                  </tr> */}
                <tr>
                  <th style={{ fontWeight: 'bold', paddingLeft: 10, width: '4%' }}></th>
                  <th style={{ fontWeight: 'bold' }}></th>
                  <th style={{ fontWeight: 'bold', paddingLeft: 10 }}></th>
                </tr>
              </thead>
              <tbody>
                {this.state.is_show ? (this.state.payment.map((data, index) => {
                  const number = ++index;
                  data.nominal = data.nominal ? data.nominal : data.value;
                  //hitung jumlah potongan
                  if (data.discount > 0 && data.discount !== null) {
                    data.potongan = parseFloat(data.discount);
                  } else {
                    data.potongan = 0;
                  }

                  //hitung total bayar
                  data.ttl = parseFloat(data.nominal) - parseFloat(data.potongan);
                  if (data.is_dp == 1) {
                    data.dp = data.dp ? data.dp : 0;
                    // data.ttl_bayar = parseFloat(data.ttl)-parseFloat(data.dp);
                    data.ttl_bayar = parseFloat(data.ttl)
                  } else {
                    data.ttl_bayar = parseFloat(data.ttl)
                  }
                  return (
                    <React.Fragment key={index}>
                      {data.is_routine === 0 ? (
                        <tr>
                          <td style={{ fontWeight: 'bold', paddingLeft: 10 }}>{number}.</td>
                          <td style={{ fontWeight: 'bold' }}>{data.name}</td>
                          <td style={{ fontWeight: 'bold', paddingLeft: 10 }}>Rp. {currency.format(data.ttl_bayar)}</td>
                        </tr>
                      ) : (

                        <tr>
                          <td style={{ fontWeight: 'bold', paddingLeft: 10 }}>{number}.</td>
                          <td style={{ fontWeight: 'bold' }}>{data.name}</td>
                          <td style={{ fontWeight: 'bold', paddingLeft: 10 }}>Rp. {currency.format(data.nominal)}</td>
                        </tr>
                      )}
                      {/* {data.is_routine === 0 ? (
                          <React.Fragment>
                            {data.prm_potong.map((data2,idx) => {
                              var char = 'a';
                              const alpha = String.fromCharCode(char.charCodeAt(0) + idx);
                              return (
                                <tr key={idx}>
                                <td style={{fontWeight:'bold',paddingLeft:10}}></td>
                                <td style={{fontWeight:'bold',paddingLeft:10}}>{alpha}. {data2.name}</td>
                                <td style={{fontWeight:'bold',paddingLeft:10}}>Rp. {currency.format(data2.nominal)}</td>
                              </tr>
                              )
                            })} */}
                      {/* {data.is_potong === 1 ? (
                              <tr>
                                <td style={{fontWeight:'bold',paddingLeft:10}}></td>
                                <td style={{fontWeight:'bold',paddingLeft:10}}>Potongan (%)</td>
                                <td style={{fontWeight:'bold',paddingLeft:10}}>Rp. {currency.format(data.potongan)}</td>
                              </tr>
                            ) : null} */}
                      {/* {data.is_dp > 0  ? (
                              <tr>
                                <td style={{border:'1px solid',paddingLeft:10}}></td>
                                <td style={{border:'1px solid',paddingLeft:10}}>DP</td>
                                <td style={{border:'1px solid',paddingLeft:10}}>Rp. {currency.format(data.dp)}</td>
                              </tr>
                            ) : null} */}
                      {/* {(data.is_potong === 1 || data.is_potong === 2) ? (
                              <tr>
                                <td style={{fontWeight:'bold',paddingLeft:10}}></td>
                                <td style={{fontWeight:'bold',paddingLeft:10}}>Jumlah {data.name}</td>
                                <td style={{fontWeight:'bold',paddingLeft:10}}>Rp. {currency.format(data.ttl_bayar)}</td>
                              </tr>
                            ) : null} */}
                      {/* </React.Fragment>
                        ) :null} */}
                    </React.Fragment>
                  )
                })) : null}
              </tbody>
            </table>
            <div>
              <div style={{ margin: 5, marginLeft: 50, marginTop: 10, fontSize: 14 }}>
                <label style={{ width: '95%', fontWeight: 'bold' }}>Pembayaran {this.getTitlePayment()} Tahap Awal setelah Wawancara Kesepakatan sebesar Rp. {this.getDP()} .
                </label>
              </div>
              <div style={{ margin: 5, marginLeft: 50, marginTop: 10, fontSize: 14 }}>
                <label style={{ width: '95%', fontWeight: 'bold' }}>
                  Untuk kekurangan {this.getTitlePayment()} dapat dibayarkan dengan cara diangsur maksimal {this.getAngsuran()} kali.
                </label>
              </div>
            </div>
            <table style={{ width: '93%', marginLeft: 50, padding: 5, fontSize: 14, marginRight: 15 }}>
              <thead>
                <tr>
                  {/* <th style={{width:'1%'}}></th>
                  <th></th>
                  <th></th>
                  <th></th> */}
                  <th style={{ fontWeight: 'bold', paddingLeft: 10, border: '1px solid' }}>SISTEM PEMBAYARAN</th>
                  <th style={{ fontWeight: 'bold', paddingLeft: 10, border: '1px solid' }}>TANGGAL TAGIHAN</th>
                  <th style={{ fontWeight: 'bold', paddingLeft: 10, border: '1px solid' }}>JUMLAH TAGIHAN</th>
                  {/* <th style={{fontWeight:'bold',border:'1px solid',paddingLeft:10}}>JUMLAH UANG YANG DIBAYARKAN</th>
                  <th style={{fontWeight:'bold',border:'1px solid',paddingLeft:10}}>PARAF PETUGAS</th> */}
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td style={{fontWeight:'bold',border:'1px solid',paddingLeft:10}}>LUNAS</td>
                  <td style={{border:'1px solid',paddingLeft:10}}></td>
                  <td style={{border:'1px solid',paddingLeft:10}}></td>
                  <td style={{border:'1px solid',paddingLeft:10}}></td>
                  <td style={{border:'1px solid',paddingLeft:10}}></td>
                </tr> */}
                {this.state.is_show ? (
                  <>
                    {map(this.state.student_detail.interview_result, (data, idx) => {
                      let items = []
                      if ("dtcicilan" in data) {
                        var filteredCicilan = data.dtcicilan.filter((data) => { return data.periode !== "000" });
                        {
                          map(filteredCicilan, (data2, index) => {
                            let newDate;
                            newDate = ''
                            if (data2.payments_due_date) {
                              const curDate = new Date(data2.payments_due_date);
                              const date = curDate.getDate();
                              const month = curDate.getMonth() + 1;
                              const year = curDate.getFullYear();
                              newDate = date + '/' + month + '/' + year
                              if (newDate == '1/1/1970') {
                                newDate = ''
                              }
                            }
                            if (data2.periode != '000') {
                              items.push(
                                <tr key={index}>
                                  {/* <td style={{fontWeight:'bold',paddingLeft:10}}>-</td> */}
                                  <td style={{ fontWeight: 'bold', paddingLeft: 10, border: '1px solid' }}>Angsuran {index + 1}</td>
                                  <td style={{ fontWeight: 'bold', paddingLeft: 10, border: '1px solid' }}>{data2.payments_due_date ? newDate : ''}</td>
                                  <td style={{ fontWeight: 'bold', paddingLeft: 10, border: '1px solid' }}>Rp. {currency.format(data2.nominal)}</td>
                                  {/* <td style={{paddingLeft:10}}></td>
                                <td style={{paddingLeft:10}}></td> */}
                                </tr>
                              )
                            }
                          })
                        }
                      }
                      return items;
                    }
                    )}
                  </>
                ) : null}
              </tbody>
            </table>
            <div style={{ margin: 5, marginLeft: 50, marginTop: 10, fontSize: 14 }}>
              <label style={{ width: '95%', fontWeight: 'bold' }}>Proses pembayaran keuangan apapun, dilakukan di Bendahara Sekolah (TU Keuangan) atau melalui Virtual Account dan bukan kepada tim pewawancara dengan menunjukkan dokumen kesanggupan ini.</label>
            </div>
            <div style={{ margin: 5, marginLeft: 50, marginTop: 10, fontSize: 14 }}>
              <label style={{ width: '95%', fontWeight: 'bold' }}>
                Demikian pernyataan kesanggupan kami sebagai Orang Tua / Wali Murid. Pernyataan ini kami buat secara sadar dan tanpa tekanan dari pihak manapun
              </label>
            </div>
          </div>


          <div className="row" style={{ alignItems: 'center', justifyContent: 'end', marginTop: 10, paddingRight: 15 }}>
            <div style={{ margin: 5, fontSize: 14 }}>
              {/* <label>Ditetapkan di:</label>
                <br></br> */}
              <label style={{ fontWeight: 'bold' }}>{this.state.fields.prm_unit.city}, {moment().format('DD-MM-YYYY')}</label>
            </div>
          </div>

          <div className="row" style={{ alignItems: 'center', justifyContent: 'space-between', marginLeft: 50, paddingRight: 15 }}>
            <div style={{ width: 250, margin: 5, fontSize: 14 }}>
              <label style={{ fontWeight: 'bold' }}>Disetujui</label><br></br>
              {/* <label>Pewawancara</label> */}
            </div>
            <div style={{ display: 'flex', width: 150, fontSize: 14, marginTop: -10, alignItems: 'center', justifyContent: 'center' }}>
              <label style={{ fontWeight: 'bold' }}>Pewawancara</label>
              {/* <label style={{ fontWeight: 'bold' }}>{this.state.student_detail.interview_user !== null ? this.state.student_detail.interview_user.name : 'Panitia PPDB'}</label> */}
              {/* <label>(Orang Tua / Wali Murid)</label> */}
            </div>
          </div>

          {/* <div className="rowHorizontal">
               <div style={{width:250, margin:5, marginLeft:50, fontSize:11}}>
                <label>Pewawancara</label>
               </div>
                <div style={{margin:5, marginLeft:250, fontSize:11}}>
                <label>Yang membuat kesanggupan</label>
                <label>(Orang Tua / Wali Murid)</label>
              </div>
            </div> */}


          <div className="row">
            <div style={{ width: 250, margin: 50, marginLeft: 50 }}>

            </div>
          </div>


          <div className="row" style={{ alignItems: 'center', justifyContent: 'space-between', marginLeft: 50, paddingRight: 15 }}>
            <div style={{ width: 150, fontSize: 11, marginTop: -10 }}>
              <label style={{ textDecorationLine: 'underline' }}>(_________________________________)</label>
            </div>
            <div style={{ display: 'flex', width: 150, fontSize: 14, marginTop: -10, alignItems: 'center', justifyContent: 'center' }}>
              <label style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}>{this.state.student_detail.interview_name !== null || this.state.student_detail.interview_name !== '' ? '(' + this.state.student_detail.interview_name + ')' : '(_________________________________)'}</label>
            </div>
          </div>

          <div className="row" style={{ alignItems: 'center', justifyContent: 'space-between', marginLeft: 50, paddingRight: 15 }}>
          </div>

        </div>
        : null
    );
  }
}

class Cetak extends Component {

  constructor(props) {
    super(props);

    this.state = {
      student_id: '',
    }
  }

  componentDidMount() {
    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split('-')[0].trim()
    })
  }

  handleSubmit = () => {
    global.swal({
      text: 'Apakah SK sudah di PRINT dan Anda yakin akan disubmit?',
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {
          text: 'Submit',
          closeModal: false,
        }
      ],
    })
      .then((confirm) => {
        let errorMessage = { code: 403, message: 'cancel' };
        if (!confirm) throw errorMessage;
        // const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : localStorage.getItem('flow_id'), upp: localStorage.getItem('upp'), spp:localStorage.getItem('spp'), kegiatan: localStorage.getItem('kegiatan'), totalus : localStorage.getItem('totalus'), potongan: localStorage.getItem('potongan'), finalupp : localStorage.getItem('finalupp')}
        const form_data = new FormData();
        form_data.append("registration_id", this.state.student_id);
        form_data.append("action", "next");
        return fetch(global.variable.LINK_API + 'flow/update',
          {
            method: 'post',
            headers: {
              Accept: "application/json",
              // "Content-Type": "application/json",
              'Authorization': 'Bearer ' + global.cookies.get('access_token'),
            },
            body: form_data,
          })
          .then(this.handleErrors)
          .then(response => response.json())
          .then(resData => {
            if (resData.message === "Success") {
              global.swal("Success", 'Berhasil Submit', "success")
                .then((value) => {
                  window.location.href = "/wawancara";
                });
            }
          })
          .catch(err => {
            if (err) {
              if (err.code !== 403) {
                let message;
                if ('errors' in err) {
                  if (Array.isArray(err.errors)) {
                    message = err.errors[0];
                  } else {
                    message = err.errors;
                  }
                } else {
                  message = "Koneksi ke server gagal.";
                }
                global.swal("Error", message, "info");
              }
            } else {
              global.swal.stopLoading();
              global.swal.close();
            }
          });
      })
      .catch(err => {
        if (err) {
          if (err.code !== 403) {
            let message;
            if ('errors' in err) {
              if (Array.isArray(err.errors)) {
                message = err.errors[0];
              } else {
                message = err.errors;
              }
            } else {
              message = "Koneksi ke server gagal.";
            }
            global.swal("Error", message, "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

  }
  handleErrors = async (response) => {
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = { code: response.status, ...errors }
      throw errorMessage;
    }
    return response;
  }
  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Cetak SK Finansial</MDBBreadcrumbItem>
                      </MDBBreadcrumb>
                      <div>
                        <ReactToPrint
                          trigger={() => <MDBBtn style={{ marginBottom: 10 }} block color='info'>Print</MDBBtn>}
                          content={() => this.componentRef}
                        />
                        <ComponentToPrint copyStyle={true} ref={el => (this.componentRef = el)} />
                      </div>
                      <div className='rowHorizontal'>
                        {JSON.parse(localStorage.getItem("STUDENT_DETAIL")).flow_id === 'FIN' ? <p></p> : <MDBBtn onClick={this.handleSubmit} block style={{ margin: 5, height: 50 }} color='info'>Submit</MDBBtn>}
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Cetak;