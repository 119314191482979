import React, { Component } from "react";
import "moment/locale/id.js";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import { DatePickerInput } from "rc-datepicker";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import { trackPromise } from "react-promise-tracker";

import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";

const showSecond = false;
const str = showSecond ? "HH:mm:ss" : "HH:mm";

const moment = require("moment");

class Verifikasi_bukti_bayar_detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      student_id: "",
      is_show: false,
      isLoading: false,
      note_verification: "",
    };
  }

  getFiles(files) {
    this.setState({
      encrypt_64: files[0].base64,
      original_name: files[0].name,
    });
  }

  componentDidMount() {
    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      student_detail: JSON.parse(localStorage.getItem("STUDENT_DETAIL")),
      is_show: true,
    });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeDate = (jsDate, dateString) => {
    this.setState({ tanggal_wawancara: dateString });
  };

  onChangeTime = (value) => {
    let val;
    if (value) {
      val = value.format(str) + ":00";
    } else {
      val = "00:00:00";
    }
    this.setState({ jam_wawancara: val });
  };

  handleSubmit = (event) => {
    this.setState({ isLoading: true});
    event.preventDefault();
    const form_data = new FormData();
    form_data.append("registration_id", this.state.student_id);
    form_data.append("action", "next");
    // form_data.append('data', JSON.stringify(data));

    trackPromise(
      fetch(global.variable.LINK_API + "flow/update", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: form_data,
      })
        .then(this.handleErrors)
        .then((response) => response.json())
        .then((resData) => {
          if (resData.message === "Success") {
            global
              .swal("Success", "Berhasil Verifikasi Bukti Bayar", "success")
              .then((value) => {
                this.props.history.push("/verifikasibuktipendaftaran");
              });
          } else {
            global.swal(resData.error);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.code !== 403) {
              let message;
              if('errors' in err){
                if(Array.isArray(err.errors)){
                  message = err.errors[0];
                } else {
                  message = err.errors;
                }
              } else {
                message = "Koneksi ke server gagal.";
              }
              global.swal("Error", message, "info");
            }
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
    );
  };

  handleErrors = async (response) => {
    this.setState({
      isLoading: false
    })
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = {code: response.status,...errors}
      throw errorMessage;
    }
    return response;
  }

  handleBack = () => {
    this.props.history.push("/verifikasibuktipendaftaran");
  };

  download = (param) => (e) => {
    e.preventDefault();
    // console.log(param);
    window.open(
      // "/image/" + param,
      param,
      "_blank",
      "location=yes,height=600,width=600,scrollbars=yes,status=yes"
    );

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  };

  form1() {
    const { student_detail } = this.state;

    return (
      <div>
        <br></br>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>Jalur</label>
            <input
              className="form-control"
              type="Text"
              name="registration_type_id"
              maxLength="100"
              value={student_detail.registration_type_id || ""}
              disabled
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="exampleForm2">Nama Lengkap</label>
            <input
              className="form-control"
              type="Text"
              name="fullname"
              maxLength="100"
              value={student_detail.fullname || ""}
              disabled
            />
          </div>
          <div className="form-group col-md-12">
            <label>Nomor Registrasi</label>
            <input
              className="form-control"
              type="Text"
              name="gender"
              maxLength="100"
              value={
                student_detail.year +
                "-" +
                student_detail.registration_type_id +
                "-" +
                student_detail.registration_number
              }
              disabled
            />
          </div>
        </div>
      </div>
    );
  }

  formbukti() {
    const { student_detail } = this.state;
    return (
      /*<Image alt="Bukti transfer" style={{ width: 400 }} src={this.state.upload_transaction.encrypt_64} thumbnail />*/
      student_detail.image_link_bukti_transfer ? (
        <div>
          <Link
            to=""
            target="_blank"
            onClick={this.download(student_detail.image_link_bukti_transfer)}
          >
            Lihat Bukti Bayar
          </Link>
        </div>
      ) : (
        ""
      )
    );
  }

  handleTest = (event) => {
    global
      .swal({
        text: "Apakah anda yakin akan disubmit?",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 4,
          note_verification: this.state.note_verification,
        };

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          //console.log(resData);
          let m = moment();
          m = moment(resData.data.schedule_test_at, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          global
            .swal(
              "Success",
              "Jadwal test adalah " +
                m +
                "\n Pada pukul 13.00 – 15.00 WIB \nDi ruang " +
                resData.data.classroom_id,
              "success"
            )
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleTolak = (event) => {
    event.preventDefault();
    global
      .swal({
        text: "Alasan ditolak?",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = [];
        let items;
        items = Object.create({});
        items.field = "note_reject";
        items.value = confirm;
        data.push(items);
        const form_data = new FormData();
        form_data.append("registration_id", this.state.student_id);
        form_data.append("action", "reject");
        form_data.append("data",JSON.stringify(data));
        trackPromise(
          fetch(global.variable.LINK_API + "flow/update", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + global.cookies.get("access_token"),
            },
            body: form_data,
          })
            // })
            .then(this.handleErrors)
            .then((response) => response.json())
            .then((resData) => {
              if (resData.message === "Success") {
                console.log(resData);
                global
                  .swal("Success", "Berhasil Tolak", "success")
                  .then((value) => {
                    this.props.history.push("/verifikasibuktipendaftaran");
                  });
              }
            })
            .catch((err) => {
              if (err) {
                if (err.code !== 403) {
                  let message;
                  if('errors' in err){
                    if(Array.isArray(err.errors)){
                      message = err.errors[0];
                    } else {
                      message = err.errors;
                    }
                  } else {
                    message = "Koneksi ke server gagal.";
                  }
                  global.swal("Error", message, "info");
                }
              } else {
                global.swal.stopLoading();
                global.swal.close();
              }
            })
        )
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            let message;
            if('errors' in err){
              if(Array.isArray(err.errors)){
                message = err.errors[0];
              } else {
                message = err.errors;
              }
            } else {
              message = "Koneksi ke server gagal.";
            }
            global.swal("Error", message, "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleReturn = (event) => {
    event.preventDefault();
    global
      .swal({
        text: "Alasan dokumen dikembalikan",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = [];
        let items;
        items = Object.create({});
        items.field = "note_return";
        items.value = confirm;
        data.push(items);
        const form_data = new FormData();
        form_data.append("registration_id", this.state.student_id);
        form_data.append("action", "back");
        form_data.append("data",JSON.stringify(data))
        trackPromise(
          fetch(global.variable.LINK_API + "flow/update", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + global.cookies.get("access_token"),
            },
            body: form_data,
          })
            // })
          .then(this.handleErrors)
          .then((response) => response.json())
          .then((resData) => {
            if (resData.message === "Success") {
              // console.log(resData);
              global
                .swal("Success", "Berhasil Dikembalikan", "success")
                .then((value) => {
                  this.props.history.push("/verifikasibuktipendaftaran");
                });
            }
          })
          .catch((err) => {
            // console.log(err);
            if (err) {
              if (err.code !== 403) {
                let message;
                if('errors' in err){
                  if(Array.isArray(err.errors)){
                    message = err.errors[0];
                  } else {
                    message = err.errors;
                  }
                } else {
                  message = "Koneksi ke server gagal.";
                }
                global.swal("Error", message, "info");
              }
            } else {
              global.swal.stopLoading();
              global.swal.close();
            }
          })
        )
      })
      .catch(err => {
        if (err) {
          if(err.code!==403){
            let message;
            if('errors' in err){
              if(Array.isArray(err.errors)){
                message = err.errors[0];
              } else {
                message = err.errors;
              }
            } else {
              message = "Koneksi ke server gagal.";
            }
            global.swal("Error", message, "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
        
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Verifikasi Bukti Bayar Daftar
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <label>Nama Siswa</label>
                        <input
                          className="form-control"
                          type="Text"
                          name="jalur"
                          value={localStorage.getItem("DATA_JALUR")}
                          disabled
                        />
                        {this.state.is_show ? this.form1() : ""}

                        {this.state.is_show ? (
                          <div>
                            <hr />
                            <label
                              style={{
                                fontSize: 20,
                                textDecorationLine: "underline",
                              }}
                            >
                              Bukti Bayar
                            </label>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.is_show ? this.formbukti() : ""}

                        <br></br>
                        <div className="row">
                          <div className="col-md-4 col-12">
                            <MDBBtn
                              onClick={this.handleTolak}
                              block
                              color="danger"
                              style={{
                                margin: 5,
                                height: 50,
                              }}
                            >
                              Tolak
                            </MDBBtn>
                          </div>
                          <div className="col-md-4 col-12">
                            <MDBBtn
                              color="danger"
                              onClick={this.handleReturn}
                              block
                              style={{
                                margin: 5,
                                height: 50,
                              }}
                            >
                              Kembalikan
                            </MDBBtn>
                          </div>
                          {/* {this.state.registration_type_id === "JU" &&
                            this.state.flow_id === 3 ? (
                          <div className="col-md-4">
                            <MDBBtn
                              color='yellow'
                              onClick={this.handleCadangan}
                              block
                              style={{
                                margin: 5,
                                height: 50,
                                backgroundColor: "#e6e600",
                              }}
                            >
                              Confirm Cadangan
                            </MDBBtn>
                            </div>
                          ) : (
                            ""
                          )} */}

                          {/* {this.state.registration_type_id === "JU" &&
                            (this.state.flow_id === 3 ||
                              this.state.flow_id === 11) ? (
                          <div className="col-md-3">
                            <MDBBtn
                              color='primary'
                              data-toggle="modal"
                              data-target="#modal"
                              block
                              style={{ margin: 5, height: 50 }}
                            >
                              Confirm
                            </MDBBtn>
                            </div>
                          ) : ( */}
                          <div className="col-md-4 col-12">
                            <MDBBtn
                              color="success"
                              data-toggle="modal"
                              data-target="#modal"
                              // onClick={this.handleWawancara}
                              block
                              disabled={this.state.isLoading}
                              type="submit"
                              style={{
                                margin: 5,
                                height: 50,
                                // backgroundColor: "#33cc33",
                                padding: "10px",
                              }}
                            >
                              {
                                this.state.isLoading ? (
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : 'Konfirmasi Bukti Bayar'
                              }
                            </MDBBtn>
                          </div>
                          {/* )} */}
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Verifikasi_bukti_bayar_detail;
