import React, { Component } from "react";
// import { Button, FormGroup, FormControl, FormLabel ,Image } from "react-bootstrap";
import "./Login.css";
import Cookies from 'js-cookie'
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBFormInline,
  MDBCollapse,
  MDBContainer,
  MDBNavbarToggler,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBInput,
  MDBIcon,
  MDBView,
  MDBBtn
} from 'mdbreact';
import '../src/components/pages/Login.css';

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()


export default class Forgetpassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
  }


  handleChange = event => {
    this.setState({
      email: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    
    try {
      fetch(global.variable.LINK_API + 'password/reset',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state), 
      })
      .then(response =>  response.json())
      .then(resData => {
        console.log(JSON.stringify(resData));
        if(resData.message==="Success"){
          global.swal("Success", 'Berhasil Reset password \nSilakan periksa email anda', "success")
          .then((value) => {
            window.location.href="/";
          });
          //global.swal(Cookies.get('access_token'));
        }else{
          let msgerr = "";
          if(resData.errors){
            let count = Object.keys(resData.errors).length;
            for(let i=0; i<count; i++){
              msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
            }
          }
          global.swal(msgerr)
        }
        
      })
    

      return true
    } catch (error) {
      global.swal(error);
      return false
    }
  }

  back(){
    
    window.location.href="/";
  }

  render() {
    return (
      <div className='classic-form-page' id='login' style={{backgroundImage: `url(${require(`./assets/images/background.png`)})`, backgroundPosition: 'center',  height:this.state.height,
        backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>
        {/* <div className="text-center" style={{background:'transparent', border: '0px' ,marginTop:-50, marginBottom:30}}>
        <Image alt="Bukti transfer" style={{width: 500, background:'transparent', }} src={require('./assets/images/logo_ppdb_online_domsav.png')} />
       </div> */}
        <form onSubmit={this.handleSubmit}>
        <MDBContainer>
              <MDBRow>
                <MDBCol md='10' lg='6' xl='5' sm='12' className='mt-5 mx-auto'>
                  <MDBCard>
                    <MDBCardBody>
                      <div className='form-header gradient-card-header blue-gradient'>
                        <h3>
                          <MDBIcon
                            icon='lock'
                            className='mt-2 mb-2 text-white'
                          />{' '}
                          Forget Password:
                        </h3>
                      </div>
                      <MDBInput
                        autoFocus
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        label='Email'
                        icon='envelope'
                        iconClass='white-text'
                      />
                      <div className='text-center mt-3 black-text'>
                     
                        <MDBBtn
                          color='primary'
                          block
                          type="submit"
                        >
                          Submit
                        </MDBBtn>

                        
                        <MDBBtn
                          color='primary'
                          block
                          onClick={this.back}
                          type="button">
                          Back
                        </MDBBtn>
                      </div>
                     
                   
                     
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
        
        </form>
      </div>
    );
  }
}