import React, { Component } from "react";
// import { Form, Breadcrumb, Button } from "react-bootstrap";
import "moment/locale/id.js";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";

class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      achieve_type_id: "",
      achieve_rank_id: "",
      achieve_type_id_name: "",
      achieve_rank_id_name: "",
      name: "",
      promoter: "",
      rank: "",
    };
  }

  componentDidMount() {
    /*if(localStorage.getItem('reg2')){
      this.setState(JSON.parse(localStorage.getItem('reg2')));
    }*/
  }

  createSelectAchieveType() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_achieve_type;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectAchieveRank() {
    let items = [];
    let params = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).pdf_achieve_rank;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id + "_" + params[i].name}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  showRemove() {
    if (localStorage.getItem("reg6")) {
      return (
        <MDBBtn
          onClick={this.handleClear}
          block
          style={{ margin: 5, height: 50, width: "20%" }}
        >
          Clear Data
        </MDBBtn>
      );
    }
  }

  showPrestasi() {
    if (localStorage.getItem("reg6")) {
      let guardian = JSON.parse(localStorage.getItem("reg6"));
      console.log(localStorage.getItem("reg6"));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) {
        showitem.push(
          <div key={guardian[i].name}>
            <hr />
            <div className="rowHorizontal">
              <label className="col-md-3" style={{ width: 200, margin: 5 }}>
                Jenis{" "}
              </label>
              <label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].achieve_type_id_name}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3" style={{ width: 200, margin: 5 }}>
                Nama Prestasi{" "}
              </label>
              <label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].name}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3" style={{ width: 200, margin: 5 }}>
                Penyelenggara{" "}
              </label>
              <label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].promoter}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3" style={{ width: 200, margin: 5 }}>
                Tingkat{" "}
              </label>
              <label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].achieve_rank_id_name}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label className="col-md-3" style={{ width: 200, margin: 5 }}>
                Peringkat{" "}
              </label>
              <label className="col-md-6" style={{ margin: 5 }}>
                {guardian[i].rank}{" "}
              </label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onDropdownSelected(e) {
    this.setState({
      [e.target.name]: e.target.value.split("_")[0],
      [e.target.name + "_name"]: e.target.value.split("_")[1],
    });
  }

  handleSubmit = (event) => {
    // event.preventDefault();

    // window.location.href = "/pendaftaran7";
    event.preventDefault();
    event.stopPropagation();    
    //console.log("state 4 " + JSON.stringify(this.state));
    // console.log(this.state)
    let reg1 = JSON.parse(localStorage.getItem('reg1'));
    let reg2 = JSON.parse(localStorage.getItem('reg2'));
    let reg3 = JSON.parse(localStorage.getItem('reg3'));
    let reg4 = JSON.parse(localStorage.getItem('reg4'));
    let reg5 = JSON.parse(localStorage.getItem('reg5'));
    let reg6 = JSON.parse(localStorage.getItem('reg6'));
    // let reg7 = JSON.parse(localStorage.getItem('reg7'));

    let statereg4 = JSON.parse(localStorage.getItem('statereg4'));

    const result = {};
    Object.keys(reg1).forEach(key => result[key] = reg1[key]);
    Object.keys(reg2).forEach(key => result[key] = reg2[key]);
    Object.keys(reg3).forEach(key => result[key] = reg3[key]);
    result['guardian'] = reg4;
    result['sibling'] = reg5;
    result['achieve'] = reg6;
    // result['score'] = reg7;
    result['parent_status_id'] = statereg4.parent_status_id;
    result['both_parent_work'] = statereg4.both_parent_work;

    console.log(JSON.stringify(result));
    
    const data = { data:JSON.stringify(result) }

    global.swal({
      text: 'Apakah anda yakin akan disubmit?',
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {text: 'Submit', 
        closeModal: false,}
      ],
    })
    .then((confirm) => {
      let errorMessage =  { code : 403, message : 'cancel' };
      if (!confirm) throw errorMessage;
      return fetch(global.variable.LINK_API + 'student/store',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      });
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        localStorage.removeItem('reg1');
        localStorage.removeItem('reg2');
        localStorage.removeItem('reg3');
        localStorage.removeItem('reg4');
        localStorage.removeItem('statereg4');
        localStorage.removeItem('reg5');
        localStorage.removeItem('reg6');
        // localStorage.removeItem('reg7');
        global.swal("Success", resData.message, "success", )
        .then((value) => {
          window.location.href="/pendaftaran";
        });
      }else{
        global.swal("Error", resData.error, "info")
      }
    })
    .catch(err => {
      if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
      } else {
        global.swal.stopLoading();
        global.swal.close();
      }
    });


  };

  handleAdd = () => {
    console.log("state 4 " + JSON.stringify(this.state));
    let item = [];
    if (localStorage.getItem("reg6")) {
      item = JSON.parse(localStorage.getItem("reg6"));
    }
    item.push(this.state);

    console.log("score " + JSON.stringify(item));

    localStorage.setItem("reg6", JSON.stringify(item));

    window.location.href = "/pendaftaran6";
  };

  handleClear = () => {
    localStorage.removeItem("reg6");
    window.location.href = "/pendaftaran6";
  };

  handleBack = () => {
    window.location.href = "/pendaftaran5";
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pendaftaran Step 6
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleAdd}>
                        <label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Prestasi
                        </label>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Jenis</label>
                            <select
                              className="form-control"
                              name="achieve_type_id"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={
                                this.state.achieve_type_id +
                                "_" +
                                this.state.achieve_type_id_name
                              }
                              required
                            >
                              <option value="">-Pilih Jenis-</option>
                              {this.createSelectAchieveType()}
                            </select>
                          </div>
                          <div className="form-group col-md-6">
                            <label>Nama Prestasi</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              onChange={this.onChange}
                              maxLength="200"
                              value={this.state.name}
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Penyelenggara</label>
                            <input
                              type="text"
                              className="form-control"
                              name="promoter"
                              onChange={this.onChange}
                              maxLength="200"
                              value={this.state.promoter}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Tingkat</label>
                            <select
                              className="form-control"
                              name="achieve_rank_id"
                              onChange={this.onDropdownSelected.bind(this)}
                              value={
                                this.state.achieve_rank_id +
                                "_" +
                                this.state.achieve_rank_id_name
                              }
                              required
                            >
                              <option value="">-Pilih Tingkat-</option>
                              {this.createSelectAchieveRank()}
                            </select>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Peringkat</label>
                            <input
                              type="text"
                              className="form-control"
                              name="rank"
                              maxLength="5"
                              value={this.state.rank}
                              onChange={this.onChange}
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6"></div>
                        </div>

                        <div className="rowHorizontal">
                          <MDBBtn
                            color="primary"
                            type="submit"
                            block
                            style={{ height: 50 }}
                          >
                            Tambah
                          </MDBBtn>
                        </div>

                        {this.showRemove()}
                        {this.showPrestasi()}
                        <div className="row">
                          <div className="col-md-6">
                            <MDBBtn
                              color="primary"
                              onClick={this.handleBack}
                              block
                              style={{ marginTop: 5, height: 50 }}
                            >
                              Prev
                            </MDBBtn>
                          </div>
                          <div className="col-md-6">
                            <MDBBtn
                              color="primary"
                              onClick={this.handleSubmit}
                              block
                              style={{ marginTop: 5, height: 50 }}
                              disabled={this.state.name!=''?true:false}
                            >
                              Submit
                            </MDBBtn>
                          </div>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
