import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import CurrencyFormat from "react-currency-format";
import {
  MDBBreadcrumb,
  // MDBBreadcrumbItem,
  // MDBContainer,
  // MDBNavbar,
  // MDBNavbarNav,
  // MDBNavItem,
  // MDBRow,
  // MDBCol,
  MDBCard,
  MDBCardBody,
  // MDBInput,
  // MDBSwitch,
  // MDBFileInput,
  // MDBRangeInput,
  // MDBSelect,
  // MDBSelectInput,
  // MDBSelectOptions,
  // MDBSelectOption,
  MDBLink,
  // MDBBtn,
} from "mdbreact";

const moment = require("moment");
class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data_student: "",
      is_show: false,
      id_selected: "",
      whatsapp: "",
    };
  }

  componentDidMount() {

    fetch(global.variable.LINK_API + 'auth/parameter', {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
        }
        // console.log(JSON.parse(localStorage.getItem("DATA_PARAMETER")));
      })

    fetch(global.variable.LINK_API + "flow/liststudent", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          this.setState({ data_student: resData.data });
          this.setState({ is_show: true });
          // console.log(this.state.data_student);
        }
      });
  }

  handleReject = (param) => (e) => {
    console.log(param);

    const data = { student_id: param, flow_id: 99, note_confirmation: "" };

    fetch(global.variable.LINK_API + "flow/update", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          console.log(resData);

          global.swal("Success", "Berhasil Tolak", "success").then((value) => {
            window.location.href = "/home";
          });
        }
      });
  };

  handleApprove = (param) => (e) => {
    console.log(param);

    const data = { student_id: param };

    fetch(global.variable.LINK_API + "student/confirmation", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          console.log(resData);

          global
            .swal("Success", "Berhasil Disetujui", "success")
            .then((value) => {
              window.location.href = "/home";
            });
        }
      });
  };

  handleOnline = (param) => (e) => {
    const data = {
      student_id: this.state.id_selected,
      flow_id: 5,
      note_confirmation: "",
      interview_method: 2,
      whatsapp: this.state.whatsapp,
    };

    fetch(global.variable.LINK_API + "flow/update", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          console.log(resData);

          const moment = require("moment");
          let m = moment();
          m = moment(
            resData.data.schedule_interview_at,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD-MM-YYYY HH:mm:ss");
          global
            .swal("Success", "Jadwal Wawancara adalah " + m, "success")
            .then((value) => {
              window.location.href = "/home";
            });
        }
      });
  };

  handleTatapMuka = (param) => (e) => {
    const data = {
      student_id: param,
      flow_id: 5,
      note_confirmation: "",
      interview_method: 1,
    };

    fetch(global.variable.LINK_API + "flow/update", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          console.log(resData);

          const moment = require("moment");
          let m = moment();
          m = moment(
            resData.data.schedule_interview_at,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD-MM-YYYY HH:mm:ss");
          global
            .swal("Success", "Jadwal Wawancara adalah " + m, "success")
            .then((value) => {
              window.location.href = "/home";
            });
        }
      });
  };

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  getMaster(id = '', table) {
    var array = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    )[table];
    let name;
    if (id != '') {
      var arrayfilter = array.filter((data) => { if (data.id == id) return data });
      name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    } else {
      name = '';
    }
    return name;
  }

  form() {
    let array = this.state.data_student;
    let showitem = [];
    let currency = Intl.NumberFormat("en-ID");
    for (let i = 0; i < array.length; i++) {
      showitem.push(
        <div key={array[i].id}>
          <hr />
          <table>
            <tbody>
              <tr>
                <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                  Nama
                </td>
                <td style={{ margin: 5, fontWeight: "bold" }}>
                  : {array[i].fullname}
                </td>
              </tr>
              <tr>
                <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                  No Pendaftaran
                </td>
                <td style={{ margin: 5, fontWeight: "bold" }}>
                  :{" "}
                  {array[i].year +
                    "-" +
                    // array[i].registration_type_id +
                    // "-" +
                    array[i].registration_number}{" "}
                </td>
              </tr>
              <tr>
                <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                  No Virtual Account
                </td>
                <td style={{ margin: 5, fontWeight: "bold" }}>
                  : {array[i].registration_id}
                </td>
              </tr>
              {/* <tr>
                <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                  Tgl Pendaftaran
                </td>
                <td style={{ margin: 5, fontWeight: "bold" }}>
                  :{moment(array[i].created_at).format("DD-MM-YYYY")}
                </td>
              </tr> */}
              <tr>
                <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                  Status
                </td>
                <td style={{ margin: 5, fontWeight: "bold" }}>
                  : {this.getMaster(array[i].flow_id, 'pdf_flow')}
                </td>
              </tr>
              {array[i].flow_id === 'PRA' ? (
                <>
                  <tr>
                    <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                      Nominal Bayar Formulir{" "}
                    </td>
                    <td style={{ margin: 5, fontWeight: "bold" }}>
                      :{" "}
                      {"Rp. " +
                        currency.format(
                          // array[i].registration_type_id.registration_fee
                          JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_unit.registration_fee
                        )}
                    </td>
                  </tr>
                  {array[i].note_return ? (
                    <tr>
                      <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                        Catatan Verifikasi
                      </td>
                      <td style={{ margin: 5, fontWeight: 'bold' }}>
                        :{" "}
                        {array[i].note_return}
                      </td>
                    </tr>
                  ) : null}
                </>
              ) : null}
              {array[i].flow_id === 'INT' ? (
                <>
                  <tr>
                    <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                      Tanggal Wawancara
                    </td>
                    <td style={{ margin: 5, fontWeight: "bold" }}>
                      : {array[i].schedule_interview_at ? moment(array[i].schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm:ss') : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                      Tempat Wawancara
                    </td>
                    <td style={{ margin: 5, fontWeight: "bold" }}>
                      : {array[i].schedule_interview_place}
                    </td>
                  </tr>
                  {/* <tr>
                    <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                      Tanggal Sosialisasi
                    </td>
                    <td style={{ margin: 5, fontWeight: "bold" }}>
                      : { array[i].schedule_socialization_at?moment(array[i].schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY HH:mm:ss'):''}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                     Tempat Sosialisasi
                    </td>
                    <td style={{ margin: 5, fontWeight: "bold" }}>
                      :{array[i].schedule_socialization_place}
                    </td>
                  </tr> */}
                </>
              ) : null}
            </tbody>
          </table>
          {/* {array[i].flow_id === 4 ? (
            <>
              <table>
                <tr>
                  <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                    Tanggal Test
                  </td>
                  <td style={{ margin: 5, fontWeight: "bold" }}>
                    :{" "}
                    {moment(
                      array[i].schedule[0].schedule_test_at,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("DD MMMM YYYY HH:mm:ss")}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                    Tempat/ Link Test
                  </td>
                  <td style={{ margin: 5, fontWeight: "bold" }}>
                    :{" "}
                    {this.isValidURL(array[i].schedule[0].classroom_id) ? (
                      <a
                        href={array[i].schedule[0].classroom_id}
                        target="_blank"
                      >
                        {array[i].schedule[0].classroom_id}
                      </a>
                    ) : (
                      array[i].schedule[0].classroom_id
                    )}
                  </td>
                </tr>
              </table>
            </>
          ) : (
            ""
          )}
          {array[i].flow_id === 5 ? (
            <>
              <table>
                <tr>
                  <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                    Tanggal Wawancara{" "}
                  </td>
                  <td style={{ margin: 5, fontWeight: "bold" }}>
                    :{" "}
                    {moment(
                      array[i].schedule[0].schedule_interview_at,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("DD MMMM YYYY HH:mm:ss")}{" "}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                    Tempat/ Link Wawancara{" "}
                  </td>
                  <td style={{ margin: 5, fontWeight: "bold" }}>
                    :{" "}
                    {this.isValidURL(
                      array[i].schedule[0].schedule_interview_place
                    ) ? (
                      <a
                        href={array[i].schedule[0].schedule_interview_place}
                        target="_blank"
                      >
                        {array[i].schedule[0].schedule_interview_place}
                      </a>
                    ) : (
                      array[i].schedule[0].schedule_interview_place
                    )}{" "}
                  </td>
                </tr>
              </table>
            </>
          ) : (
            ""
          )}
          {array[i].is_confirmation ? (
            <table>
              <tr>
                <td style={{ width: 250, margin: 5, fontWeight: "bold" }}>
                  Alasan Pindah
                </td>
                <td style={{ margin: 5 }}>
                  : {array[i].note_change_registration_type}
                </td>
              </tr>
            </table>
          ) : (
            ""
          )}
          {array[i].is_confirmation ? (
            <div className="rowHorizontal">
              <p className="col-md-8" style={{ margin: 5 }}>
                {array[i].note_confirmation}
              </p>
              <MDBBtn
                className="col-md-2"
                onClick={this.handleApprove(array[i].id)}
                style={{ margin: 2, height: 30 }}
              >
                Setuju
              </MDBBtn>
              <MDBBtn
                className="col-md-2"
                onClick={this.handleReject(array[i].id)}
                style={{ margin: 2, height: 30 }}
              >
                Tolak
              </MDBBtn>
            </div>
          ) : (
            ""
          )}
          {array[i].flow_id === 10 ? (
            <div className="rowHorizontal">
              <p className="col-md-8" style={{ margin: 5 }}>
                Pilih metode wawancara yang diinginkan
              </p>
              <MDBBtn
                className="col-md-2"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => this.setState({ id_selected: array[i].id })}
                style={{ margin: 2, height: 30 }}
              >
                Online
              </MDBBtn>
              <MDBBtn
                className="col-md-2"
                onClick={this.handleTatapMuka(array[i].id)}
                style={{ margin: 2, height: 30 }}
              >
                Tatap Muka
              </MDBBtn>
            </div>
          ) : (
            ""
          )} */}
        </div>
      );
    }

    return showitem;
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBLink active to="Home">
                          Home
                        </MDBLink>
                      </MDBBreadcrumb>
                      {this.form()}
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">
                      Masukan nomor Whatsapp untuk keperluan wawancara
                    </h3>
                    <h5 className="modal-title" id="exampleModalLabel">
                      No Jurusan {this.state.id_selected}
                    </h5>
                  </div>
                  <div className="modal-body">
                    <CurrencyFormat
                      input="number"
                      name="whatsapp"
                      format="+62############"
                      onChange={this.onChange}
                      value={this.state.whatsapp}
                      maxLength="25"
                      style={{ width: "100%" }}
                      required
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleOnline()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
