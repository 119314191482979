import React, { Component } from "react";
// import { Button, FormGroup, FormControl, FormLabel ,Image } from "react-bootstrap";
import "./Login.css";
import Cookies from 'js-cookie'
import { trackPromise } from 'react-promise-tracker';
//import "./css/styles.css";
import {
  // MDBNavbar,
  // MDBNavbarBrand,
  // MDBNavbarNav,
  // MDBNavItem,
  // MDBNavLink,
  // MDBFormInline,
  // MDBCollapse,
  MDBContainer,
  // MDBNavbarToggler,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  // MDBMask,
  MDBInput,
  MDBIcon,
  // MDBView,
  MDBBtn
} from 'mdbreact';
import '../src/components/pages/Login.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;


export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

export const authenticate = async () => {
    try {
     
      //console.log(this.state);
      

      const tokens = '123';

      const expires = (tokens.expires_in || 60 * 60) * 1000
      const inOneHour = new Date(new Date().getTime() + expires)

      // you will have the exact same setters in your Login page/app too
      //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
      //Cookies.set('refresh_token', tokens.refresh_token)

      Cookies.set('access_token', '333', { expires: inOneHour })
      Cookies.set('refresh_token', '222')

      return true
    } catch (error) {
      return false
    }
}

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subdomain:global.variable.subdomain,
      id: "",
      password: "",
      height: '',
      isLoading: false,
      passwordShown: false,
      setPasswordShown: false,
    };
    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  validateForm() {
    return this.state.id.length > 0 && this.state.password.length > 0;
  }

  handleChangeuser = event => {

    this.setState({
      id: event.target.value
    });
  }

  handleChangepassword = event => {

    this.setState({
      password: event.target.value
    });
  }

  togglePasswordVisiblity = event =>{
    this.setState({
      passwordShown : !this.state.passwordShown
    });
  }

  register(){
    
    window.location.href="/register";
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({isLoading: true});
    /*global.swal({
        title: 'Now loading',
        closeOnEsc: false,
        closeOnClickOutside: false,
        button: false,
        imageUrl: "assets/images/loader.gif",
        onOpen: () => {
          global.swal.showLoading();
        }
      }).then(
        
      )*/
      trackPromise(
        fetch(global.variable.LINK_API + 'auth/login',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
          body: JSON.stringify(this.state), 
        })
        .then(this.handleErrors)
        .then(response =>  response.json())
        .then(resData => {
          if(resData.message==="Success"){
            // console.log(resData);
            const tokens = resData.access_token;
      
            const expires = (tokens.expires_in || 60 * 60) * 1000
            const inOneHour = new Date(new Date().getTime() + expires)
      
            // you will have the exact same setters in your Login page/app too
            //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
            //Cookies.set('refresh_token', tokens.refresh_token)
      
            Cookies.set('access_token', tokens, { expires: inOneHour })
            Cookies.set('refresh_token', 'refresh_token')            
            localStorage.setItem('menu', JSON.stringify(resData.menu));
            localStorage.setItem('logo', JSON.stringify(resData.logo));
            localStorage.setItem('levels_id', JSON.stringify(resData.levels_id));
            localStorage.setItem('kop', JSON.stringify(resData.kop));
            localStorage.setItem('levels_id', JSON.stringify(resData.levels_id));
            localStorage.setItem('id', this.state.id);
            localStorage.setItem('redirect', resData.redirect);
            this.props.history.push("/home");
            //global.swal(Cookies.get('access_token'));
          }else{
            this.setState({isLoading: false})
            global.swal(resData.error);
          }
          
        })
        .catch((err) => {
          // console.log(err);
          this.setState({isLoading: false})
          if (err) {
            if (err.code !== 403) {
              let message;
              if('errors' in err){
                if(Array.isArray(err.errors)){
                  message = err.errors[0];
                } else {
                  message = err.errors;
                }
              } else {
                message = "Koneksi ke server gagal.";
              }
              global.swal("Error", message, "info");
            }
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
      )
  }

  handleErrors = async (response) => {
    this.setState({isLoading: false})
    if (!response.ok) {
      const errors = await response.clone().json();
      const errorMessage = {code: response.status,...errors}
      throw errorMessage;
    }
    return response;
  }

  render() {

    return (
      <div className='classic-form-page' id='login' style={{backgroundImage: `url(${require(`./assets/images/background.png`)})`, backgroundPosition: 'center',  height:this.state.height,
      backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>
       {/* <div className="text-center" style={{background:'transparent', border: '0px', marginBottom:50}}>
         <Image alt="Bukti transfer" style={{width: 500, background:'transparent', }} src={require('./assets/images/logo_ppdb_online_domsav.png')} />
       </div> */}
        <form onSubmit={this.handleSubmit}>
       
          {/* <MDBMask
            className='d-flex justify-content-center align-items-center'
            overlay='stylish-strong'
          > */}
            {/* <FormLabel style={{paddingLeft:12}}>User ID</FormLabel > */}
              {/* <div className="pass-wrapper" style={{position: 'relative', display: 'flex', marginBottom: '14px'}}> */}
              <MDBContainer>
              <MDBRow>
                <MDBCol md='10' lg='6' xl='5' sm='12' className='mt-5 mx-auto'>
                  <MDBCard>
                    <MDBCardBody>
                      <div className='form-header gradient-card-header blue-gradient'>
                        <h3>
                          <MDBIcon
                            icon='user'
                            className='mt-2 mb-2 text-white'
                          />{' '}
                          Log in:
                        </h3>
                      </div>
                      <MDBInput
                        autoFocus
                        type="text"
                        name="user_id"
                        value={this.state.user_id}
                        onChange={this.handleChangeuser}
                        label='User ID'
                        icon='user'
                        iconClass='white-text'
                      />
                      <MDBInput
                        placeholder="Password"
                        name="password"
                        type={this.state.passwordShown ? "text" : "password"}
                        value={this.state.password}
                        onChange={this.handleChangepassword}
                        label='Your password'
                        icon='lock'
                        iconClass='white-text'
                      />
                      <div className='text-center mt-3 black-text'>
                      <MDBBtn 
                        color='primary'
                        disabled={!this.validateForm()}
                        type="submit">
                        {
                          this.state.isLoading ? (
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : 'Login'
                        }
                      </MDBBtn>

                      {/* <MDBBtn 
                        color='primary'
                        onClick={this.register}
                        type="submit">
                        Register</MDBBtn> */}
                      </div>
                     
                    <p style={{textAlign:'bold'}}><a href="/forgetpassword">Lupa Password?</a></p>
                     
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
           
        
        </form>
      </div>
    );
  }
}