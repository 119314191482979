import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBTable,
} from "mdbreact";
import { isEmpty, map } from "lodash";

class Edit_param_pusat extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const { state } = location;
    const { data } = state;

    this.state = {
      data: data,
    };
  }

  componentDidMount() {
    // const { location } = this.props;
    // const { state } = location;
    // const { isEdit, data } = state;

    // if (isEdit && data) {
    //   this.setState({data: data})
    // }
  }

  onClickEditDokumen(val) {
    const { history } = this.props;
    history.push("/editparamdokumen", {
      data: val,
      isEdit: true,
    });
  }

  onClickEditMenu(val) {
    const { history } = this.props;
    history.push("/editparammenu", {
      data: val,
      isEdit: true,
    });
  }

  onClickEditPayment(val) {
    const { history } = this.props;
    history.push("/editparampayment", {
      data: val,
      isEdit: true,
    });
  }

  onClickEditFlow(val) {
    const { history } = this.props;
    history.push("/editparamflow", {
      data: val,
      isEdit: true,
    });
  }

  onClickEditMsStudent(val) {
    const { history } = this.props;
    history.push("/editparamstudent", {
      data: val,
      isEdit: true,
    });
  }

  render() {
    const { data } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        {/* <MDBBreadcrumbItem href>
                          <a href="/pengaturanunit">Pengaturan Unit Pusat</a>
                        </MDBBreadcrumbItem> */}
                        <MDBBreadcrumbItem active>
                          Edit Parameter
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>
                      <form>
                        <div className="form-row">
                          <div className="col-md-6">
                            <b>Subdomain</b>
                            <input
                              className="form-control"
                              type="Text"
                              name="jalur"
                              value={data.subdomain || ""}
                              disabled
                            />
                          </div>
                        </div>
                        <br></br>
                        <div className="form-group">
                          <MDBTable bordered>
                            <thead className="blue-grey lighten-4">
                              <tr>
                                <th style={{ textAlign: "center" }}>Jenis</th>
                                <th style={{ textAlign: "center" }} colSpan={2}>
                                  Opsi
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ textAlign: "center" }}>
                                  Parameter Dokumen Upload
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <MDBBtn
                                    color="primary"
                                    block
                                    onClick={() => this.onClickEditDokumen(data)}
                                  >
                                    Edit
                                  </MDBBtn>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "center" }}>
                                  Pengaturan Data Calon Siswa
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <MDBBtn
                                    color="primary"
                                    block
                                    onClick={() => this.onClickEditMsStudent(data)}
                                  >
                                    Edit
                                  </MDBBtn>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "center" }}>
                                  Pengaturan Wawancara
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <MDBBtn
                                    color="primary"
                                    block
                                    onClick={() => this.onClickEditPayment(data)}
                                  >
                                    Edit
                                  </MDBBtn>
                                </td>
                              </tr>
                            </tbody>
                          </MDBTable>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Edit_param_pusat;
