import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Image from '../Image';
import Home from '../pendaftaran/Home';
import Pra_pendaftaran from '../pendaftaran/Pra_pendaftaran';
import Pendaftaran from '../pendaftaran/Pendaftaran';
import Pendaftaran1 from '../pendaftaran/Pendaftaran1';
import Pendaftaran2 from '../pendaftaran/Pendaftaran2';
import Pendaftaran3 from '../pendaftaran/Pendaftaran3';
import Pendaftaran4 from '../pendaftaran/Pendaftaran4';
import Pendaftaran5 from '../pendaftaran/Pendaftaran5';
import Pendaftaran6 from '../pendaftaran/Pendaftaran6';
import Pendaftaran7 from '../pendaftaran/Pendaftaran7';

import Upload_dokumen from '../pendaftaran/Upload_dokumen';
import Upload_dokumendetail from '../pendaftaran/Upload_dokumendetail';

import Bayar_pendaftaran from '../pendaftaran/Bayar_pendaftaran';
import Bayar_pendaftaran_detail from '../pendaftaran/Bayar_pendaftaran_detail';

import Changepassword from '../pendaftaran/Change_password';

import Verifikasi_bukti_bayar from '../pendaftaran/Verifikasi_bukti_bayar';
import Verifikasi_bukti_bayar_detail from '../pendaftaran/Verifikasi_bukti_bayar_detail';
import Verifikasi from '../pendaftaran/Verifikasi';
import Verifikasi_detail from '../pendaftaran/Verifikasi_detail';

import Report_pendaftaran from '../pendaftaran/Report_pendaftaran';
import Report_pendaftaran_detail from '../pendaftaran/Report_pendaftaran_detail';

import Report_jadwal_wawancara from '../pendaftaran/Report_jadwal_wawancara';
import Report_jadwal_wawancara_detail from '../pendaftaran/Report_jadwal_wawancara_detail';

import Wawancara from '../pendaftaran/Wawancara';
import Wawancara_detail from '../pendaftaran/Wawancara_detail';

import Report_wawancara from '../pendaftaran/Report_wawancara';
import Report_wawancara_detail from '../pendaftaran/Report_wawancara_detail';

import Detail_student from '../pendaftaran/Detail_student';
import Cetak_SK_Finansial from '../pendaftaran/cetak_skfinansial';

import Pengaturan_units_pusat from '../pengaturan/Pengaturan_units_pusat';
import Pengaturan_units from '../pengaturan/Pengaturan_units';
import Pengaturan_umum from '../pengaturan/Pengaturan_umum';
import Pengaturan_admin from '../pengaturan/Pengaturan_admin';
import Tambah_unit from '../pengaturan/Tambah_unit';
import Tambah_dokumen from '../pengaturan/Tambah_dokumen';
import Tambah_menu from '../pengaturan/Tambah_menu';
import Tambah_organisasi from '../pengaturan/Tambah_organisasi';
import Tambah_ms_student from '../pengaturan/Tambah_ms_student';
import Tambah_user from '../pengaturan/Tambah_user';
import Tambah_user_pusat from '../pengaturan/Tambah_user_pusat';

import Edit_param from '../pengaturan/Edit_param';
import Edit_param_pusat from '../pengaturan/Edit_param_pusat';
import Edit_param_unit from '../pengaturan/Edit_param_unit';
import Edit_prm_dokumen from '../pengaturan/Edit_prm_dokumen';
import Edit_prm_menu from '../pengaturan/Edit_prm_menu';
import Edit_prm_payment from '../pengaturan/Edit_prm_payment';
import Edit_prm_flow from '../pengaturan/Edit_prm_flow';
import Edit_prm_student from '../pengaturan/Edit_prm_student';

import Customers from './pages/Customers';
import Invoice from './pages/Invoice';
import Chat from './pages/Chat';
import Support from './pages/Support';
import PageCreator from './pages/PageCreator.js';

import PV1 from './profile/v1';
import PV2 from './profile/v2';
import PExtended from './profile/extended';

import Google from './maps/google';
import MFull from './maps/full';
import Vector from './maps/vector';

import TBasic from './tables/basic';
import TExtended from './tables/extended';
import Datatable from './tables/datatable';

import Grid from './css/grid';
import Utilities from './css/utilities';
import Code from './css/code';
import Icons from './css/icons';
import Images from './css/images';
import Typography from './css/typography';
import Colors from './css/colors';
import Shadows from './css/shadows';
import Masks from './css/masks';
import Hover from './css/hover';
import MediaObject from './css/media';
import Animations from './css/animations';

import Buttons from './components/buttons';
import Cards from './components/cards';
import Panels from './components/panels';
import List from './components/list';
import Progress from './components/progress';
import Tabs from './components/tabs';
import Tags from './components/tags';
import DatePicker from './components/datepicker';
import TimePicker from './components/timepicker';
import Popover from './components/popover';
import Tooltip from './components/tooltips';
import Stepper from './components/stepper';
import Pagination from './components/pagination';
import Collapse from './components/collapse';

import FBasic from './forms/basic';
import FExtended from './forms/extended';
import FValidation from './forms/validation';

import Charts from './others/charts';
import Alerts from './others/alerts';
import Modals from './others/modals';
import Sections from './others/sections';
import Calendar from './others/calendar';

const fourtOFour = () => <h1 className="text-center">404</h1>

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        
        <Route exact path="/image/:id" component={Image}/>
        {/* <Route path='/' exact component={DV1} /> */}
        <Route path='/home' component={Home} />
        <Route path='/prapendaftaran' component={Pra_pendaftaran} />
        <Route path='/pendaftaran' component={Pendaftaran} />
        <Route path='/pendaftaran1' component={Pendaftaran1} />
        <Route path='/pendaftaran2' exact component={Pendaftaran2} />
        <Route path='/pendaftaran3' exact component={Pendaftaran3} />
        <Route path='/pendaftaran4' exact component={Pendaftaran4} />
        <Route path='/pendaftaran5' exact component={Pendaftaran5} />
        <Route path='/pendaftaran6' exact component={Pendaftaran6} />
        <Route path='/pendaftaran7' exact component={Pendaftaran7} />

        <Route path='/uploaddokumen' exact component={Upload_dokumen} />
        <Route path='/uploaddokumendetail' exact component={Upload_dokumendetail} />

        <Route path='/bayarpendaftaran' exact component={Bayar_pendaftaran} />
        <Route path='/bayarpendaftarandetail' exact component={Bayar_pendaftaran_detail} />
        
        <Route path='/changepassword' component={Changepassword} />
        <Route path='/verifikasibuktipendaftaran' component={Verifikasi_bukti_bayar} />
        <Route path='/verifikasibuktipendaftarandetail' component={Verifikasi_bukti_bayar_detail} />
        <Route path='/verifikasi' component={Verifikasi} />
        <Route path='/verifikasidetail' component={Verifikasi_detail} />

        <Route path='/reportpendaftaran' component={Report_pendaftaran} />
        <Route path='/reportpendaftarandetail' component={Report_pendaftaran_detail} />

        <Route path='/reportjadwalwawancara' component={Report_jadwal_wawancara} />
        <Route path='/reportjadwalwawancaradetail' component={Report_jadwal_wawancara_detail} />

        <Route path='/wawancara' component={Wawancara} />
        <Route path='/wawancaradetail' component={Wawancara_detail} />
        <Route path='/cetakskfinansial' component={Cetak_SK_Finansial} />
        
        <Route path='/reportwawancara' component={Report_wawancara} />
        <Route path='/reportwawancaradetail' component={Report_wawancara_detail} />

        <Route path='/detailstudent' component={Detail_student} />

        <Route path='/pengaturanpusat' component={Pengaturan_units_pusat} />
        <Route path='/pengaturanunit' component={Pengaturan_units} />
        <Route path='/pengaturanumum' component={Pengaturan_umum} />
        <Route path='/pengaturanadmin' component={Pengaturan_admin} />
        <Route path='/tambahunit' component={Tambah_unit} />
        <Route path='/tambahdokumen' component={Tambah_dokumen} />
        <Route path='/tambahmenu' component={Tambah_menu} />
        <Route path='/tambahorganisasi' component={Tambah_organisasi} />
        <Route path='/tambahmsstudent' component={Tambah_ms_student} />
        <Route path='/tambahuser' component={Tambah_user} />
        <Route path='/tambahuserpusat' component={Tambah_user_pusat} />

        <Route path='/editparam' component={Edit_param} />
        <Route path='/editparampusat' component={Edit_param_pusat} />
        <Route path='/editparamunit' component={Edit_param_unit} />
        <Route path='/editparamdokumen' component={Edit_prm_dokumen} />
        <Route path='/editparammenu' component={Edit_prm_menu} />
        <Route path='/editparampayment' component={Edit_prm_payment} />
        <Route path='/editparamflow' component={Edit_prm_flow} />
        <Route path='/editparamstudent' component={Edit_prm_student} />

        <Route path='/pages/invoice' exact component={Invoice} />
        <Route path='/pages/customers' exact component={Customers} />
        <Route path='/pages/chat' exact component={Chat} />
        <Route path='/pages/support' exact component={Support} />
        <Route path='/pages/page-creator' exact component={PageCreator} />

        <Route path='/profile/v1' exact component={PV1} />
        <Route path='/profile/v2' exact component={PV2} />
        <Route path='/profile/extended' exact component={PExtended} />

        <Route path='/css/grid' exact component={Grid} />
        <Route path='/css/utilities' exact component={Utilities} />
        <Route path='/css/code' exact component={Code} />
        <Route path='/css/icons' exact component={Icons} />
        <Route path='/css/images' exact component={Images} />
        <Route path='/css/typography' exact component={Typography} />
        <Route path='/css/colors' exact component={Colors} />
        <Route path='/css/shadows' exact component={Shadows} />
        <Route path='/css/masks' exact component={Masks} />
        <Route path='/css/hover' exact component={Hover} />
        <Route path='/css/media' exact component={MediaObject} />
        <Route path='/css/animations' exact component={Animations} />

        <Route path='/components/buttons' exact component={Buttons} />
        <Route path='/components/cards' exact component={Cards} />
        <Route path='/components/panels' exact component={Panels} />
        <Route path='/components/list' exact component={List} />
        <Route path='/components/progress' exact component={Progress} />
        <Route path='/components/tabs' exact component={Tabs} />
        <Route path='/components/tags' exact component={Tags} />
        <Route path='/components/date' exact component={DatePicker} />
        <Route path='/components/time' exact component={TimePicker} />
        <Route path='/components/popovers' exact component={Popover} />
        <Route path='/components/tooltips' exact component={Tooltip} />
        <Route path='/components/stepper' exact component={Stepper} />
        <Route path='/components/pagination' exact component={Pagination} />
        <Route path='/components/collapse' exact component={Collapse} />

        <Route path='/tables/basic' exact component={TBasic} />
        <Route path='/tables/extended' exact component={TExtended} />
        <Route path='/tables/datatable' exact component={Datatable} />

        <Route path='/maps/google' exact component={Google} />
        <Route path='/maps/full' exact component={MFull} />
        <Route path='/maps/vector' exact component={Vector} />

        <Route path='/forms/basic' exact component={FBasic} />
        <Route path='/forms/extended' exact component={FExtended} />
        <Route path='/forms/validation' exact component={FValidation} />

        <Route path='/charts' exact component={Charts} />
        <Route path='/alerts' exact component={Alerts} />
        <Route path='/modals' exact component={Modals} />
        <Route path='/sections' exact component={Sections} />
        <Route path='/calendar' exact component={Calendar} />

        <Route component={fourtOFour} />
      </Switch>
    );
  }
}

export default Routes;