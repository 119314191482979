import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import CurrencyFormat from "react-currency-format";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
  MDBTable,
} from "mdbreact";
// import remSep from "string-remove-thousand-separators";
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height={100} width={100} />
      </div>
    )
  );
};

class Tambah_dokumen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name:"",
      // data: "",
      list: [],
    };
  }

  componentDidMount() {
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {
    this.setState({ 
      isEdit: false,
      name: "",
      listorganisasi: [],
    });
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/master/list", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ list: resData, is_show: true });
            // this._onChecked(resData[0]);
          }
        })
    );
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeDate = (jsDate, dateString) => {
    this.setState({ dob: dateString });
  };

  validateForm() {
    return this.state.name.length > 0;
    //&& this.state.birth_certificate.length > 0;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    console.log("state 1 " + JSON.stringify(this.state));

    fetch(global.variable.LINK_API + "settings/master/create", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(this.state),
    })
      .then((response) => response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success").then((value) => {
            window.location.href = "/tambahdokumen";
          });
        } else {
          global.swal("Error", resData.error, "info");
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleUpdate = (event) => {
    event.preventDefault();

    fetch(global.variable.LINK_API + 'settings/master/edit',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(this.state),
      })
      .then(response => response.json())
      .then(resData => {
        console.log(resData)
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success", )
          .then((value) => {
            this._onSearchContent();
          });
        } else {
          global.swal("Error", resData.error, "info")
        }

      }) .catch(err => {
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
 
  };

  handleBack = () => {
    window.location.href = "/pendaftaran";
  };

  onClickEdit(val) {
    const { history } = this.props;
    window.scrollTo(0, 0);
    this.setState({
      isEdit: true,
      id: val.id,
      name: val.name,
    })
  }

  render() {
    const { location } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const {list, form, isEdit} = this.state;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        {/* <MDBBreadcrumbItem active>
                          <a href="/pengaturanumum">Pengaturan Umum</a>
                        </MDBBreadcrumbItem> */}
                        <MDBBreadcrumbItem active>
                          Tambah Dokumen
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={isEdit ? this.handleUpdate : this.handleSubmit}>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label>Nama Dokumen *</label>
                            <input
                              className="form-control"
                              type="Text"
                              name="name"
                              maxLength="100"
                              value={this.state.name}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                        </div>

                        <MDBBtn color="primary" block type="submit">
                        { isEdit ? "Update" : "Submit" }
                        </MDBBtn>
                      </form>
                      <br></br>
                      <div className="form-group">
                        <MDBTable bordered>
                          <thead className="blue-grey lighten-4">
                            <tr>
                              <th style={{ textAlign: "center" }}>No</th>
                              <th style={{ textAlign: "center" }}>Nama Dokumen</th>
                              <th style={{ textAlign: "center" }}> Opsi </th>
                            </tr>
                          </thead>
                          <tbody>
                            {list.map((data, idx) => {
                              return (
                                <tr key={idx}>
                                  <td style={{ textAlign: "center" }}>{idx + 1}</td>
                                  <td style={{ textAlign: "center" }}>{data.name}</td>
                                  <td style={{ textAlign: "center" }}>
                                      <MDBBtn
                                        color="primary"
                                        block
                                        onClick={() => this.onClickEdit(data)}
                                      >Edit</MDBBtn>
                                  </td>
                                </tr>
                              )
                            })}
                           
                          </tbody>
                        </MDBTable>
                        <LoadingIndicator/>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Tambah_dokumen;
