import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBTable,
} from "mdbreact";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";

class Pengaturan_umum extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CB_REGTYPE: "",
      CB_STUDYYEAR: "",
      CB_JALUR: "",
      CB_FLOW: "",
      CB_STATUS: "",
      val_jalur: "",
      val_studyyear: "",
      is_show: false,
      listunit: [],
    };
  }

  componentDidMount() {
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/listunit", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listunit: resData, is_show: true });
            // this._onChecked(resData[0]);
          }
        })
    );
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(
        <option
          key={i}
          value={
            this.state.CB_JALUR[i].id + "-" + this.state.CB_JALUR[i].fullname
          }
        >
          {this.state.CB_JALUR[i].id + " - " + this.state.CB_JALUR[i].fullname}
        </option>
      );
    }
    return items;
  }

  createSelectItemsStudyYear() {
    let items = [];
    for (let i = 0; i < this.state.CB_STUDYYEAR.length; i++) {
      items.push(
        <option key={i} value={this.state.CB_STUDYYEAR[i].id}>
          {this.state.CB_STUDYYEAR[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectItemsREG() {
    let items = [];
    for (let i = 0; i < this.state.CB_REGTYPE.length; i++) {
      items.push(
        <option key={i} value={this.state.CB_REGTYPE[i].id}>
          {this.state.CB_REGTYPE[i].id + " - " + this.state.CB_REGTYPE[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectItemsFlow() {
    let items = [];
    for (let i = 0; i < this.state.CB_FLOW.length; i++) {
      items.push(
        <option key={i} value={this.state.CB_FLOW[i].id}>
          {this.state.CB_FLOW[i].name}
        </option>
      );
    }
    return items;
  }

  createSelectItemsstatus() {
    let items = [];
    for (let i = 0; i < this.state.CB_STATUS.length; i++) {
      items.push(
        <option key={i} value={this.state.CB_STATUS[i].id}>
          {this.state.CB_STATUS[i].name}
        </option>
      );
    }
    return items;
  }

  onDropdownSelected(e) {
    console.log([e.target.name] + " - " + e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }

  onDropdownSelectedJalur(e) {
    if (e.target.value === "") {
      this.setState({ is_show: false });
    } else {
      fetch(
        global.variable.LINK_API +
          "student/flow/7?registration_type_id=" +
          e.target.value,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          //body: JSON.stringify(data)
        }
      )
        .then((response) => response.json())
        .then((resData) => {
          if (resData.message === "Success") {
            //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

            this.setState({ CB_JALUR: resData.data, is_show: true });
          }
          console.log(resData);
        });
    }
  }

  onClickEditDokumen() {
    const { history } = this.props;
    history.push("/tambahdokumen");
  }

  onClickEditMenu() {
    const { history } = this.props;
    history.push("/tambahmenu");
  }

  onClickEditMsStudent() {
    const { history } = this.props;
    history.push("/tambahmsstudent");
  }

  onClickAdd() {
    const { history } = this.props;
    history.push("/tambahunit", { isEdit: true });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let jalur = this.state.val_regtype ? this.state.val_regtype : "";
    let flow = this.state.val_flow ? this.state.val_flow : "";
    let status = this.state.val_status ? this.state.val_status : "";

    console.log(jalur);

    /*localStorage.setItem('DATA_JALUR', this.state.val_jalur);*/

    const data = {
      registration_type_id: jalur,
      flow_id: flow,
      interview_status_id: status,
      study_year: this.state.val_studyyear,
    };

    console.log(data);

    Swal.fire({
      title: "Mohon Tunggu Sebentar !",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(global.variable.LINK_API + "report/interview", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        console.log(resData);
        if (resData.message === "Success") {
          localStorage.setItem("data_report", JSON.stringify(resData.data));
          window.location.href = "/reportwawancaradetail";
        } else {
          global.swal("Error", resData.error, "info");
        }
      });
  };

  render() {
    const { filters, form, listunit } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pengaturan Umum
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form>
                        <div className="form-group">
                          <MDBTable bordered>
                            <thead className="blue-grey lighten-4">
                              <tr>
                                <th style={{ textAlign: "center" }}>Jenis</th>
                                <th style={{ textAlign: "center" }} colSpan={2}>
                                  Opsi
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ textAlign: "center" }}>
                                  Parameter Dokumen Upload
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <MDBBtn
                                    color="primary"
                                    block
                                    onClick={() => this.onClickEditDokumen()}
                                  >
                                    Edit
                                  </MDBBtn>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "center" }}>
                                  Parameter Menu
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <MDBBtn
                                    color="primary"
                                    block
                                    onClick={() => this.onClickEditMenu()}
                                  >
                                    Edit
                                  </MDBBtn>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "center" }}>
                                Pengaturan Data Calon Siswa
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <MDBBtn
                                    color="primary"
                                    block
                                    onClick={() => this.onClickEditMsStudent()}
                                  >
                                    Edit
                                  </MDBBtn>
                                </td>
                              </tr>
                            </tbody>
                          </MDBTable>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pengaturan_umum;
