import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSwitch,
  MDBFileInput,
  MDBRangeInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBLink,
  MDBBtn,
} from "mdbreact";
class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      class: "",
    };
  }

  componentDidMount() {
    /*if(localStorage.getItem('reg2')){
      this.setState(JSON.parse(localStorage.getItem('reg2')));
    }*/
  }

  // createSelectAchieveType() {
  //   let items = [];
  //   let params = JSON.parse(
  //     localStorage.getItem("DATA_PARAMETER")
  //   ).prmAchieveType;
  //   for (let i = 0; i < params.length; i++) {
  //     items.push(
  //       <option key={i} value={params[i].id + "_" + params[i].name}>
  //         {params[i].name}
  //       </option>
  //     );
  //   }
  //   return items;
  // }

  // createSelectAchieveRank() {
  //   let items = [];
  //   let params = JSON.parse(
  //     localStorage.getItem("DATA_PARAMETER")
  //   ).prmAchieveRank;
  //   for (let i = 0; i < params.length; i++) {
  //     items.push(
  //       <option key={i} value={params[i].id + "_" + params[i].name}>
  //         {params[i].name}
  //       </option>
  //     );
  //   }
  //   return items;
  // }

  createSelectClass() {
    // ini valuenya
    let items = [];
    items.push(
      <option value="10">10</option>,
      <option value="11">11</option>,
      <option value="12">12</option>
    );

    return items;
  }

  showRemove() {
    if (localStorage.getItem("reg5")) {
      return (
        <MDBBtn
          onClick={this.handleClear}
          block
          style={{ margin: 5, height: 50, width: "20%" }}
        >
          Clear Data
        </MDBBtn>
      );
    }
  }

  showPrestasi() {
    if (localStorage.getItem("reg5")) {
      let guardian = JSON.parse(localStorage.getItem("reg5"));
      console.log(localStorage.getItem("reg5"));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) {
        showitem.push(
          <div key={guardian[i].name}>
            <hr />
            <div className="rowHorizontal">
              <label
                className="col-md-3"
                style={{ width: 200, margin: 5, fontWeight: "bold" }}
              >
                Nama Siswa{" "}
              </label>
              <label
                className="col-md-6"
                style={{ margin: 5, fontWeight: "bold" }}
              >
                {guardian[i].name}{" "}
              </label>
            </div>
            <div className="rowHorizontal">
              <label
                className="col-md-3"
                style={{ width: 200, margin: 5, fontWeight: "bold" }}
              >
                Kelas{" "}
              </label>
              <label
                className="col-md-6"
                style={{ margin: 5, fontWeight: "bold" }}
              >
                {guardian[i].class}{" "}
              </label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  onChange = (e) => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a });
  };

  onDropdownSelected(e) {
    this.setState({
      [e.target.name]: e.target.value.split("_")[0],
      [e.target.name + "_name"]: e.target.value.split("_")[1],
    });
  }

  onDropdownSelected2(e) {
    this.setState({ class: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    window.location.href = "/pendaftaran6";
  };

  handleAdd = () => {
    console.log("state 4 " + JSON.stringify(this.state));
    let item = [];
    if (localStorage.getItem("reg5")) {
      item = JSON.parse(localStorage.getItem("reg5"));
    }
    item.push(this.state);

    console.log("score " + JSON.stringify(item));

    localStorage.setItem("reg5", JSON.stringify(item));

    window.location.href = "/pendaftaran5";
  };

  handleClear = () => {
    localStorage.removeItem("reg5");
    window.location.href = "/pendaftaran5";
  };

  handleBack = () => {
    window.location.href = "/pendaftaran4";
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Pendaftaran Step 5
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>
                      <form onSubmit={this.handleAdd}>
                        <label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Saudara yang masih bersekolah di{" "}
                          {
                            JSON.parse(localStorage.getItem("DATA_PARAMETER"))
                              .prm_unit.name
                          }
                        </label>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label>Nama Siswa</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              onChange={this.onChange}
                              maxLength="200"
                              value={this.state.name}
                              style={{ width: "100%" }}
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Kelas</label>
                            <select
                              className="form-control"
                              onChange={this.onDropdownSelected2.bind(this)}
                              value={this.state.class}
                            >
                              <option value="">-Pilih Kelas-</option>
                              {this.createSelectClass()}
                            </select>
                          </div>
                        </div>
                        <div className="rowHorizontal">
                          <MDBBtn
                            type="submit"
                            color="primary"
                            block
                            style={{ height: 50 }}
                          >
                            Tambah
                          </MDBBtn>
                        </div>

                        {this.showRemove()}
                        {this.showPrestasi()}

                        <div className="row">
                          <div className="col-md-6">
                            <MDBBtn
                              color="primary"
                              onClick={this.handleBack}
                              block
                              style={{ marginTop: 5, height: 50 }}
                            >
                              Prev
                            </MDBBtn>
                          </div>
                          <div className="col-md-6">
                            <MDBBtn
                              color="primary"
                              onClick={this.handleSubmit}
                              block
                              style={{ marginTop: 5, height: 50 }}
                              disabled={this.state.name!=''?true:false}
                            >
                              Next
                            </MDBBtn>
                          </div>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
