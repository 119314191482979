import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBTable,
} from "mdbreact";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
      </div>
    )
  );
};

class Edit_prm_flow extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const { state } = location;
    const { data } = state;

    this.state = {
      data: data,
      subdomain: data.subdomain,
      listflow: [],
      prm_flow: [],
    }
  }

  componentDidMount() {
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {
    window.scrollTo(0, 0);
    
    // list flow
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/param/listFlow", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listflow: resData, is_show: true });
          }
        })
    );

    // get prm_flow
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/param/getFlow", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify({subdomain: this.state.subdomain})
      })
        .then((response) => response.json())
        .then((resData) => {
          if (resData.message !== "Error") {
            // buat set di listflow
            let newList = [];
            let newData = [];
            newList = this.state.listflow;
            resData.data.map ((list, idx) => {
              const findIdx = newList.findIndex((i) => i.id == list);
              if (findIdx !== -1) {
                newList[findIdx].checked = "true";
                newList[findIdx].sequence = idx+1;
                newData.push(newList[findIdx])
              }
            });
            this.setState({ 
              prm_flow: newData, 
              listflow: newList 
            });
          }
        })
    );


  }

  createSelectItems() {
    let items = [];
    for (let i = 1; i <= this.state.prm_flow.length; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }

  handleCheckAll = (e) => {
    const { listflow, prm_flow } = this.state;
    const formattedValue = e.target.checked ? "true" : "";
    let newList = this.state.listflow;
    if (formattedValue === "true") {
      // kosongkan data dri prm_flow
      this.state.prm_flow.splice(0, listflow.length);

      listflow.map((list, idx) => {
        list.sequence = idx+1;
        list.checked = "true";

        // ubah data listflow
        newList[idx] = list;

        // push data ke prm_flow jika checked true
        this.state.prm_flow.push(list);
      })
    } else {
      listflow.map((list, idx) => {
        list.sequence = "";
        list.checked = "";

        // ubah data listflow
        newList[idx] = list;

        // push data ke prm_flow jika checked true
        this.state.prm_flow.push(list);
      })
      // hapus data dri prm_flow jika checked tidak true
      this.state.prm_flow.splice(0, prm_flow.length);
    }

    // setstate
    this.setState({ 
      [e.target.name]: formattedValue,
      listflow: newList,
    });
  };

  handleChangeArr = async (e) => {
    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")
    let newList = []
    newList = this.state[fieldname];

    // khusus checkbox
    if (e.target.type === "checkbox") {
      const formattedValue = e.target.checked ? "true" : "";
      newList[idx][e.target.name] = formattedValue;
      if (formattedValue === "true") {
        newList[idx].sequence = this.state.prm_flow.length+1;
        // push data ke prm_flow jika checked true
        this.state.prm_flow.push(newList[idx]);
      } else {
        this.state.check_all = "";
        // hapus data dri prm_flow jika checked tidak true
        const findIdx = this.state.prm_flow.findIndex((i) => i.id == newList[idx].id);
        this.state.prm_flow.splice(findIdx, 1);
      }
    } else {
      // newList[idx][e.target.name] = e.target.value
      // cari urutan yang sudah dipakai
      if (e.target.name === "sequence") {
        const findIdx = this.state.prm_flow.findIndex((i) => i.sequence == e.target.value);
        if (findIdx !== -1 && e.target.value !== "") {
          e.target.value = "";
          newList[idx].errseq = "Urutan sudah dipakai"
        } else {
          newList[idx].errseq = ""
          newList[idx][e.target.name] = e.target.value
          if (e.target.value !== "") {
            const new_prm_flow = this.state.prm_flow
  
            // tukar urutan prm_flow
            var temp = new_prm_flow[e.target.value-1];     //simpan data prm_flow yg urutan e.target.value di 
            new_prm_flow[e.target.value-1] = newList[idx]  //ambil data yg diubah urutannya kemudian masukan ke prm_flow dengan urutan yg tepat
            const newIdx = this.state.prm_flow.findIndex((i) => i.id == newList[idx].id); //cari idx newlist yg di ubah di prmflow
            new_prm_flow[newIdx] = temp; //ambil dari data temp tadi di simpan di 
            this.setState({prm_flow: new_prm_flow});
          }

        }
      }


      // // set prm_menu
      // const findIdx = this.state.prm_menu.findIndex((i) => i.id == newList[idx].id);
      // this.state.prm_menu[findIdx][e.target.name] = e.target.value;
    }

    // setstate
    this.setState({ [fieldname]: newList });
  };


  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("prm_flow", this.state.prm_flow);
    data.append("subdomain", this.state.subdomain);

    fetch(global.variable.LINK_API + 'settings/param/setFlow',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify({
          subdomain: this.state.subdomain, 
          prm_flow: this.state.prm_flow,
        }),
        // body: data,
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success", )
          .then((value) => {
            window.location.href="/pengaturanunit";
          });
        } else {
          global.swal("Error", resData.error, "info")
        }

      }) .catch(err => {
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
 
  };

  render() {
    const { data, listflow, prm_flow, check_all } = this.state;
    const col = []
    let errcol = ""
    // if (!isEmpty(prm_flow)) {
      prm_flow.map((list, idx) => {
        col.push(" "+list.id);
        if (list.sequence === "") {
          errcol = "Harap mengisi semua urutan."
        }
      })
    // }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        {/* <MDBBreadcrumbItem href>
                          <a href="/pengaturanunit">Pengaturan Unit</a>
                        </MDBBreadcrumbItem> */}
                        <MDBBreadcrumbItem active>
                          Edit Parameter Flow
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="col-md-6">
                            <b>Subdomain</b>
                            <input
                              className="form-control"
                              type="Text"
                              name="jalur"
                              value={data.subdomain || ""}
                              disabled
                            />
                          </div>
                          <div className="col-md-6">
                            <b>Param Flow</b>
                            <input
                              className="form-control"
                              type="Text"
                              name="param"
                              value={errcol === "" ? (col || "") : ""}
                              disabled
                            />
                            {errcol !== "" && (
                              <small className='form-text text-error'>
                                {errcol}
                              </small>
                            )}
                          </div>
                        </div>
                        <br></br>
                        <label htmlFor="exampleForm2">Daftar Parameter Flow</label>
                        <MDBInput
                          label="Pilih Semua"
                          labelClass='mr-5'
                          type='checkbox'
                          value={this.state.check_all}
                          name='check_all'
                          checked={this.state.check_all === "true" ? true : false}
                          id={'check_all'}
                          onChange={this.handleCheckAll}
                          containerClass='my-3'
                        />
                        <MDBTable bordered>
                          <thead className="blue-grey lighten-4">
                            <tr>
                              <th style={{ textAlign: "center" }}>Nama</th>
                              <th style={{ textAlign: "center" }}>Urutan</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listflow.map((list, idx) => {
                              // buat reset sequence jika di uncek atau sequence nya gak valid
                              if (list.checked !== "true" || list.sequence > prm_flow.length) {
                                list.sequence = ""
                              }
                              return (
                                <tr key={idx}>
                                  <td>
                                    <MDBInput
                                      label={list.name}
                                      labelClass='mr-5'
                                      type='checkbox'
                                      value={list.checked}
                                      name='checked'
                                      checked={(check_all === "true" || list.checked === "true") ? true : false}
                                      id={'checkbox'+idx}
                                      onChange={this.handleChangeArr}
                                      containerClass='my-3'
                                      fieldname="listflow"
                                      index={idx}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center", width: "20%" }}>
                                    <select
                                      className="form-control"
                                      name="sequence"
                                      value={list.sequence}
                                      onChange={this.handleChangeArr}
                                      fieldname="listflow"
                                      index={idx}
                                      disabled = {list.checked==="true" ? false : true}
                                      required = {list.checked==="true" ? true : false}
                                    >
                                      <option value="">- Pilih Urutan -</option>
                                      {this.createSelectItems()}
                                    </select>
                                    {list.errseq !== "" && (
                                      <small className='form-text text-error'>
                                        {list.errseq}
                                      </small>
                                    )}
                                  </td>
                                </tr>
                              )
                            })

                            }
                          </tbody>
                        </MDBTable>
                        <LoadingIndicator />
                        {!isEmpty(prm_flow) && (
                          <MDBBtn color="primary" block type="submit">
                            Submit
                          </MDBBtn>
                        )}
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Edit_prm_flow;
