import React, { Component } from "react";
// import {Form, Breadcrumb, Button} from 'react-bootstrap';
import "moment/locale/id.js";
import "rc-datepicker/lib/style.css";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBSwitch,
  MDBTable,
} from "mdbreact";
import { isEmpty, map } from "lodash";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
      </div>
    )
  );
};

class Edit_prm_menu extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const { state } = location;
    const { data } = state;

    this.state = {
      data: data,
      subdomain: data.subdomain,
      user_level: data.user_level,
      listmenu: [],
      prm_menu: [],
    }
  }

  componentDidMount() {
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {
    window.scrollTo(0, 0);
    
    // list menu
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/master/listmenu", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listmenu: resData, is_show: true });
          }
        })
    );

    // get prm_menu
    await trackPromise(
      fetch(global.variable.LINK_API + "settings/param/getMenu", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify({subdomain: this.state.subdomain})
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log(resData.prm_menu)
          if (!isEmpty(resData.prm_menu)) {
            // buat set di listmenu
            let newList = [];
            newList = this.state.listmenu;
            resData.prm_menu.map ((list, idx) => {
              const findIdx = newList.findIndex((i) => i.id == list.id);
              if (findIdx !== -1) {
                newList[findIdx].checked = "true";
                newList[findIdx].is_active = list.is_active;
                newList[findIdx].sequence = list.sequence;
                newList[findIdx].user_level_id = list.user_level_id;
              }
            });
            this.setState({ 
              prm_menu: resData.prm_menu, 
              listmenu: newList 
            });
          }
        })
    );
  }

  createSelectItems() {
    let items = [];
    for (let i = 1; i <= this.state.prm_menu.length; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }

  createSelectUserLevel() {
    let items = [];
    const params = JSON.parse(localStorage.getItem("DATA_PARAMETER")).prm_user_level;
    for (let i = 0; i < params.length; i++) {
      items.push(
        <option key={i} value={params[i].id}>
          {params[i].name}
        </option>
      );
    }
    return items;
  }

  handleChangeArr = (e) => {
    const { prm_menu } = this.state;

    const fieldname = e.target.getAttribute("fieldname")
    const idx = e.target.getAttribute("index")
    let newList = []
    newList = this.state[fieldname];

    // khusus checkbox
    if (e.target.type === "checkbox") {
      const formattedValue = e.target.checked ? "true" : "";
      newList[idx][e.target.name] = formattedValue;
      if (formattedValue === "true") {
         //ini biar urutannya otomatis ketika checked true
         newList[idx].sequence = prm_menu.length+1;
        // push data ke prm_menu jika checked true
        this.state.prm_menu.push(newList[idx]);
      } else {
        // hapus data dri prm_menu jika checked tidak true
        const findIdx = this.state.prm_menu.findIndex((i) => i.id == newList[idx].id);
        this.state.prm_menu.splice(findIdx, 1);
      }
    } else {
      // cari urutan yang sudah dipakai
      if (e.target.name === "sequence") {
        const findIdx = this.state.prm_menu.findIndex((i) => i.sequence == e.target.value);
        if (findIdx !== -1 && e.target.value !== "") {
          e.target.value = "";
          newList[idx].errseq = "Urutan sudah dipakai"
        } else {
          newList[idx].errseq = ""
        }
      }

      newList[idx][e.target.name] = e.target.value

      // set prm_menu
      const findIdx = this.state.prm_menu.findIndex((i) => i.id == newList[idx].id);
      this.state.prm_menu[findIdx][e.target.name] = e.target.value;
    }

    // setstate
    this.setState({ [fieldname]: newList });
  };

  handleSwitchChangeArr (idx) {
    let newList = []
    newList = this.state.listmenu;
    const formattedValue = newList[idx].is_active === 1 ? 0 : 1;
    newList[idx].is_active = formattedValue;

    // set prm_menu
    const findIdx = this.state.prm_menu.findIndex((i) => i.id == newList[idx].id);
    this.state.prm_menu[findIdx].is_active = formattedValue;

    this.setState({ listmenu: newList });
  };

  handleSubmit = (event) => {
    const { user_level } = this.state;

    event.preventDefault();

    fetch(global.variable.LINK_API + 'settings/param/createMenu',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        body: JSON.stringify(this.state),
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          global.swal("Success", resData.message, "success", )
          .then((value) => {
            if (user_level == 5) {
              window.location.href = "/pengaturanadmin";
            }
            else{
              window.location.href=user_level==7?"/pengaturanpusat":"/pengaturanunit";
            }
          });
        } else {
          global.swal("Error", resData.error, "info")
        }

      }) .catch(err => {
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
 
  };

  render() {
    const { data, listmenu, prm_menu } = this.state;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem href>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        {/* <MDBBreadcrumbItem href>
                          <a href="/pengaturanunit">Pengaturan Unit</a>
                        </MDBBreadcrumbItem> */}
                        <MDBBreadcrumbItem active>
                          Edit Parameter Menu
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>

                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="col-md-6">
                            <b>Subdomain</b>
                            <input
                              className="form-control"
                              type="Text"
                              name="jalur"
                              value={data.subdomain || ""}
                              disabled
                            />
                          </div>
                        </div>
                        <br></br>
                        <label htmlFor="exampleForm2">Daftar Parameter Menu</label>
                        <MDBTable bordered>
                          <thead className="blue-grey lighten-4">
                            <tr>
                              <th style={{ textAlign: "center" }}>Nama</th>
                              <th style={{ textAlign: "center" }}>Urutan</th>
                              <th style={{ textAlign: "center" }}>User Level</th>
                              <th style={{ textAlign: "center" }}>Is Aktif</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listmenu.map((list, idx) => {
                              if (list.checked !== "true") {
                                list.is_active = 0
                                list.sequence = ""
                              }
                              if (list.sequence > prm_menu.length) {
                                list.sequence = ""
                                // set prm_menu
                                const findIdx = this.state.prm_menu.findIndex((i) => i.id == list.id);
                                this.state.prm_menu[findIdx].sequence = ""
                              }
                              return (
                                <tr key={idx}>
                                  <td>
                                    <MDBInput
                                      label={list.name}
                                      labelClass='mr-5'
                                      type='checkbox'
                                      value={list.checked}
                                      name='checked'
                                      checked={list.checked === "true" ? true : false}
                                      id={'checkbox'+idx}
                                      onChange={this.handleChangeArr}
                                      containerClass='my-3'
                                      fieldname="listmenu"
                                      index={idx}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center", width: "20%" }}>
                                    <select
                                      className="form-control"
                                      name="sequence"
                                      value={list.sequence}
                                      onChange={this.handleChangeArr}
                                      fieldname="listmenu"
                                      index={idx}
                                      disabled = {list.checked==="true" ? false : true}
                                      required = {list.checked==="true" ? true : false}
                                    >
                                      <option value="">- Pilih Urutan -</option>
                                      {this.createSelectItems()}
                                    </select>
                                    {list.errseq !== "" && (
                                      <small className='form-text text-error'>
                                        {list.errseq}
                                      </small>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center", width: "20%" }}>
                                  <select
                                    id="exampleForm2"
                                    className="form-control"
                                    name="user_level_id"
                                    value={list.user_level_id}
                                    fieldname="listmenu"
                                    index={idx}
                                    onChange={this.handleChangeArr}
                                    disabled = {list.checked==="true" ? false : true}
                                    required = {list.checked==="true" ? true : false}
                                  >
                                    <option value="">- Pilih User Level-</option>
                                    {this.createSelectUserLevel()}
                                  </select>
                                  </td>
                                  <td style={{ textAlign: "center", width: "10%" }}>
                                    <MDBSwitch
                                      getValue={() => this.handleSwitchChangeArr(idx)}
                                      name="is_active"
                                      checked={list.is_active == "1" ? true : false}
                                      labelLeft='' labelRight=''
                                      disabled = {list.checked==="true" ? false : true}
                                    />
                                  </td>
                                </tr>
                              )
                            })

                            }
                          </tbody>
                        </MDBTable>
                        <LoadingIndicator />
                        {!isEmpty(prm_menu) && (
                          <MDBBtn color="primary" block type="submit">
                            Submit
                          </MDBBtn>
                        )}
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Edit_prm_menu;
